<template>
  <div class="postal">
    <InputField :fields="fields" class="postal__input" @change="updatePostal">
      <Spinner class="postal__input--loading" v-if="loading"></Spinner>
      <span v-else class="postal__input-autocomplete">{{ city }}</span>
    </InputField>
  </div>
</template>

<script>
import { InputField, Spinner } from '@/sharedComponents';
import { getCityFromZipcode } from '@/api';

export default {
  name: 'Postal',
  components: {
    InputField,
    Spinner,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      postal: this.$store.state.app[this.fields.Key.value],
      city: this.$store.state.app[this.fields.KeyCity.value],
      value: null,
      errorMessage: null,
      loading: false,
    };
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  async mounted() {
    this.addToValidation(this);
    this.value = this.postal;

    this.$root.$on('onSave', () => {
      this.onSaveEvent(this.fields.Key.value, this.postal, this.$options.name);
      this.onSaveEvent(this.fields.KeyCity.value, this.city, this.$options.name);
    });

    if (this.postal) await this.getCity(this.postal);
  },
  methods: {
    async updatePostal(e) {
      this.postal = e.target.value;
      await this.getCity(this.postal);
    },
    async getCity(zipcode) {
      this.loading = true;
      this.value = null;

      try {
        if (!zipcode || zipcode.length < 4) {
          this.city = undefined;
          return;
        }

        const response = await getCityFromZipcode(zipcode);

        if (!response.data) {
          this.city = this.$t('unknown');
          console.error(`${this.$options.name}: No city found for zip code: ${zipcode}`);
          return;
        }

        const uppercaseCityName = response.data.name;

        this.city = this.capitalizeFirstLetter(uppercaseCityName);
        this.value = zipcode;
      } catch (error) {
        console.error(error);
      } finally {
        if (this.fields.Validations) this.$root.$emit('onValidate', this);
        this.loading = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ');
    },
    // used by Talkmore.Web.Vue.Shared/validate
    getValue() {
      return this.value;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    validated(valid, validation, validationError) {
      if (!valid && (this.getValue()?.length || validationError)) {
        this.errorMessage = validation.fields.ErrorText.value;
      } else {
        this.errorMessage = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.postal {
  &__input {
    ::v-deep input {
      max-width: 89px;
    }

    &--loading {
      padding-left: 16px;
    }

    &-autocomplete {
      padding-left: 16px;
      text-transform: uppercase;
      font-size: 16px;
      color: var(--color-grey-darker);
    }
  }
}
</style>
