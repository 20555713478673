<template>
  <div>
    <!--
    <div v-show="isEditorActive" class="experience-editor">
      <button class="button-secondary" @click="hidden = !hidden">
        {{ hidden ? 'Show modal for editing' : 'Hide modal again' }}
      </button>
      <p>This button is only visible while editing in the experience editor</p>
    </div>
    -->

    <ModalOverlay :class="{ hidden }" @click="closeModal">
      <div class="modal-choice">
        <ScText class="modal__heading text-l" tag="h3" :field="fields.ModalChoiceHeading" />
        <ScText class="text-m" v-if="fields.ModalChoiceContent" tag="p" :field="fields.ModalChoiceContent" />
        <div class="modal-choice__button-container">
          <button class="button secondary-btn" @click="closeModal">
            <ScText tag="span" :field="fields.ModalChoiceCloseButtonText" />
          </button>
          <button class="button primary-btn" @click="emitActionEvent" @click.prevent="closeModal(false)">
            <ScText tag="span" :field="fields.ModalChoiceActionButtonText" />
          </button>
        </div>
      </div>
    </ModalOverlay>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ModalOverlay from './util/ModalOverlay';
import { toggleBodyScrollLock, focusTrapInsideElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'ModalChoice',
  components: {
    ScText: Text,
    ModalOverlay,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    hidden: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    hidden() {
      toggleBodyScrollLock(!this.hidden);
      focusTrapInsideElement(this.$el, !this.hidden, this.closeModal);
    },
  },
  methods: {
    emitActionEvent() {
      const actionName = this.fields.ModalChoiceActionEvent?.fields.Key.value;
      if (actionName) this.$emit(actionName);
    },
    closeModal(cancelled = true) {
      this.$emit('closeModal', { detail: { cancelled } });
    },
  },
};
</script>

<style lang="scss">
.modal-choice {
  position: relative;
  height: fit-content;
  background-color: #{$color-white};
  border-radius: 24px;
  padding: #{$spacing-m};
  max-width: #{$desktop-narrow-component};
  margin: #{$spacing-l} #{$spacing-s};
  @include desktop {
    width: #{$desktop-narrow-component};
  }

  &__heading {
    margin-bottom: #{$spacing-xxs};
  }

  &__button-container {
    margin-top: #{$spacing-m};
    display: flex;
    flex-wrap: wrap;
    gap: #{$spacing-xs};
    justify-content: center;
    justify-items: center;
    width: 100%;

    .button {
      display: flex;
      @include button-small;
      max-width: calc(50% - (#{$spacing-xs}/ 2));
      margin: 0;

      span {
        flex-shrink: 0;
      }
    }
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
}
</style>
