<template>
  <FlowSelectorNavigation v-if="visible" :fields="fields"></FlowSelectorNavigation>
</template>

<script>
import FlowSelectorNavigation from './FlowSelectorNavigation.vue';

export default {
  name: 'FlowSelectorNavigationTrumf',
  components: {
    FlowSelectorNavigation,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    visible() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }

      return true;
    },
  },
};
</script>
