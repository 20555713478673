<template>
  <div class="toggle-button desktop-narrow" :class="{ invalid: errorMessage || requiredError }">
    <ScText tag="h5" class="toggle-button-label" :field="fields.LabelText" />

    <div class="toggle-button__labels">
      <label class="toggle-button__labels__label" :class="{ selected: toggleValue === onValue }">
        <input type="radio" :value="onValue" v-model="toggleValue" />
        <span class="toggle-button__circle"></span>
        <ScText tag="span" class="toggle-button-label" :field="fields.OnText" />
      </label>
      <label class="toggle-button__labels__label" :class="{ selected: toggleValue === offValue }">
        <input type="radio" :value="offValue" v-model="toggleValue" />
        <span class="toggle-button__circle"></span>
        <ScText tag="span" class="toggle-button-label" :field="fields.OffText" />
      </label>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'ToggleButton',
  components: {
    ScText: Text,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    rendering: {
      type: Object,
    },
  },
  data() {
    return {
      toggleValue: this.$store.state.app[this.fields.Key.value],
      errorMessage: null,
      requiredError: false,
      toggleByValue: this.rendering.fields.ToggleValue.value,
    };
  },
  computed: {
    onValue() {
      return this.fields.OnValue.value ? this.fields.OnValue.value : this.fields.OnText.value;
    },
    offValue() {
      return this.fields.OffValue.value ? this.fields.OffValue.value : this.fields.OffText.value;
    },
  },
  watch: {
    toggleValue() {
      this.emit();

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  mounted() {
    this.addToValidation(this);

    this.$root.$on('onSave', () => {
      this.onSaveEvent(this.fields.Key.value, this.toggleValue, this.$options.name);
    });

    // give listening components a chance to render in before emitting
    setTimeout(() => this.emit(), 10);
  },
  methods: {
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.toggleValue;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    validated(valid, validation, validationError) {
      if (!valid) this.errorMessage = validation.fields.ErrorText.value;
      else this.errorMessage = null;

      if (validationError) this.requiredError = true;
      else this.requiredError = false;
    },
    emit() {
      const shouldEmit = this.toggleByValue && this.toggleByValue === this.toggleValue;
      this.onEmitValue(this, shouldEmit ? this.rendering.dataSource : this.toggleValue);
    },
  },
};
</script>

<style lang="scss">
.toggle-button {
  .toggle-button__circle {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #{$color-grey-dark};
    flex-shrink: 0;

    &:after {
      content: '';
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: white;
      top: 0;
      left: 0;
    }
  }

  &__labels {
    display: flex;
    gap: 8px;
    margin-top: 16px;

    &__label {
      background: #{$color-white};
      border-radius: 100px;
      border: 2px solid #{$color-grey};
      padding: 12px 24px 12px 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;

      &:hover,
      &.selected:hover {
        border-color: #{$color-black};

        .toggle-button__circle {
          border-color: #{$color-black};
        }
      }

      &.selected {
        border: 2px solid #{$color-green-darker};
        background: #{$color-green-lighter};

        .toggle-button__circle {
          background-color: #{$color-green-darker};
          border-color: #{$color-green-darker};
        }
      }
    }

    &.invalid {
      color: #{$color-red};
    }

    &.invalid &__label {
      border: 1px solid #{$color-red};

      & input::after {
        border: 2px solid #{$color-red};
      }
    }

    input {
      display: none;
    }
  }
}
</style>
