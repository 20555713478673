<template>
  <div v-if="visible" :class="containerBackgroundColorClass">
    <div class="icon-list-three__container content-container-12">
      <ScText tag="h3" class="text-xl title-md-m text-center full-width m-b-xl m-md-b-3xl" :field="fields.IconListTitle" />
      <div class="icon-list-three flex">
        <div v-for="(item, i) in fields.IconListItems" :key="i" class="icon-list-three__item flex">
          <div class="image-container flex justify-content--center align-items--center">
            <ScImage :media="item.fields.IconListItemImage" />
          </div>
          <div class="text-container">
            <div class="text-content">
              <ScText tag="h4" class="title text-l text-md-l m-b-xs m-md-b-s" :field="item.fields.IconListItemTitle" />
              <ScRichText class="text-m text-md-m" tag="div" :field="item.fields.IconListItemText" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'IconListThree',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    visible() {
      if (this.fields.HideWhenStoreValueDefinedEqualsValue?.value) {
        const storeValue = this.$store.state.app[this.fields.HideWhenStoreValueDefined?.value];
        const storeValueAsString = typeof storeValue !== 'string' ? String(storeValue) : storeValue;

        const fieldValue = this.fields.HideWhenStoreValueDefinedEqualsValue?.value;
        const fieldValueAsString = typeof fieldValue !== 'string' ? String(fieldValue) : fieldValue;

        return storeValueAsString !== fieldValueAsString; // TODO: create a helper function for comparing values
      }
      if (this.fields.HideWhenStoreValueDefined?.value) {
        return !Object.hasOwn(this.$store.state.app, this.fields.HideWhenStoreValueDefined.value);
      }
      return true;
    },
    containerBackgroundColorClass() {
      const value = this.fields?.ContainerBackgroundColor?.fields.Type.value;
      if (value) return `bg-${value}`;
      else return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-list-three {
  gap: $spacing-l $spacing-m;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  &__container {
    padding: $spacing-xxl $spacing-m;
    flex-direction: row;
  }

  &__item {
    flex-direction: row;
    flex-basis: 100%;
    gap: $spacing-l $spacing-s;
    @include desktop {
      flex-direction: column;
      text-align: center;
    }

    .image-container {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      margin: 0 auto;

      @include desktop {
        width: 72px;
        height: 72px;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        @include desktop {
          height: 100%;
          width: auto;
          max-height: 100%;
          max-width: auto;
        }
      }
    }
  }
}
</style>
