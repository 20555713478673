<template>
  <div v-if="visible" class="user-selector">
    <ScText tag="h2" :field="fields.UserSelectorTitle" class="user-selector__title text-l text-md-l" />

    <div class="flex justify-content--center">
      <div class="user-selector__counter" ref="counter">
        <button @click="decrementCounter" :disabled="disabled">
          <i class="icon icon-minus" />
        </button>

        <span aria-live="assertive" class="user-selector__counter-result text-m text-md-m">
          {{ counter + ' ' + $t('persons') }}
        </span>

        <button @click="incrementCounter" :class="{ disabled: disabledMaxValue }">
          <i class="icon icon-plus" />
        </button>
      </div>
    </div>

    <div :class="{ visible: showMaxValueTooltip }" class="user-selector__validator">
      <div class="tooltip text-xs text-md-xs" ref="tooltip">
        <i class="icon-alert-circle"></i>
        <p v-html="maxUsersText" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { getProductIndex } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'UserSelector',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      defaultValue: 2,
      showMaxValueTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      usersCount: 'basket/getUsersCount',
      basket: 'basket/getBasket',
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
    }),
    counter: {
      get() {
        return this.usersCount || this.defaultValue;
      },
      set(value) {
        this.updateCounterInStore(value);

        if (this.basketItems && this.basketItems.length > 0) {
          const product = this.basketItems[0].product;
          this.$root.$emit('onSaveMultipleProducts', product, getProductIndex(this.allProducts, product), this.usersCount);
        }
      },
    },
    allProducts() {
      return this.$store.state.app?.allProducts;
    },
    disabled() {
      return this.counter <= this.defaultValue;
    },
    disabledMaxValue() {
      const maxValue = this.fields.UserSelectorMaxValue?.value;
      if (!maxValue) return false;
      return this.counter >= maxValue;
    },
    maxUsersText() {
      return this.fields.UserSelectorMaxValueText?.value;
    },
    basketItems() {
      return this.basket.items;
    },
    visible() {
      return !this.isRecruitmentOrderType || this.$store.state.app[this.fields.ToggleByStoreValue?.value];
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  created() {
    if (!this.usersCount) this.updateCounterInStore(this.defaultValue);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    incrementCounter() {
      const maxValue = this.fields.UserSelectorMaxValue?.value;

      if (this.counter === maxValue) this.showMaxValueTooltip = true;
      else this.counter++;
    },
    decrementCounter() {
      if (this.disabled) return;

      this.counter--;
      this.showMaxValueTooltip = false;
    },
    updateCounterInStore(value) {
      this.$store.dispatch('basket/addItem', { key: 'usersCount', value });
    },
    handleClickOutside(event) {
      const tooltip = this.$refs.tooltip;
      const counter = this.$refs.counter;
      if (this.showMaxValueTooltip && !tooltip.contains(event.target) && (!counter || !counter.contains(event.target))) {
        this.showMaxValueTooltip = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-selector {
  margin-bottom: $spacing-m;

  @include desktop {
    margin-top: $spacing-m;
    margin-bottom: 0;
  }

  &__title {
    text-align: center;
    margin-bottom: $spacing-xs;
  }

  &__counter {
    gap: 0 12px;

    &-result {
      width: 90px;
      text-align: center;
      flex-shrink: 0;
      white-space: nowrap;
    }

    button {
      width: 42px;
      height: 42px;
    }
  }

  &__validator {
    display: flex;
    justify-content: center;
    position: relative;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    @include transition(0.25s);

    &.visible {
      visibility: visible;
      opacity: 1;
      @include transition(0.25s);
    }

    .tooltip {
      background-color: $color-yellow-light;
      padding: $spacing-s;
      margin-top: $spacing-xs;
      color: $color-black;
      position: absolute;
      display: flex;
      align-items: center;
      gap: $spacing-s;
      @include border-radius(16px);

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: -$spacing-xs;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 16px;
        height: 16px;
        background-color: $color-yellow-light;
        transform: rotate(-45deg);
      }
      .icon-alert-circle {
        &:before {
          font-family: $talkmore-icons;
          content: map-get($icons, icon-alert-circle);
          font-size: 26px;
        }
      }
      &.text-xs {
        ::v-deep a.link {
          text-decoration-thickness: 1px;
        }
      }
    }
  }
}
</style>
