<template>
  <div class="user-overview__list desktop-narrow">
    <div class="user-overview">
      <Pill v-if="isOwner" class="text-xs" color="green" :text="$t('owner-and-user')"></Pill>
      <div class="user-overview__left">
        <ScText tag="h4" class="text-m text-md-m" :field="fields.UserInfoTitle" />
        <span>{{ fullName }}</span>
        <span>{{ birthday }}</span>
      </div>
      <div class="user-overview__right">
        <button
          type="button"
          class="button-textlink button-small transparent-btn"
          @click="$root.$emit('overlay-route', fields.ChangeUserInfoOptionLink)"
        >
          <span class="main">
            <span class="text text-s text-normal text-md-m">{{ $t('change') }}</span>
          </span>
        </button>
      </div>
    </div>

    <div class="user-overview">
      <div class="user-overview__left">
        <ScText tag="h4" class="text-m text-md-m" :field="fields.UserPhonenumberTitle" />
        <span v-if="isPorting">{{ `${$t('keeping')} ${formatPhonenumber(portingNumber)}` }}</span>
        <span v-else>{{ $t('new-number') }}</span>
      </div>
      <div class="user-overview__right">
        <button
          class="button-textlink button-small transparent-btn"
          type="button"
          @click="$root.$emit('overlay-route', fields.ChangePhonenumberOptionLink)"
        >
          <span class="main">
            <span class="text text-s text-normal text-md-m">{{ $t('change') }}</span>
          </span>
        </button>
      </div>
    </div>

    <div class="user-overview">
      <div class="user-overview__left">
        <ScText tag="h4" class="text-m text-md-m" :field="fields.UserSimTitle" />
        <span>{{ $t(`${isEsim ? 'esim' : 'sim-card'}`) }}</span>
        <span>{{ simInfoText }}</span>
      </div>
      <div class="user-overview__right">
        <button
          type="button"
          class="button-textlink button-small transparent-btn"
          @click="$root.$emit('overlay-route', fields.ChangeSimcardOptionLink)"
        >
          <span class="main">
            <span class="text text-s text-normal text-md-m">{{ $t('change') }}</span>
          </span>
        </button>
      </div>
    </div>

    <ScText tag="p" class="text-m text-md-m text-center m-t-l m-md-t-xs m-b-l" :field="fields.OverviewText" />
  </div>
</template>

<script>
import { Pill } from '@/sharedComponents';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'UserOverview',
  components: {
    Pill,
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      isOwner: this.$store.state.basket.currentItem.user.info === 'owner',
      isPorting: this.$store.state.basket.currentItem.user.porting === 'keep-number',
      isEsim: this.$store.state.basket.currentItem.user.simType === 'esim',
      isSim: this.$store.state.basket.currentItem.user.simType === 'sim',
      isSentByPost: this.$store.state.basket.currentItem.user.simCardDeliveryType === '0',
      firstname: this.$store.state.basket.currentItem.user.firstname,
      lastname: this.$store.state.basket.currentItem.user.lastname,
      birthday: this.$store.state.basket.currentItem.user.birthday,
      portingNumber: this.$store.state.basket.currentItem.user.phonenumber,
    };
  },
  computed: {
    fullName() {
      return `${this.firstname} ${this.lastname}`;
    },
    simInfoText() {
      if (this.isEsim) return this.$t('activate-on-my-pages');
      if (this.isSim && !this.isSentByPost) return this.$t('pick-up');
      return this.$t('sent-by-post');
    },
  },
  methods: {
    formatPhonenumber(phonenumber) {
      const lastEightDigits = phonenumber.substr(phonenumber.length - 8);
      return `${lastEightDigits.slice(0, 3)} ${lastEightDigits.slice(3, 5)} ${lastEightDigits.slice(5, 9)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-overview {
  display: flex;
  position: relative;
  gap: 0 12px;
  border: 1px solid #{$color-grey};
  border-radius: 16px;
  padding: 12px 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 20px 0;
    @include screen-tablet-portrait-up {
      gap: 24px 0;
    }
  }
  .pill {
    position: absolute;
    right: 8px;
    top: -15px;
  }
  &__left {
    flex: 1;
    span {
      display: block;
    }
  }
  &__right {
    display: flex;
    button {
      margin: 0 -16px 0 0;
    }
  }
}
</style>
