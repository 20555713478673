<template>
  <ul class="spot-list">
    <li v-for="(spot, i) in spots" :key="i">
      <ScImage :media="spot.fields.Image" :style="styleClass" />
      <ScText tag="span" :field="spot.fields.Text" class="text-s text-md-s" />
    </li>
  </ul>
</template>

<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'SpotList',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    spots: {
      type: Array,
    },
    imgsize: {
      type: String,
    },
    imgmdsize: {
      type: String,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    styleClass() {
      // we make it possible to have different icon size for mobile and desktop
      // 800 = $screen-tablet-portrait-up-min value
      if (this.windowWidth < 800 || !this.imgmdsize) {
        return `width:${this.imgsize}`;
      } else {
        return `width:${this.imgmdsize}`;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.spot-list {
  list-style: none;
  flex-direction: column;
  display: flex;
  gap: 12px;
  li {
    display: flex;
    width: 100%;
    height: auto;
    gap: 12px;
    align-items: center;
    img {
      width: 24px;
      height: auto;
    }
    span {
      align-self: flex-end;
    }
  }
  &.center {
    li {
      justify-content: center;
    }
  }
}
</style>
