function pushToGtm(object) {
  window.dataLayer?.push(object);
  // console.log('Push to GTM: ', object);
}

function replaceNullWithUndefined(array) {
  array = array.map((item) => {
    for (const key in item) {
      if (item[key] === null) {
        item[key] = undefined;
      }
    }
    return item;
  });
  return array;
}

export function removeDuplicateGtmItems(gtmRemovedItems, gtmAddedItems) {
  // Combine both arrays with a tag to identify their origin
  const combined = [
    ...gtmRemovedItems.map((item) => ({ ...item, origin: 'removed' })),
    ...gtmAddedItems.map((item) => ({ ...item, origin: 'added' })),
  ];

  // Filter out duplicates within and across both arrays based on price and coupon
  const filtered = combined.filter(
    (item, index, self) =>
      self.findIndex((t) => t.price === item.price && t.coupon === item.coupon) === index &&
      !(
        gtmRemovedItems.some((t) => t.price === item.price && t.coupon === item.coupon) &&
        gtmAddedItems.some((t) => t.price === item.price && t.coupon === item.coupon)
      ),
  );

  // Split the filtered items back into removed and added, removing the origin tag
  const uniqueRemovedItems = filtered.filter((item) => item.origin === 'removed').map(({ origin, ...rest }) => rest);
  const uniqueAddedItems = filtered.filter((item) => item.origin === 'added').map(({ origin, ...rest }) => rest);

  return [uniqueRemovedItems, uniqueAddedItems];
}

export function gtmEcommerceEvent({ event, items, gtmSharedParams, gtmSharedEcommerceParams = null, value = null }) {
  // loop through items and replace null-values with undefined
  items = replaceNullWithUndefined(items);
  pushToGtm({
    event,
    ecommerce: {
      ...(value != null && { value }),
      ...(gtmSharedEcommerceParams && { ...gtmSharedEcommerceParams }),
      items,
    },
    _clear: true,
    ...gtmSharedParams,
  });
}

export function gtmPurchaseEvent({ transaction_id, tnIDs, value, tax, items, gtmSharedParams }) {
  const event = 'purchase';
  const currency = 'NOK';
  const affiliation = 'privat';
  const shipping = 0;
  transaction_id = transaction_id?.toString();
  tnIDs = tnIDs?.toString();

  // loop through items and replace null-values with undefined
  items = replaceNullWithUndefined(items);
  // add transaction_id to each item
  items = items.map((item) => ({ ...item, tn_id: transaction_id }));

  pushToGtm({
    event,
    tnIDs, // a comma-separated list of all Telenordic IDs in the order
    ecommerce: {
      transaction_id,
      value,
      tax,
      shipping,
      currency,
      affiliation,
      items,
    },
    _clear: true,
    ...gtmSharedParams,
  });
}

export function gtmEproviderDisplayedEvent({ providers, gtmSharedParams }) {
  if (Array.isArray(providers) && providers.length > 0) {
    providers = providers.map((p) => p.displayName).join(', ');
  }
  pushToGtm(gtmEproviderEventModel('eprovider displayed', providers, gtmSharedParams));
}

export function gtmEproviderClickedEvent({ providers, gtmSharedParams }) {
  pushToGtm(gtmEproviderEventModel('eprovider clicked', providers, gtmSharedParams));
}

export function gtmEproviderCallbackEvent({ providers, isSuccess, gtmSharedParams }) {
  pushToGtm(gtmEproviderEventModel('eprovider callback', providers, gtmSharedParams, isSuccess));
}

function gtmEproviderEventModel(action, providers, gtmSharedParams, callbackStatus = null) {
  const status = callbackStatus === null ? null : callbackStatus ? 'success' : 'error';
  return {
    event: 'eprovider_event',
    action,
    providers,
    ...(status && { status }),
    ...gtmSharedParams,
  };
}

export function gtmErrorMessageEvent({ message = null, gtmSharedParams }) {
  pushToGtm({
    event: 'error_message',
    error_message: message,
    context: 'checkout',
    ...gtmSharedParams,
  });
}

export function gtmModalViewEvent({ modal_name, gtmSharedParams }) {
  pushToGtm({
    event: 'modal_view',
    context: 'checkout',
    modal_name,
    ...gtmSharedParams,
  });
}

export function gtmModalClickEvent({ modal_name, modal_selection, gtmSharedParams }) {
  pushToGtm({
    event: 'modal_click',
    context: 'checkout',
    modal_name,
    modal_selection,
    ...gtmSharedParams,
  });
}

export function gtmCheckoutSubscriberEvent({ sub_index, sub_type, sub_step, gtmSharedParams }) {
  pushToGtm({
    event: 'checkout_subscriber',
    ...(sub_index && { sub_index }),
    sub_type,
    sub_step,
    ...gtmSharedParams,
  });
}

export function gtmConsentEvent({ gtmSharedParams }) {
  pushToGtm({
    event: 'checkout_consent',
    ...gtmSharedParams,
  });
}

export function gtmAddCouponEvent({ coupon, gtmSharedParams }) {
  if (coupon.length === 8 && !isNaN(coupon)) coupon = 'verving'; // check for a Norwegian phone number TODO: test on valid number
  pushToGtm({
    event: 'add_coupon',
    coupon,
    ...gtmSharedParams,
  });
}
