<template>
  <span>{{ getProductTitle }}</span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SummaryProductTitle',
  props: {
    productItem: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      usersCount: 'basket/getUsersCount',
    }),
    getProductTitle() {
      let basketTitle = this.$store.state.config?.basketItemTitle;
      const title = this.productItem?.product?.fields?.Title?.value;
      if (basketTitle && basketTitle.includes('{Title}')) {
        basketTitle = basketTitle.replace('{Title}', title);
      }
      if (basketTitle && basketTitle.includes('{RatePlanTitle}')) {
        let ratePlanTitle = this.$t('label-single');
        if (this.productItem?.priceKey === 'U30') ratePlanTitle = this.$t('label-u30'); // TODO: define U30 price key in config
        if (this.usersCount) ratePlanTitle = this.$t('label-shared-data');
        basketTitle = basketTitle.replace('{RatePlanTitle}', ratePlanTitle);
      }
      return basketTitle;
    },
  },
};
</script>
