<template>
  <div class="seperator" />
</template>

<script>
export default {
  name: 'Seperator',
};
</script>

<style lang="scss">
.seperator {
  margin: 24px 0;
  height: 1px;
  width: 100%;
  background-color: #{$color-grey};
}
</style>
