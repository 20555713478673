<template>
  <div class="extra-sim m-t-s desktop-narrow">
    <div class="extra-sim__container flex flex-column">
      <div class="flex flex-column extra-sim__container--top">
        <div class="flex extra-sim__container--top__head">
          <ScImage :media="fields.ExtraSimImage" />
          <ScText class="extra-sim__title text-m text-md-m" tag="h4" :field="fields.ExtraSimCounterTitle" />
        </div>
        <ScText class="extra-sim__title text-s text-md-s" tag="span" :field="fields.ExtraSimText" />
      </div>

      <div class="extra-sim__container--bottom">
        <ScText tag="p" class="extra-sim__text text-s text-md-s" :field="fields.ExtraSimCounterText" />
        <div class="extra-sim-counter">
          <template>
            <button
              type="button"
              value="minus"
              class="cursor-pointer"
              @click="handleExtraSim(extraSim - 1)"
              :disabled="minValueReached"
            >
              <span>
                <i class="icon icon-minus" />
              </span>
            </button>

            <p class="extra-sim__result bold text-l text-md-l">{{ extraSim }}</p>
          </template>

          <button
            type="button"
            class="cursor-pointer"
            :disabled="maxValueReached"
            value="plus"
            @click="handleExtraSim(extraSim + 1)"
          >
            <span>
              <i class="icon icon-plus-bold" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams, gtmExtraSimParams } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'ExtraSim',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    maxValueReached() {
      return this.extraSim === this.fields.ExtraSimMaxValue.value;
    },
    minValueReached() {
      return this.extraSim === 0;
    },
    extraSim() {
      return this.$store.state.basket?.currentItem?.extraSim ?? 0;
    },
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'checkout_simcards',
      ...gtmSharedParams(),
    });
  },
  methods: {
    handleExtraSim(value) {
      // determine +/- event before updating store
      const event = this.extraSim > value ? 'remove_from_cart' : 'add_to_cart';

      this.$store.dispatch('basket/appendToCurrentItem', { key: 'extraSim', value });

      const items = [gtmExtraSimParams];
      gtmEcommerceEvent({ event, items, gtmSharedParams: gtmSharedParams() });
    },
  },
};
</script>

<style lang="scss">
.extra-sim {
  &__result {
    width: 16px;
    text-align: center;
  }
  &-counter {
    display: flex;
    align-items: center;
    gap: 9px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      border: none;
      width: 48px;
      height: 48px;
      font-size: 20px;
      background-color: transparent;
      span {
        background-color: #{$color-green};
        height: 42px;
        width: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(0.1s);
      }
      &:hover {
        span {
          background-color: #{$color-green-dark};
          @include transition(0.1s);
        }
      }
      &--disabled,
      &:disabled {
        cursor: not-allowed;

        span {
          color: #{$color-black};
          background: #{$color-grey-tint-light};
        }
        &:hover {
          span {
            background: #{$color-grey-tint-light};
            cursor: not-allowed;
            border-color: #{$color-grey-dark};
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 24px 16px;
    gap: 16px;
    border-radius: 16px;
    background: #{$color-blue-lighter};
    width: 100%;

    &--top {
      padding-bottom: 16px;
      border-bottom: 1px solid #{$color-grey-dark};
      width: 100%;
      &__head {
        gap: 0 16px;
        align-items: center;
        margin-bottom: 12px;

        img {
          max-height: 32px;
          max-width: 32px;
          width: 32px;
          height: auto;
        }
      }
    }
    &--bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
</style>
