import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from '../lib/PersistedState';

import app from './modules/app';
import basket from './modules/basket';
import config from './modules/config';
import { getOidcStoreModule } from './modules/oidc';
import { getPartnerOidcStoreModule } from './modules/partnerOidc';

Vue.use(Vuex);

const plugins = [];

if (process.browser) {
  plugins.push(createPersistedState);
}

const store = new Vuex.Store({
  modules: {
    app,
    basket,
    config,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins,
});

// we can't load oidcStore as a static module because we want to unregister it and re-register it again in runtime
store.registerModule('oidcStore', getOidcStoreModule());

// we can't load oidcStore as a static module because we want to unregister it and re-register it again in runtime
store.registerModule('partnerOidcStore', getPartnerOidcStoreModule());

export default store;
