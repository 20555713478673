<template>
  <div class="consent text-s text-md-s">
    <ScText tag="h5" class="consent__title text-l text-md-m" :field="fields.ConsentTitle" />
    <ScText tag="p" class="consent__text" :field="fields.ConsentText" />
    <ScText
      tag="a"
      tabindex="0"
      class="link"
      @click="openOverlay"
      @keydown.enter.space.prevent="openOverlay"
      :field="fields.ConsentBottomSheetText"
    />

    <div class="consent__checkbox check-box">
      <input type="checkbox" class="checkbox__input" :id="sms" :name="sms" v-model="smsValue" @change="onChange($event)" />
      <ScText :for="sms" tag="label" class="consent__checkbox-label" :field="fields.ConsentSmsText" />
    </div>

    <div class="consent__checkbox check-box">
      <input type="checkbox" class="checkbox__input" :id="email" :name="email" v-model="emailValue" @change="onChange($event)" />
      <ScText :for="email" tag="label" class="consent__checkbox-label" :field="fields.ConsentEmailText" />
    </div>

    <BottomSheet
      v-if="fields.ConsentBottomSheetText.value"
      ref="consentBottomSheet"
      :closeText="fields.BottomSheetCloseText.value"
    >
      <ScText tag="h4" class="title-xxs title-md-xs" :field="fields.BottomSheetTitle" />
      <hr />
      <ScRichText tag="div" class="text-m" :field="fields.BottomSheetText" />
      <div class="m-t-s flex justify-content--center">
        <button class="button secondary-btn text-m" @click="closeOverlay" style="width: auto">
          <span class="main">
            <span class="text">{{ fields.BottomSheetCloseText.value }}</span>
          </span>
        </button>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmConsentEvent, gtmModalViewEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import BottomSheet from './util/BottomSheet.vue';

export default {
  name: 'Consent',
  components: {
    ScText: Text,
    ScRichText: RichText,
    BottomSheet,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      sms: 'consentSms',
      smsValue: this.$store.state.app.consentSms,
      email: 'consentEmail',
      emailValue: this.$store.state.app.consentEmail,
    };
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gtmConsentEvent({ gtmSharedParams: gtmSharedParams() });
        }
      });
    });
    observer.observe(this.$el);
  },
  methods: {
    openOverlay() {
      this.$refs.consentBottomSheet.open();

      const modal_name = this.fields.BottomSheetTitle?.value;
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeOverlay() {
      this.$refs.consentBottomSheet.close();
    },
    onChange(e) {
      const key = e.target.id;
      const value = e.target.checked;

      this.$store.dispatch('app/addItem', { key, value });
      console.log(`${this.$options.name}: Saved value: '${value}' for key: '${key}'`);
    },
  },
};
</script>

<style lang="scss" scoped>
.consent {
  margin-bottom: 24px;

  @include screen-tablet-portrait-up {
    padding: 0 16px 0;
  }

  &__title {
    padding-bottom: 16px;
    @include screen-tablet-portrait-up {
      padding-bottom: 12px;
    }
  }

  &__text {
    @include screen-tablet-portrait-up {
      padding-bottom: 12px;
    }
  }
  &__checkbox {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &-label {
      padding-left: $spacing-xs;
      align-self: center;
    }
  }
}
</style>
