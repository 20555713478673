<template>
  <div class="summary-product">
    <div class="summary-product__top">
      <div class="flex flex-direction--column summary-product__left">
        <ScText tag="h5" class="text-m text-md-m" :field="digitalSecurityTitle" />
        <div class="flex flex-direction--column text-xs text-md-s">
          <ScText tag="span" class="text-s text-md-s" :field="digitalSecurityDescription" />
          <ScText tag="span" class="text-s text-md-s" :field="digitalSecurityText" />
        </div>
      </div>
      <div class="flex flex-direction--column summary-product__top--right">
        <strong class="text-m text-md-m text-bold">{{ digitalSecurityPriceText }}</strong>
        <button class="button-textlink button-small transparent-btn" @click="removeDigitalSecurity()">
          <span class="main">
            <span class="text text-s text-normal text-md-m">{{ $t('remove') }}</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams, gtmDigitalSecurityParams } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'SummaryDigitalSecurity',
  components: {
    ScText: Text,
  },
  data() {
    return {
      digitalSecurityTitle: this.$store.state.config?.digitalSecurity?.title,
      digitalSecurityDescription: this.$store.state.config?.digitalSecurity?.description,
      digitalSecurityText: this.$store.state.config?.digitalSecurity?.text,
      digitalSecurityPriceText:
        this.$store.state.config.digitalSecurity.crmItem?.price + this.$t('price-denote') + this.$t('price-postfix'),
    };
  },
  methods: {
    removeDigitalSecurity() {
      const key = 'digitalSecurity';
      this.$store.dispatch('app/deleteItem', key);
      console.log(`${this.$options.name}: Removed key: '${key}'`);
      gtmEcommerceEvent({ event: 'remove_from_cart', items: [gtmDigitalSecurityParams()], gtmSharedParams: gtmSharedParams() });
    },
  },
};
</script>
