<template>
  <div v-if="visible" class="recruitment-info">
    <LoadingSpinner v-if="visibleSpinner" />
    <div v-if="visibleDefault" class="recruitment-info__success">
      <ScImage class="recruitment-info__success-icon" :media="fields.RecruitmentInfoIcon" aria-hidden="true" />
      <ScText tag="h1" :field="titleWithRecruiterName" class="title-s title-md-s" />
      <p>{{ recruitmentInfoText }}</p>
      <ScText tag="button" :field="fields.RecruitmentInfoButtonText" class="primary-btn text-s text-md-s" @click="onClick" />
      <ScLink :field="fields.RecruitmentInfoLink" class="text-s text-md-s" />
    </div>
    <div v-if="visibleError" class="recruitment-info__error">
      <ScImage :media="fields.RecruitmentInfoErrorImage" />
      <ScRichText tag="div" :field="fields.RecruitmentInfoErrorText" class="recruitment-info__error--text" />
      <ScLink :field="fields.RecruitmentInfoErrorLink" class="primary-btn text-s text-md-s" />
    </div>
  </div>
</template>

<script>
import { Text, Image, Link, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { validateRecruitmentCode } from '@/api';
import LoadingSpinner from '@/components/app/LoadingSpinner.vue';

export default {
  name: 'RecruitmentInfo',
  components: {
    ScText: Text,
    ScImage: Image,
    ScLink: Link,
    ScRichText: RichText,
    LoadingSpinner,
  },
  inject: {
    onEmitValue: {
      type: Function,
    },
    saveRecruitmentCode: {
      type: Function,
    },
    saveRecruiterName: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isRecruitmentCodeValid: {
      get() {
        return this.$store.state.app.isRecruitmentCodeValid;
      },
      set(value) {
        this.$store.dispatch('app/addItem', { key: 'isRecruitmentCodeValid', value });
      },
    },
    recruitmentInfoText() {
      return this.$store.state.config?.recruitmentCampaign?.infoText;
    },
    recruitmentCode() {
      return this.$store.state.app?.recruitmentCode;
    },
    recruiterName() {
      return this.$store.state.app?.recruiterName;
    },
    visible() {
      return !this.$store.state.app[this.fields.ToggleByStoreValue.value];
    },
    visibleDefault() {
      return this.isRecruitmentCodeValid === true && this.recruiterName && this.recruitmentCode;
    },
    visibleError() {
      return this.isRecruitmentCodeValid === false;
    },
    visibleSpinner() {
      return this.isRecruitmentCodeValid === null;
    },
    titleWithRecruiterName() {
      if (!this.recruiterName) return this.fields.RecruitmentInfoTitle;
      const modifiedTitle = { ...this.fields.RecruitmentInfoTitle };
      modifiedTitle.value = modifiedTitle.value.replace('{recruiterName}', this.recruiterName);
      return modifiedTitle;
    },
  },
  created() {
    const code = this.$route.query.kode;
    // If both codes are missing, mark as invalid and exit
    if (!code && !this.recruitmentCode) {
      this.isRecruitmentCodeValid = false;
      return;
    }
    if (code && code !== this.recruitmentCode) {
      // reset validation state and check new code
      this.isRecruitmentCodeValid = null;
      this.validateAndSaveRecruitmentCode(code);
    }
  },
  methods: {
    onClick() {
      if (this.fields?.ToggleByStoreValue?.value) {
        this.$store.dispatch('app/addItem', { key: this.fields.ToggleByStoreValue.value, value: true });
      }
    },
    async validateAndSaveRecruitmentCode(code) {
      let isValid = false;
      try {
        const encodedCode = encodeURIComponent(code);
        const response = await validateRecruitmentCode(encodedCode);
        if (response.data.isValid) {
          this.saveRecruitmentCode(code);
          this.saveRecruiterName(response.data);
          isValid = true;
        } else {
          console.error('Invalid recruitment code');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isRecruitmentCodeValid = isValid;
        if (!isValid) {
          this.$store.dispatch('app/deleteItem', this.fields.ToggleByStoreValue.value);
          this.$store.dispatch('app/deleteItem', 'recruitmentCode');
          this.$store.dispatch('app/deleteItem', 'recruiterName');
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.recruitment-info {
  padding: $spacing-m 0;
  text-align: center;

  &__success,
  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $spacing-m;

    &-icon {
      width: 72px;
      height: 72px;
    }
  }

  &__error {
    img {
      max-height: 95px;
      max-width: 100%;
      width: auto;
      height: auto;

      @include screen-tablet-portrait-up {
        max-height: 206px;
      }
    }
  }
}
.primary-btn {
  margin: 0;
}
</style>
