<template>
  <div v-if="visible" class="auth-login m-t-m m-md-t--clear">
    <div v-show="isEditorActive" class="experience-editor">
      <button class="secondary-btn" @click="hidden = !hidden">
        {{ hidden ? 'Show modal for editing' : 'Hide modal again' }}
      </button>
      <p>This button is only visible while editing in the experience editor</p>
    </div>

    <ModalOverlay :class="{ hidden }" @click="closeModal">
      <div class="auth-login__modal" ref="modal">
        <ScText class="auth-login__modal-title text-xs-l title-md-xs" tag="h4" :field="fields.ModalTitle" />
        <ScText class="auth-login__modal-text text-xs-m text-md-l" tag="p" :field="fields.ModalText" />

        <div class="auth-login__modal-button-container">
          <ScText
            tag="button"
            class="button button-auto-width primary-xs-small primary-md-small text-md-m"
            :field="fields.ModalButton"
            tabindex="0"
            @click="closeModal"
            @keydown.enter.prevent="closeModal"
            @keydown.space.prevent="closeModal"
          />
        </div>
      </div>
    </ModalOverlay>

    <ScText class="auth-login__title text-md-m text-m m-b-xs" tag="h2" :field="fields.AuthLoginTitle" />
    <!-- "tag" should always be a block element, otherwise SSR will fail when fields.AuthLoginText contains block elements -->
    <ScRichText class="auth-login__text full-width" tag="div" :field="fields.AuthLoginText" />

    <Heading :fields="fields" class="auth-login__title title-xs text-center" :heading="'h3'" />

    <div class="auth-login__container">
      <div
        role="button"
        tabindex="0"
        class="auth-login__provider no-select"
        @click="login(provider)"
        @keydown.enter.space.prevent="login(provider)"
        v-for="(provider, i) in fields.Provider"
        :key="i"
        :style="[{ backgroundColor: provider.fields.ProviderColor.value }]"
      >
        <div class="auth-login__text-container">
          <ScText class="auth-login__provider-text" tag="span" :field="provider.fields.ProviderTitle" />
          <ScImage class="auth-login__image" :media="provider.fields.ProviderImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { Heading, ModalOverlay } from '@/sharedComponents';
import { defaultOidcSettings } from '@/oidc';
import { getOidcStoreModule } from '@/store/modules/oidc';
import { toggleBodyScrollLock, focusTrapInsideElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';
import {
  gtmEcommerceEvent,
  gtmEproviderDisplayedEvent,
  gtmEproviderClickedEvent,
} from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { gtmSharedParams, getGtmItems } from '@/gtmTracking';
const packageConfig = require('@/../package.json');

export default {
  name: 'AuthLogin',
  components: {
    ScText: Text,
    Heading,
    ModalOverlay,
    ScImage: Image,
    ScRichText: RichText,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      hidden: true,
    };
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
    ...mapGetters({
      getBasket: 'basket/getBasket',
    }),
    visible() {
      return !this.$store.state.app.oidcToken;
    },
  },
  watch: {
    hidden() {
      focusTrapInsideElement(this.$refs.modal, !this.hidden, this.closeModal);
      toggleBodyScrollLock(!this.hidden);
    },
  },
  mounted() {
    if (this.visible && this.getBasket.items) {
      const value = this.getBasket.total;
      const items = getGtmItems();
      gtmEcommerceEvent({
        event: 'begin_checkout',
        items,
        gtmSharedParams: gtmSharedParams(),
        value,
      });
    }
    if (this.visible && this.fields.Provider.length > 0) {
      gtmEproviderDisplayedEvent({ providers: this.fields.Provider, gtmSharedParams: gtmSharedParams() });
    }
    // show the error modal if we had any issues logging in
    const error = localStorage.getItem('ShowErrorPage');
    this.hidden = !error ?? true;

    if (error) {
      localStorage.removeItem('ShowErrorPage');
      console.log(`${this.$options.name}: Removed item 'ShowErrorPage' from localStorage`);
    }
  },
  methods: {
    ...mapActions('oidcStore', ['authenticateOidc']),
    closeModal() {
      this.hidden = true;
    },
    login(provider) {
      // save selected provider name to store so we can use it in AuthInfo.vue
      this.$store.dispatch('app/addItem', { key: 'selectedProvider', value: provider.displayName });
      console.log(`${this.$options.name}: Saved value: '${provider.displayName}' for key: 'selectedProvider'`);
      gtmEproviderClickedEvent({ providers: provider.displayName, gtmSharedParams: gtmSharedParams() });

      // check if we already are authenticated. if so, no need to login again
      if (this.oidcIsAuthenticated) {
        this.$router.push(this.fields.Route.url);
        return;
      }

      // check if oidc store module is already registered and if so,
      // unload it so we can update our oidc settings
      if (this.$store.hasModule('oidcStore')) {
        this.$store.unregisterModule('oidcStore');
      }

      // load settings from sitecore, then overwrite to default oidc settings
      const currentUrl = window.location.origin;
      const redirectUri = `${currentUrl}${packageConfig.config.sitecoreRedirectPath}`;

      defaultOidcSettings.acr_values = provider?.fields?.ProviderAcrValue?.value;
      defaultOidcSettings.authority = this.$store.state.config.oidcAuthority;
      defaultOidcSettings.redirect_uri = redirectUri;
      defaultOidcSettings.popup_redirect_uri = redirectUri;
      defaultOidcSettings.post_logout_redirect_uri = redirectUri;

      // reload oidcStore with new and updated settings
      this.$store.registerModule('oidcStore', getOidcStoreModule(defaultOidcSettings));

      // save orderflow config id so we can load it once we got redirected back
      // to the redirect page containing the OidcRedirect.vue component.
      // the redirect page is not tied to a specific orderflow but is a general
      // page related to the private orderflow so it does not have its own config
      localStorage.setItem('ConfigId', this.$store.state.app.configId);
      console.log(`${this.$options.name}: Saved value: '${this.$store.state.app.configId}' for key 'ConfigId' in localStorage`);

      // save current page so we can redirect back to it in case anything fails during login
      localStorage.setItem('ErrorPage', window.location.pathname);
      console.log(`${this.$options.name}: Saved value: '${window.location.pathname}' for key 'ErrorPage' in localStorage`);

      // save final landing page for after redirect
      localStorage.setItem('RedirectPage', this.fields.Route.url);
      console.log(`${this.$options.name}: Saved value: '${this.fields.Route.url}' for key 'RedirectPage' in localStorage`);

      // start the criipto login process and redirect to either bankid or vipps login
      this.authenticateOidc();
    },
  },
};
</script>

<style lang="scss">
.auth-login {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__modal {
    max-width: 576px;
    width: Calc(100% - 24px);
    margin: 0 12px;
    max-height: 90vh;
    background: #{$color-white};
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop {
      max-width: $desktop-wide-component;
      padding: 32px 32px;
    }

    &-title {
      margin-bottom: 4px;
    }

    &-button {
      margin: 0;
      max-width: 295px;
    }

    &-button-container {
      margin: 24px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: $spacing-xs;
  }

  &__container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    max-width: 300px;
    margin-bottom: 8px;
  }

  &__provider {
    font-family: $font-bold;
    border-radius: 30px;
    border: 1px solid #{$color-grey};
    cursor: pointer;
    padding: 16px 24px;
    color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    line-height: 145%;
    min-height: 60px;
  }

  &__text {
    text-align: left;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 8px;
    }
  }

  &__image {
    max-height: 19px;
    width: auto;
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
  }

  &__underline {
    display: none;
    @include desktop {
      display: block;
    }
  }
}
</style>
