<template>
  <div class="desktop-narrow">
    <div class="helping-title__container" :class="fields.HeadingClass.value">
      <ScText class="text-m text-md-m" tag="h3" :field="fields.HeadingTitle" />
      <button :aria-label="ariaLabel" type="button" class="transparent-btn" @click="showInfo">
        <i class="icon icon-info-circle" aria-hidden="true" />
      </button>
    </div>
    <BottomSheet ref="headingSimBottomSheet" :closeText="fields.BottomSheetCloseText.value">
      <ScText tag="h4" class="title-xxs title-md-xs" :field="fields.BottomSheetTitle" />
      <hr />
      <ScRichText tag="div" class="text-m" :field="fields.BottomSheetText" />
      <div class="m-t-s flex justify-content--center">
        <button class="button secondary-btn text-m" @click="closeInfo" style="width: auto">
          <span class="main">
            <span class="text">{{ fields.BottomSheetCloseText.value }}</span>
          </span>
        </button>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import BottomSheet from './util/BottomSheet.vue';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmModalViewEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'HeadingWithBottomsheet',
  components: {
    ScText: Text,
    ScRichText: RichText,
    BottomSheet,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    ariaLabel() {
      return this.fields.BottomSheetOpenButtonAriaLabel?.value;
    },
  },
  methods: {
    showInfo() {
      this.$refs.headingSimBottomSheet.open();
      const modal_name = this.fields.BottomSheetTitle?.value;
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeInfo() {
      this.$refs.headingSimBottomSheet.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.helping-title__container {
  button {
    margin-top: -12px; // we make the button size seem normal, even though the button's pressure size is bigger than the icon.
    margin-bottom: -12px; // we make the button size seem normal, even though the button's pressure size is bigger than the icon.
    @include infoButtonEffects();
  }
}
</style>
