<template>
  <div class="summary desktop-wide">
    <div class="summary-head">
      <ScText tag="h3" class="title-xxs title-md-xs" :field="fields.SummaryTitle" />
      <ScText tag="p" class="text-m text-md-s" :field="fields.SummaryText" />
    </div>
    <div class="summary-product__list">
      <ScText tag="h4" class="text-l text-md-l p-b-s p-md-b-m" :field="fields.SummaryHeading" />

      <SummaryContent :fields="fields" />

      <Membership v-if="isPartnerOrderType" />

      <Recruitment :fields="fields" v-if="!isPartnerOrderType" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import SummaryContent from './util/SummaryContent.vue';
import Recruitment from './util/Recruitment.vue';
import { gtmSharedParams } from '@/gtmTracking';
import Membership from './util/Membership.vue';

export default {
  name: 'Summary',
  components: {
    ScText: Text,
    Recruitment,
    SummaryContent,
    Membership,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isPartnerOrderType: 'config/isPartnerOrderType',
    }),
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'checkout_confirmation',
      ...gtmSharedParams(),
    });
  },
};
</script>

<style lang="scss" scoped>
.summary {
  display: flex;
  flex-direction: column;
  background-color: $color-grey-lighter;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 24px;
  padding: 24px 16px 24px;
  @include phone-xs {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }
  @media screen and (min-width: 612px) {
    background-color: transparent;
  }
  @include screen-tablet-portrait-up {
    padding-top: 0;
  }
  &-head {
    padding-bottom: 16px;
  }
  &-product__list {
    padding: 0;

    // specific min-width breakpoint added due to the transition between full width grey lighter background and then a boxed grey-ligter box.
    // Done so that we got the white margin on each side of summary on devices more than 612px.
    @media screen and (min-width: 612px) {
      margin-top: 8px;
      padding-top: 32px;
      background-color: $color-grey-lighter;
      border-radius: 30px;
      margin-bottom: 24px;
      padding: 32px 24px;
    }
  }
}
</style>
