<template>
  <div v-if="route" class="overlay">
    <template v-if="showStepIndicator">
      <div class="step-indicator__container">
        <div class="step-indicator">
          <progress
            class="step-indicator__progress"
            id="progress-bar"
            :value="route.fields.ProgressBarPercentage.value"
            max="100"
          />
        </div>
      </div>

      <ul class="overlay__steps">
        <li
          v-for="page in fields.Pages"
          :key="page.id"
          :class="{ active: page.fields.pageTitle.value === route.fields.pageTitle.value }"
        >
          {{ page.fields.pageTitle.value }}
        </li>
      </ul>
    </template>

    <Placeholder class="overlay-main" name="Main" :rendering="route" ref="content" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dataApi, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import config from './../temp/config';
import { dataFetcher } from './../dataFetcher';
import { gtmSharedParams, getGtmItems } from '@/gtmTracking';
import { gtmCheckoutSubscriberEvent, gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { calculateAge, focusOnFirstTabbableElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'Overlay',
  components: {
    Placeholder,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      route: null,
      currentPage: null,
      disabled: true,
      visited: [],
      eventListeners: [
        { event: 'overlay-route', handler: this.setPage },
        { event: 'overlay-route-back', handler: this.goBack },
        { event: 'onHandleUserData', handler: this.handleUserData },
        { event: 'onHandleSimData', handler: this.handleSimData },
        { event: 'onSaveCurrentUser', handler: this.saveCurrentUser },
        { event: 'onSaveOverlayData', handler: this.saveOverlayData },
        { event: 'onUserDone', handler: this.userDone },
        { event: 'onHandleExtraSim', handler: this.onHandleExtraSim },
      ],
      overlayPrefix: 'overlay-',
    };
  },
  computed: {
    ...mapGetters({
      getItems: 'basket/getItems',
      currentUserSimDeliveryType: 'basket/getCurrentUserSimDeliveryType',
    }),
    previousPage() {
      const p = this.visited.filter((f) => f.fields.pageNumber.value < this.currentPage.fields.pageNumber.value)?.slice(-1);
      return p.length ? p : null;
    },
    showStepIndicator() {
      return this.currentPage.fields.pageTitle?.value;
    },
    currentItem() {
      return this.$store.state.basket?.currentItem;
    },
  },
  async mounted() {
    this.currentPage = this.fields.Pages[0];

    this.eventListeners.forEach(({ event, handler }) => this.$root.$on(event, (...args) => handler(...args)));

    // we only got one item in basket store so we can auto set current item
    if (this.getItems.length === 1) {
      let currentItem;
      currentItem = this.$store.getters['basket/getItems'][0];

      if (Object.hasOwn(currentItem, 'user')) this.loadCurrentItem(currentItem);
      else currentItem.user = {};

      if (currentItem.user.info) console.log('Overlay: Set current item in basket store:', currentItem.user.info);
      else console.log('Overlay: Set empty current item in basket store');

      await this.$store.dispatch('basket/addCurrentItem', currentItem);
    }

    this.setPage(this.currentPage);
  },
  beforeDestroy() {
    this.eventListeners.forEach(({ event }) => this.$root.$off(event));
  },
  methods: {
    loadCurrentItem(item) {
      if (!item.user) return;

      // take all properties from currentItem and load them into app store
      for (const [oldKey, value] of Object.entries(item.user)) {
        const key = `${this.overlayPrefix}${oldKey}`;
        // console.log(`loadCurrentItem: Write current user data to app store - key: ${key} / value: ${value}`);
        this.$store.dispatch('app/addItem', { key, value });
      }
    },
    saveCurrentUser() {
      // take all prefixed properties from app store and append them to current user
      for (const [oldKey, value] of Object.entries(this.$store.state.app)) {
        if (oldKey.startsWith(this.overlayPrefix)) {
          const key = oldKey.replace(this.overlayPrefix, '');
          // console.log(`saveCurrentUser: append new data to current user - key: ${key} / value: ${value}`);
          // continue to the next key if current user already has the the same key-value
          if (this.currentItem?.user[key] === value) continue;
          if (this.currentItem?.isCompleted) this.$store.dispatch('basket/removeFromCurrentItem', 'isCompleted');
          this.$store.dispatch('basket/appendToCurrentUser', { key, value });
        }
      }
    },
    handleSimData() {
      const previousSimType = this.currentItem?.user?.simType;
      const currentSimType = this.$store.state.app?.['overlay-simType'];

      if (currentSimType === previousSimType) return;

      if (currentSimType === 'esim') {
        console.log('handleSimData: remove extra sim and simCardDeliveryType info from store');
        this.$store.dispatch('basket/removeFromCurrentItem', 'extraSim');
        this.$store.dispatch('basket/removeFromCurrentUser', 'simCardDeliveryType');
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}simCardDeliveryType`);
      } else if (currentSimType === 'sim') {
        const isNewNumber = this.$store.state.app?.['overlay-porting'] === 'new-number';

        if (isNewNumber) {
          console.log('handleSimData: pre-set simCardDeliveryType to 0 since we selected new number');
          const value = '0';
          // we can only choose to 'pick up' sim in store, so we can preselect simCardDeliveryType to be 'delivery'
          this.$store.dispatch('basket/appendToCurrentUser', { key: 'simCardDeliveryType', value });
          this.$store.dispatch('app/addItem', { key: `${this.overlayPrefix}simCardDeliveryType`, value });
        }
      }
    },
    handleUserData() {
      const previousSelected = this.currentItem?.user?.info;
      const nowSelected = this.$store.state.app?.['overlay-info'];

      if (nowSelected === previousSelected) return;

      if (nowSelected === 'owner') {
        console.log('handleUserData: save owner info to store');
        const ownerInfo = {
          firstname: this.$store.state.app.firstname,
          lastname: this.$store.state.app.lastname,
          birthday: this.$store.state.app.birthday,
          phonenumber: this.$store.state.app.phonenumber,
          email: this.$store.state.app.email,
        };

        for (const [key, value] of Object.entries(ownerInfo)) {
          this.$store.dispatch('basket/appendToCurrentUser', { key, value });
          this.$store.dispatch('app/addItem', { key: `${this.overlayPrefix}${key}`, value });
        }
      } else if (nowSelected === 'user') {
        console.log('handleUserData: remove owner info saved in user data');
        this.$store.dispatch('basket/removeFromCurrentUser', 'firstname');
        this.$store.dispatch('basket/removeFromCurrentUser', 'lastname');
        this.$store.dispatch('basket/removeFromCurrentUser', 'birthday');
        this.$store.dispatch('basket/removeFromCurrentUser', 'phonenumber');
        this.$store.dispatch('basket/removeFromCurrentUser', 'email');

        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}firstname`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}lastname`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}birthday`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}phonenumber`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}email`);
      }
    },
    async saveOverlayData() {
      await this.$store.dispatch('basket/appendToCurrentItem', { key: 'isCompleted', value: true });
      const currentItem = this.$store.state.basket?.currentItem;
      // console.log('saveOverlayData: Deleting current item...');
      this.$store.dispatch('basket/deleteItem', 'currentItem');

      // overwrite current basket item with currentItem
      if (this.getItems.length === 1) {
        this.$store.dispatch('basket/updateBasketItem', { id: currentItem.id, newItem: currentItem });
      }

      for (const [key, value] of Object.entries(this.$store.state.app)) {
        if (key.startsWith(this.overlayPrefix)) {
          console.log(`saveOverlayData: Deleting prefixed overlay data from app store - key: ${key} / value: ${value}`);
          this.$store.dispatch('app/deleteItem', key);
        }
      }
    },
    goBack(route) {
      if (route) {
        this.$router.push(route);
        return;
      }

      if (this.previousPage) {
        this.visited.pop();
        this.setPage(this.previousPage[0]);
      }
    },
    async setPage(page) {
      if (!page) return;
      // push pages to a list sorted by pageNumber so the back button always goes to a
      // page with a smaller pageNumber
      if (!this.visited.find((f) => f.fields.pageNumber.value === page.fields.pageNumber.value)) {
        this.visited.push(page);
      }

      this.visited.sort((a, b) => {
        return a.fields.pageNumber.value < b.fields.pageNumber.value;
      });

      this.currentPage = page;
      this.disabled = true;

      const pageRoute = page.url;
      const fetchOptions = {
        layoutServiceConfig: { host: config.sitecoreApiHost },
        querystringParams: { sc_lang: 'en', sc_apikey: config.sitecoreApiKey },
        fetcher: dataFetcher,
      };

      await dataApi.fetchRouteData(pageRoute, fetchOptions).then((routeData) => {
        this.route = routeData.sitecore.route;
      });

      // focus on first tabbable element in overlay
      this.$nextTick(() => focusOnFirstTabbableElement(this.$refs.content.$el));

      // Define and send gtm event
      let sub_type = undefined;
      if (this.currentItem?.user?.info === 'owner') sub_type = 'owner';
      else if (this.currentItem?.user?.birthday) {
        const age = calculateAge(this.currentItem.user.birthday);
        age < 18 ? (sub_type = 'child') : (sub_type = 'adult'); // TODO: define age threshold in config (and reuse in ControlServiceProduct)?
      }

      const sub_step = this.currentPage.fields.SubStep.value;
      gtmCheckoutSubscriberEvent({ sub_type, sub_step, gtmSharedParams: gtmSharedParams() });

      this.$store.dispatch('app/addItem', { key: 'useSecondaryRoute', value: false });
    },
    async userDone() {
      await this.$store.dispatch('basket/appendToCurrentItem', { key: 'isCompleted', value: true });
      this.saveOverlayData();

      const items = getGtmItems();
      const value = this.$store.getters['basket/getBasket']?.total;
      gtmEcommerceEvent({ event: 'add_shipping_info', items, value, gtmSharedParams: gtmSharedParams() });
    },
    onHandleExtraSim() {
      const isPickingUp = this.currentUserSimDeliveryType == '1' ? true : false;
      if (isPickingUp) this.$store.dispatch('basket/removeFromCurrentItem', 'extraSim');
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100%;

  &-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    ::v-deep .heading__title {
      @include desktop {
        font-size: 19px;
        line-height: 140%;
        max-width: $desktop-narrow-component !important;
        margin: 0 auto;
        text-align: left;
      }
    }

    ::v-deep.rich-text {
      text-align: left;
      max-width: $desktop-narrow-component !important;
    }

    @include desktop {
      align-items: center;
    }

    .button-container {
      flex: 1;
      align-items: flex-end;
      margin-top: 16px;
      @include screen-tablet-portrait-up {
        margin-top: 24px;
      }
    }
  }

  .step-indicator {
    margin-bottom: 8px;
    @include screen-tablet-portrait-up {
      margin-bottom: 16px;
    }

    &__container {
      width: 100%;
    }

    progress {
      background: #dcdcdc;
      height: 2px;
      width: 100%;
      border-radius: 50px;

      &::-webkit-progress-bar {
        background-color: $color-grey-tint-light;
        height: 2px;
        position: relative;
        border-radius: 50px;
      }
      &::-webkit-progress-value {
        transition: inline-size ease-in-out 0.5s;
        background-color: $color-green-dark;
        height: 6px;
        position: absolute;
        top: -2px;
        border-radius: 50px;
        block-size: 6px !important;
      }
    }
  }

  &__steps {
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    @include screen-tablet-portrait-up {
      font-size: 18px;
    }

    li {
      display: block;
      flex: 0 1 auto;
      list-style-type: none;
      text-align: center;
      font-size: 15px;
      color: #{$color-grey-darkest};
      line-height: normal;
      @include screen-tablet-portrait-up {
        font-size: 18px;
      }

      &.active {
        color: #{$color-black};
        font-family: $font-bold;

        @include screen-tablet-portrait-up {
          font-size: 19px;
        }
      }
    }
  }
}
</style>
