<template>
  <div v-if="visible" class="auth-info">
    <div class="full-width desktop-narrow">
      <ScText class="auth-info__title text-m m-t-s text-md-m full-width" tag="h1" :field="fields.AuthInfoTitle" />
      <Underline class="auth-info_underline" />
      <p class="auth-info__text">{{ text }}</p>
    </div>

    <div class="auth-info__text-container desktop-narrow">
      <div class="auth-info__text-line">
        <p><ScText tag="b" class="auth-info__text" :field="fields.NameText" /> {{ name }}</p>
      </div>
      <div class="auth-info__text-line">
        <p><ScText tag="b" class="auth-info__text" :field="fields.BirthdateText" /> {{ birthday }}</p>
      </div>
      <ScText
        tag="a"
        tabindex="0"
        class="link auth-info__link"
        @keydown.enter="openModal"
        @keydown.space="openModal"
        @click.prevent="openModal"
        :field="fields.LogoutText"
      />
      <ModalChoice :fields="fields" :hidden="isModalHidden" @closeModal="closeModal" @signOut="signOut" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import Underline from './util/Underline.vue';
import { ModalChoice } from '@/sharedComponents';
import { gtmModalViewEvent, gtmModalClickEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { gtmSharedParams, getGtmItems } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'AuthInfo',
  components: {
    ScText: Text,
    Underline,
    ModalChoice,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      isModalHidden: true,
    };
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),
    ...mapGetters({
      getBasket: 'basket/getBasket',
    }),
    name() {
      return `${this.$store.state.app.firstname} ${this.$store.state.app.lastname}`;
    },
    birthday() {
      return this.$store.state.app.birthday;
    },
    text() {
      if (this.fields.AuthInfoText?.value.includes('{selectedProvider}')) {
        return this.fields.AuthInfoText?.value.replace('{selectedProvider}', this.$store.state.app.selectedProvider);
      }
      return this.fields.AuthInfoTitle?.value;
    },
    visible() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }

      return true;
    },
    debugEnabled() {
      return process.env.NODE_ENV === 'development';
    },
  },
  mounted() {
    if (this.visible && this.getBasket.items) {
      const value = this.getBasket.total;
      const items = getGtmItems();
      gtmEcommerceEvent({
        event: 'add_payment_info',
        items,
        gtmSharedParams: gtmSharedParams(),
        value,
      });
    }
  },
  methods: {
    ...mapActions('oidcStore', [
      'removeOidcUser', // Signs out user in vuex and browser storage, but not in open id provider
    ]),
    signOut() {
      gtmModalClickEvent({
        modal_name: this.fields.ModalChoiceHeading.value,
        modal_selection: 'log out',
        gtmSharedParams: gtmSharedParams(),
      });
      this.removeOidcUser().then(() => {
        // first we want to clear oidc info from state
        this.$store.dispatch('app/deleteItem', 'firstname');
        console.log(`${this.$options.name}: Cleared value for key: 'firstname'`);

        this.$store.dispatch('app/deleteItem', 'lastname');
        console.log(`${this.$options.name}: Cleared value for key: 'lastname'`);

        this.$store.dispatch('app/deleteItem', 'birthday');
        console.log(`${this.$options.name}: Cleared value for key: 'birthday'`);

        this.$store.dispatch('app/deleteItem', 'email');
        console.log(`${this.$options.name}: Cleared value for key: 'email'`);

        this.$store.dispatch('app/deleteItem', 'phonenumber');
        console.log(`${this.$options.name}: Cleared value for key: 'phonenumber'`);

        this.$store.dispatch('app/deleteItem', 'oidcToken');
        console.log(`${this.$options.name}: Cleared value for key: 'oidcToken'`);

        this.$store.dispatch('app/deleteItem', 'selectedProvider');
        console.log(`${this.$options.name}: Cleared value for key: 'selectedProvider'`);

        this.$store.dispatch('app/deleteItem', 'postal');
        console.log(`${this.$options.name}: Cleared value for key: 'postal'`);

        this.$store.dispatch('app/deleteItem', 'street');
        console.log(`${this.$options.name}: Cleared value for key: 'street'`);

        if (!this.debugEnabled) this.$store.commit('basket/CLEAR_OWNER_DATA');

        // now we can redirect back to login step
        this.$router.push(this.fields.Route.url);
      });
    },
    openModal() {
      this.isModalHidden = false;
      gtmModalViewEvent({ modal_name: this.fields.ModalChoiceHeading.value, gtmSharedParams: gtmSharedParams() });
    },
    closeModal(e) {
      this.isModalHidden = true;
      if (e.detail.cancelled)
        gtmModalClickEvent({
          modal_name: this.fields.ModalChoiceHeading.value,
          modal_selection: 'cancel',
          gtmSharedParams: gtmSharedParams(),
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;

  &__text {
    margin-bottom: 16px;
    line-height: 145%;

    &-container {
      width: 100%;
    }
  }

  &__link {
    @include small-textlink;
  }
}
</style>
