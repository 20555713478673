import gql from 'graphql-tag';

export default gql`
  query Config($path: String!) {
    # path can be an item tree path or GUID-based id
    # {647EE526-B2D8-4300-9870-2C0C11318C63}
    # /sitecore/content/Talkmore/Home/Privat/abonnement/enkel/bestill/Config
    item(path: $path) {
      id
      name
      path
      fields(ownFields: true) {
        name
        ... on CheckboxField {
          value
        }
        ... on TextField {
          value
        }
        ... on ImageField {
          src
        }
        ... on ReferenceField {
          targetItem {
            url
          }
        }
        ... on MultilistField {
          targetItems {
            name
            fields(ownFields: true) {
              name
              ... on TextField {
                value
              }
              ... on MultilistField {
                targetItems {
                  name
                  fields(ownFields: true) {
                    name
                    value
                  }
                }
              }
            }
          }
        }
        ... on LookupField {
          targetItem {
            name
            displayName
            fields(ownFields: false, excludeStandardFields: true) {
              name
              value
              ... on MultilistField {
                targetItems {
                  name
                  fields(ownFields: true) {
                    name
                    value
                  }
                }
              }
            }
          }
        }
        ... on LinkField {
          url
          text
        }
      }
    }
  }
`;
