<template>
  <div
    :class="isDesktopView() ? 'desktop-view' : ''"
    class="bottom-sheet"
    :style="{ zIndex: zIndex }"
    ref="bottomSheet"
    :aria-hidden="!showSheet"
    role="dialog"
  >
    <transition name="fade">
      <div
        :style="{ backgroundColor: overlayColor }"
        @click="clickOnOverlayHandler"
        class="bottom-sheet__overlay"
        v-if="overlay && showSheet"
      />
    </transition>
    <div
      ref="bottomSheetContent"
      :style="{
        maxWidth: maxWidthString,
        maxHeight: maxHeightString,
        transform: translateValueString,
        height: sheetHeightString,
      }"
      :class="[sheetContentClasses]"
    >
      <header ref="bottomSheetHeader" class="bottom-sheet__header">
        <div class="bottom-sheet__draggable-area" ref="bottomSheetDraggableArea">
          <slot name="drag">
            <div class="bottom-sheet__draggable-thumb"></div>
          </slot>
        </div>
        <div :class="[titleText != '' ? 'split' : '']" class="bottom-sheet__header-content">
          <span v-if="titleText" class="title-xxs bold title-md-xxs">{{ titleText }}</span>
          <button
            class="transparent-btn text-s text-md-m button-small cursor-pointer text-normal text-medium"
            type="button"
            @click="close"
            v-html="closeButtonText"
            :aria-label="closeText == '' ? $t('close') : false"
          ></button>
        </div>
        <slot name="header" />
      </header>
      <main ref="bottomSheetMain" class="bottom-sheet__main" :style="{ overflowY: showSheet ? 'auto' : 'hidden' }">
        <slot />
      </main>
      <footer ref="bottomSheetFooter" class="bottom-sheet__footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import Hammer from '@egjs/hammerjs';
import { toggleBodyScrollLock, focusTrapInsideElement, tabbableElements } from '../../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'VueBottomSheetVue2',
  inject: {
    hasModalAncestor: { default: false },
  },
  props: {
    overlay: {
      type: Boolean,
      default: true,
    },
    overlayColor: {
      type: String,
      default: '#0000004D',
    },
    maxWidth: {
      type: Number,
      default: 640,
    },
    maxHeight: {
      type: Number,
      default: undefined,
    },
    overlayClickClose: {
      type: Boolean,
      default: true,
    },
    canSwipe: {
      type: Boolean,
      default: true,
    },
    closeHeightPercent: {
      type: Number,
      default: 100,
    },
    initSheetHeight: {
      type: Number,
      default: undefined,
    },
    zIndex: {
      type: Number,
      default: 99999,
    },
    customClass: {
      type: String,
      default: '',
    },
    closeText: {
      type: String,
      default: 'X',
    },
    titleText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSheet: false,
      translateValue: this.closeHeightPercent,
      isDragging: false,
      contentScroll: 0,
      sheetHeight: 0,
      desktopTransform: this.maxWidth + 24,
    };
  },
  computed: {
    sheetContentClasses() {
      return [
        'bottom-sheet__content',
        {
          'bottom-sheet__content--fullscreen': this.sheetHeight >= window.innerHeight,
          'bottom-sheet__content--dragging': this.isDragging,
        },
        this.customClass,
      ];
    },
    maxWidthString() {
      return `${this.maxWidth}px`;
    },
    maxHeightString() {
      const margin = 42; // 42px margin on top and bottom
      const availableHeight = window.innerHeight - 2 * margin;

      if (this.sheetHeight >= availableHeight) {
        return `${availableHeight}px`;
      }

      return this.maxHeight ? `${this.maxHeight}px` : 'inherit';
    },
    translateValueString() {
      if (this.showSheet === false) {
        return 'translate3d(0, 100%, 0)'; // hide the sheet
      }
      const margin = 42;
      const windowHeight = window.innerHeight;
      const bottomPosition = (windowHeight - this.sheetHeight) / 2;

      // "mobile" screen - narrower than the sheet
      if (window.innerWidth <= this.maxWidth + 24) {
        return `translate3d(0, 0, 0)`;
      }
      // "desktop" screen - wider than the sheet
      else if (windowHeight > this.sheetHeight) {
        return `translate3d(0, -${bottomPosition}px, 0)`;
      } else if (windowHeight < this.sheetHeight) {
        return `translate3d(0, ${bottomPosition}px, 0)`;
      } else {
        return `translate3d(0, -${margin}px, 0)`;
      }
    },
    sheetHeightString() {
      return this.sheetHeight && this.sheetHeight > 0 ? `${this.sheetHeight + 1}px` : 'auto';
    },
    closeButtonText() {
      if (this.closeText) return this.closeText;
      return '<i aria-hidden class="icon icon-cross"></i>';
    },
  },
  mounted() {
    this.makeContentUntabbable(); // initially make slot content untabbable and use focus trap on open
    setTimeout(() => {
      this.initHeight();

      // Create instances of Hammerjs
      const hammerAreaInstance = new Hammer(this.$refs.bottomSheetDraggableArea, {
        inputClass: Hammer.TouchMouseInput,
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
      });

      const hammerMainInstance = new Hammer(this.$refs.bottomSheetMain, {
        inputClass: Hammer.TouchMouseInput,
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
      });

      // Set events and handlers to hammerjs instances
      hammerAreaInstance.on('panstart panup pandown panend', (e) => {
        this.dragHandler(e, 'area');
      });

      hammerMainInstance.on('panstart panup pandown panend', (e) => {
        this.dragHandler(e, 'main');
      });
    }, 100);
  },
  methods: {
    isDesktopView() {
      return window.innerWidth > this.desktopTransform;
    },
    initHeight() {
      this.sheetHeight =
        this.initSheetHeight ??
        this.$refs.bottomSheetHeader.offsetHeight +
          this.$refs.bottomSheetMain.offsetHeight +
          this.$refs.bottomSheetFooter.offsetHeight;
    },
    clickOnOverlayHandler() {
      if (this.overlayClickClose) this.close();
    },
    dragHandler(event, type) {
      if (this.canSwipe) {
        this.isDragging = true;

        const preventDefault = (e) => {
          e.preventDefault();
        };

        if (type === 'main') {
          this.contentScroll = this.$refs.bottomSheetMain.scrollTop;
          document.documentElement.style.overflowY = 'hidden';
          document.documentElement.style.overscrollBehavior = 'none';
        }

        if (this.showSheet) {
          if (event.deltaY > 0) {
            if (type === 'main' && event.type === 'panup') {
              this.translateValue = this.pixelToVh(event.deltaY);
              if (event.cancelable) {
                this.$refs.bottomSheetMain.addEventListener('touchmove', preventDefault);
              }
            }

            if (type === 'main' && event.type === 'pandown' && this.contentScroll === 0) {
              this.translateValue = this.pixelToVh(event.deltaY);
            }

            if (type === 'area') {
              this.translateValue = this.pixelToVh(event.deltaY);
            }

            if (event.type === 'panup') {
              this.$emit('dragging-up');
            }
            if (event.type === 'pandown') {
              this.$emit('dragging-down');
            }
          }
        } else {
          if (type === 'main' && event.type === 'panup') {
            if (event.cancelable) {
              this.$refs.bottomSheetMain.addEventListener('touchmove', preventDefault);
            }
            let tslVal = this.closeHeightPercent + this.pixelToVh(event.deltaY);
            if (tslVal >= 0) {
              this.translateValue = tslVal;
            }
          }
          if (type === 'main' && event.type === 'pandown' && this.contentScroll === 0) {
            this.translateValue = this.closeHeightPercent + this.pixelToVh(event.deltaY);
          }

          if (type === 'area') {
            let tslVal = this.closeHeightPercent + this.pixelToVh(event.deltaY);
            if (tslVal >= 0) {
              this.translateValue = tslVal;
            }
          }

          if (event.type === 'panup') {
            this.$emit('dragging-up');
          }
          if (event.type === 'pandown') {
            this.$emit('dragging-down');
          }
        }

        if (event.isFinal) {
          this.$refs.bottomSheetMain.removeEventListener('touchmove', preventDefault);

          if (type === 'main') {
            this.contentScroll = this.$refs.bottomSheetMain.scrollTop;
          }
          this.isDragging = false;
          if (this.showSheet) {
            if (
              (this.pixelToVh(event.deltaY) >= 15 && this.contentScroll === 0) ||
              (this.pixelToVh(event.deltaY) >= 15 && type === 'area')
            ) {
              this.close();
            } else {
              this.open();
            }
          } else {
            if (this.pixelToVh(event.deltaY) <= -5) {
              this.open();
            } else {
              this.close();
            }
          }
        }
      }
    },
    pixelToVh(pixel) {
      const height = this.maxHeight && this.maxHeight <= this.sheetHeight ? this.maxHeight : this.sheetHeight;
      return (pixel / height) * 100;
    },
    close() {
      focusTrapInsideElement(this.$refs.bottomSheet, false);
      if (this.hasModalAncestor) this.$root.$emit('closeModal');
      this.showSheet = false;
      this.translateValue = this.closeHeightPercent;
      document.documentElement.style.overflowY = 'visible';
      document.documentElement.style.overscrollBehavior = 'scroll';
      setTimeout(() => {
        if (!this.hasModalAncestor) toggleBodyScrollLock(false); // only toggle body scroll lock if there is no modal ancestor
        this.$emit('closed');
      }, this.transitionDuration * 1000);
    },
    open() {
      this.showSheet = true;
      if (!this.hasModalAncestor) toggleBodyScrollLock(true);
      // only toggle body scroll lock if there is no modal ancestor
      else this.$root.$emit('openModal');
      focusTrapInsideElement(this.$refs.bottomSheet, true, this.close);
      this.$emit('opened');
    },
    makeContentUntabbable() {
      const contentTabbableElements = this.$refs.bottomSheet.querySelectorAll(tabbableElements);
      contentTabbableElements.forEach((el) => {
        el.setAttribute('tabindex', '-1');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: visibility 0.5s;

  * {
    box-sizing: border-box;
  }

  &[aria-hidden='false'] {
    visibility: visible;
  }

  &[aria-hidden='true'] {
    //visibility: hidden;
    pointer-events: none;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  &__header {
    &-content {
      display: flex;
      justify-content: flex-end;
      padding: 0 $spacing-m $spacing-s;
      @include desktop {
        padding-top: $spacing-s;
      }
      button {
        margin: 0;
        line-height: 0;
        min-width: 47px;
        min-height: 47px;
        text-align: right;
        padding: 12px 24px;
        margin: -12px -24px -12px 0;
      }

      &.split {
        justify-content: space-between;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 16px 16px 0 0;
    background: #ffffff;
    overflow-y: hidden;
    box-sizing: border-box;
    pointer-events: all;
    width: 100%;

    &--fullscreen {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(.bottom-sheet__content--dragging) {
      transition: 0.5s ease;
    }
  }

  &__draggable-area {
    width: 100%;
    margin: auto;
    padding: 16px;
    cursor: grab;
  }

  &__draggable-thumb {
    width: 40px;
    height: 4px;
    background: #c4c4c4;
    border-radius: 8px;
    margin: 0 auto;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    touch-action: auto !important;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-corner {
      display: none;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
    }

    hr {
      display: block;
      border: 1px solid $color-grey;
      width: 100%;
      margin-top: $spacing-m;
      margin-bottom: $spacing-s;
    }
  }

  &__footer:empty {
    display: none;
  }
}

.bottom-sheet.desktop-view {
  .bottom-sheet__content {
    border-radius: 30px;
  }
  .bottom-sheet__draggable {
    &-area {
      padding-bottom: 0;
    }
    &-thumb {
      display: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
