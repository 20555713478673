<template>
  <div class="choiceModal" :class="getAlignment">
    <div v-show="isEditorActive" class="experience-editor">
      <button class="button-secondary" @click="hidden = !hidden">
        {{ hidden ? 'Show modal for editing' : 'Hide modal again' }}
      </button>
      <p>This button is only visible while editing in the experience editor</p>
    </div>

    <ModalOverlay :class="{ hidden }" @click="toggle()">
      <div class="choiceModal__content">
        <!-- "tag" should always be a block element, otherwise SSR will fail when fields.Content contains block elements -->
        <ScText v-if="fields.Content" tag="div" class="choiceModal__content-content" :field="fields.Content" :encode="false" />

        <div class="choiceModal__content-buttons">
          <ScText
            tag="a"
            class="link"
            :field="fields.CloseButtonText"
            tabindex="0"
            @click="toggle()"
            @keydown.enter.prevent="toggle()"
            @keydown.space.prevent="toggle()"
          />
          <ScText
            tag="a"
            class="link link--warning"
            :field="fields.ActionButtonText"
            tabindex="0"
            @click="emitActionEvent()"
            @keydown.enter.prevent="toggle()"
            @keydown.space.prevent="toggle()"
          />
        </div>
      </div>
    </ModalOverlay>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ModalOverlay from './util/ModalOverlay';

export default {
  name: 'ChoiceModal',
  components: {
    ScText: Text,
    ModalOverlay,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    trigger: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hidden: true,
    };
  },
  computed: {
    getAlignment() {
      if (this.fields.TextAlignment) {
        return `text-align--${this.fields.TextAlignment.fields.Type.value}`;
      }

      return null;
    },
  },
  watch: {
    trigger() {
      this.toggle();
    },
  },
  methods: {
    toggle() {
      if (this.isEditorActive) return;

      this.hidden = !this.hidden;
    },
    emitActionEvent() {
      let actionName = this.fields.ActionEvent?.fields.Key.value;
      this.$root.$emit(actionName);
      this.toggle();
    },
  },
};
</script>

<style lang="scss">
.choiceModal {
  margin: 0 24px 24px 24px;

  &__content {
    max-width: 326px;
    max-height: 90vh;
    background: #{$color-white};
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 0;

    @include desktop {
      max-width: $desktop-wide-component;
    }

    &-image {
      margin: 0 24px;
    }

    &-content {
      margin: 0 24px;

      p {
        padding-bottom: 16px;
      }

      p:last-child {
        padding-bottom: 0;
      }

      a {
        @include textlink;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin: 0 24px;
    }
  }

  .hidden {
    display: none;
    opacity: 0;
  }
}
</style>
