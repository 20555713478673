<template>
  <div class="heading" :class="fields.HeadingClass.value">
    <ScText :tag="headingTag" class="heading__title text-m text-md-m" :field="fields.HeadingTitle" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Heading',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
    heading: {
      type: String,
      default: 'h5',
    },
  },
  computed: {
    headingTag() {
      return this.heading !== null ? this.heading : 'h5';
    },
  },
};
</script>

<style lang="scss">
.heading {
  text-align: left;
  margin-top: 32px; // this is default, but can be edited in sitecore item
  margin-bottom: 16px;
  width: 100%;
}
</style>
