<template>
  <div class="pill" :class="getColor()">
    <p class="pill__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'Pill',
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColor() {
      if (!this.color) return null;
      return `pill--${this.color}`;
    },
  },
};
</script>

<style lang="scss">
.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 50px;
  padding: 0px 8px;

  &__text {
    font-size: 14px;
    line-height: 0;
  }

  &--pink {
    background-color: #{$color-pink-tint} !important;
  }

  &--green {
    background-color: #{$color-green-tint-light} !important;
  }

  &--blue {
    background-color: #{$color-blue-tint} !important;
  }
}
</style>
