import Vue from 'vue';
import { fetchRateplanPrices, fetchProductOptions } from '@/api';
import { isDefaultPrice } from '../../../../Talkmore.Web.Vue.Shared/src/utils';

const state = {
  loading: true,
  reloadConfig: false,
};

const ratePlanMappings = {
  Normal: { ratePlans: 'ratePlansDefault', priceKey: 'Normal', coupon: undefined },
  U30: { ratePlans: 'ratePlansU30', priceKey: 'U30', coupon: 'u30' },
  Partner: { ratePlans: 'ratePlansPartner', priceKey: 'Partner' }, // partner coupons come from discountType object
  Partner10: { ratePlans: 'ratePlansPartner10', priceKey: 'Partner10' },
};

const mutations = {
  ADD_ITEM(state, { key, value }) {
    Vue.set(state, key, value);
  },
  DELETE_ITEM(state, key) {
    Vue.delete(state, key);
  },
  RELOAD_CONFIG(state, value) {
    if (value === state.reloadConfig) return;
    Vue.set(state, 'reloadConfig', value);
  },
  UPDATE_PRICE_KEY(state, priceKey) {
    state.priceKey = priceKey;
    state.useDefaultPrices = isDefaultPrice(priceKey, 'Normal'); // TODO: use variable for default price key
  },
  UPDATE_RATEPLAN_PRICES(state, data) {
    if (state.ratePlans) {
      state.ratePlans = data;
    }
  },
  SET_RATEPLAN_PRICES(state, { priceKey, data }) {
    const ratePlans = ratePlanMappings[priceKey]?.ratePlans;
    state[ratePlans] = data;
  },
  SET_FAMILY_CRM_ITEM_PRICE(state, data) {
    if (state.familyCrmItem) {
      state.familyCrmItem = data;
    }
  },
  SET_LOADING(state, value) {
    Vue.set(state, 'loading', value);
  },
  SET_DIGITAL_SECURITY_PRICE(state, price) {
    Vue.set(state.digitalSecurity.crmItem, 'price', price);
  },
};

const actions = {
  addItem({ commit }, { key, value }) {
    commit('ADD_ITEM', { key, value });
  },
  async addInitialConfig({ state, commit, dispatch, getters }, config) {
    commit('SET_LOADING', true);

    const digitalSecurity = {};
    const extraSim = {};
    const footer = {};

    config.value.fields.forEach((element) => {
      switch (element.name) {
        case 'SalesStore': {
          commit('ADD_ITEM', { key: 'salesStore', value: element.value });
          break;
        }
        case 'OrderFlow': {
          commit('ADD_ITEM', { key: 'orderFlow', value: element.value });
          break;
        }
        case 'OrderType': {
          commit('ADD_ITEM', { key: 'orderType', value: element.value });
          break;
        }
        case 'ItemListName': {
          commit('ADD_ITEM', { key: 'itemListName', value: element.value });
          break;
        }
        case 'Include VAT': {
          commit('ADD_ITEM', { key: 'useVat', value: element.value === '1' });
          break;
        }
        case 'PriceKey': {
          commit('ADD_ITEM', { key: 'priceKey', value: element.value });
          commit('ADD_ITEM', { key: 'priceKeyDefault', value: element.value });
          break;
        }
        case 'Rateplans': {
          const value = [];
          element.targetItems.forEach((item) => {
            const title = item.fields.find((x) => x.name == 'Title')?.value;
            const crmId = item.fields.find((x) => x.name == 'CRM Item')?.value;
            const price = 0; // TODO: get prices from graphQL instead of API
            const type = item.__typename.split('_')[0];
            value.push({ title, crmId, price, type });
          });
          commit('ADD_ITEM', { key: 'ratePlans', value });
          commit('ADD_ITEM', { key: 'ratePlansDefault', value });

          if (state.orderFlow !== 'share') commit('ADD_ITEM', { key: 'ratePlansU30', value }); // TODO: could also be a config value or filtered by discount type in partner flows
          if (state.priceKey === 'Partner') commit('ADD_ITEM', { key: 'ratePlansPartner', value });
          if (state.priceKey === 'Partner10') commit('ADD_ITEM', { key: 'ratePlansPartner10', value });
          break;
        }
        case 'Family CRM Item': {
          const value = {
            crmId: element.targetItem?.fields.find((x) => x.name == 'Key')?.value,
            name: element.targetItem?.fields.find((x) => x.name == 'Value')?.value, // TODO: change to "title"
            price: 0, // TODO: get prices from graphQL instead of API
          };
          commit('ADD_ITEM', { key: 'familyCrmItem', value });
          break;
        }
        case 'Pages': {
          const value = element.targetItems.filter((item) => item.name);
          commit('ADD_ITEM', { key: 'basketPages', value });
          break;
        }
        case 'Show Sticky Basket': {
          commit('ADD_ITEM', { key: 'showStickyBasket', value: element.value === '1' });
          break;
        }
        case 'Basket Item Title': {
          commit('ADD_ITEM', { key: 'basketItemTitle', value: element.value });
          break;
        }
        case 'BasketFinalText': {
          commit('ADD_ITEM', { key: 'basketFinalText', value: element.value });
          break;
        }
        case 'Basket Route': {
          commit('ADD_ITEM', { key: 'basketRoute', value: element.targetItem?.url });
          break;
        }
        case 'Basket CloseButton Text': {
          commit('ADD_ITEM', { key: 'basketCloseButtonText', value: element.value });
          break;
        }
        case 'Oidc Authority': {
          if (!element.targetItem) break;
          const authority = element.targetItem?.fields.find((x) => x.name == 'Authority')?.value;
          commit('ADD_ITEM', { key: 'oidcAuthority', value: authority });
          break;
        }
        case 'Partner Oidc Authority': {
          if (!element.targetItem) break;
          const authority = element.targetItem?.fields.find((x) => x.name == 'Authority')?.value;
          commit('ADD_ITEM', { key: 'partnerOidcAuthority', value: authority });
          break;
        }
        case 'StepsPages': {
          const value = element.targetItems.filter((item) => item.name);
          commit('ADD_ITEM', { key: 'stepsPages', value });
          break;
        }
        case 'DigitalSecurityTitle': {
          digitalSecurity.title = element;
          break;
        }
        case 'DigitalSecurityDescription': {
          digitalSecurity.description = element;
          break;
        }
        case 'DigitalSecurityText': {
          digitalSecurity.text = element;
          break;
        }
        case 'DigitalSecurityCrmItem': {
          const value = {
            crmId: element.targetItem?.fields.find((x) => x.name == 'Key')?.value,
            title: element.targetItem?.fields.find((x) => x.name == 'Value')?.value,
            price: 0,
          };
          digitalSecurity.crmItem = value;
          break;
        }
        case 'ExtraSimTitle': {
          extraSim.title = element.value;
          break;
        }
        case 'ExtraSimText': {
          extraSim.text = element;
          break;
        }
        case 'ExtraSimBottomSheetCloseText': {
          extraSim.bottomSheetCloseText = element.value;
          break;
        }
        case 'ExtraSimBottomSheetTitle': {
          extraSim.bottomSheetTitle = element.value;
          break;
        }
        case 'ExtraSimBottomSheetButtonText': {
          extraSim.bottomSheetButtonText = element.value;
          break;
        }
        case 'ExtraSimBottomSheetListAmount': {
          extraSim.bottomSheetListAmount = element.value;
          break;
        }
        case 'RecruitmentCampaign': {
          if (!element.targetItem) break;

          const value = {
            infoText: element.targetItem?.fields.find((x) => x.name == 'RecruitmentInfoText')?.value,
            spotText: element.targetItem?.fields.find((x) => x.name == 'RecruitmentSpotText')?.value,
            productTitle: element.targetItem?.fields.find((x) => x.name == 'RecruitmentProductTitle')?.value,
            productDescription: element.targetItem?.fields.find((x) => x.name == 'RecruitmentProductDescription')?.value,
            basketText: element.targetItem?.fields.find((x) => x.name == 'RecruitmentBasketText')?.value,
          };
          commit('ADD_ITEM', { key: 'recruitmentCampaign', value });
          break;
        }
        case 'DiscountType': {
          if (!element.targetItem) break;

          const value = {
            discountPercentage: element.targetItem?.fields.find((x) => x.name == 'DiscountPercentage')?.value,
            discountForOnlyOneProduct:
              element.targetItem?.fields.find((x) => x.name == 'DiscountForOnlyOneProduct')?.value == 1 ? true : false,
            discountWithoutPorting:
              element.targetItem?.fields.find((x) => x.name == 'DiscountWithoutPorting')?.value == 1 ? true : false,
            discountCoupon: element.targetItem?.fields.find((x) => x.name == 'DiscountCoupon')?.value,
          };
          commit('ADD_ITEM', { key: 'discountType', value });
          break;
        }
        case 'Discount': {
          if (!element.targetItem) break;

          const validations = element.targetItem?.fields.find((x) => x.name == 'DiscountValidations')?.targetItems;
          const discountValidations = validations.map((validation) => {
            return {
              ...validation,
              fields: validation.fields.reduce((acc, field) => {
                acc[field.name] = { value: field.value };
                return acc;
              }, {}),
            };
          });

          const value = {
            discountName: element.targetItem?.name,
            discountNameDisplay: element.targetItem?.displayName,
            discountSummaryText: element.targetItem?.fields.find((x) => x.name == 'DiscountSummaryText')?.value,
            discountFieldText: element.targetItem?.fields.find((x) => x.name == 'DiscountFieldText')?.value,
            discountFieldLength: element.targetItem?.fields.find((x) => x.name == 'DiscountFieldLength')?.value,
            discountFieldDescription: element.targetItem?.fields.find((x) => x.name == 'DiscountFieldDescription')?.value,
            discountSummaryDescription: element.targetItem?.fields.find((x) => x.name == 'DiscountSummaryDescription')?.value,
            discountSummaryDescriptionMulti: element.targetItem?.fields.find((x) => x.name == 'DiscountSummaryDescriptionMulti')
              ?.value,
            discountValidations,
          };
          commit('ADD_ITEM', { key: 'discount', value });
          break;
        }
        case 'FooterCopyrightText': {
          footer.copyrightText = element.value;
          break;
        }
        case 'FooterCookieButtonText': {
          footer.cookieButtonText = element.value;
          break;
        }
      }
    });

    commit('ADD_ITEM', { key: 'digitalSecurity', value: digitalSecurity });
    commit('ADD_ITEM', { key: 'extraSim', value: extraSim });
    commit('ADD_ITEM', { key: 'footer', value: footer });
    commit('ADD_ITEM', { key: 'useDefaultPrices', value: true });

    // Fetch prices after adding initial config
    await dispatch('setRatePlanPrices', 'Normal');
    if (state.ratePlansU30) await dispatch('setRatePlanPrices', 'U30');
    if (state.ratePlansPartner) await dispatch('setRatePlanPrices', 'Partner');
    if (state.ratePlansPartner10) await dispatch('setRatePlanPrices', 'Partner10');
    if (state.familyCrmItem) await dispatch('setFamilyCrmItemPrice');
    if (state.digitalSecurity?.crmItem) {
      // Get price for digital security
      const id = state.digitalSecurity.crmItem.crmId;
      const data = await dispatch('fetchProductOptions', id);
      if (data) {
        commit('SET_DIGITAL_SECURITY_PRICE', data[0].Cost);
      }
    }

    if (getters.isRecruitmentOrderType === false) await dispatch('app/clearRecruitmentValues', null, { root: true });

    commit('SET_LOADING', false);
  },
  updateRatePlanPrices({ commit, state }) {
    const ids = state.ratePlans.map((x) => x.crmId);
    if (!ids || ids.length === 0) return;

    const priceKey = state.priceKey ?? 'Normal'; // Default to Normal prices

    // Use the key from ratePlanMappings to access the correct rate plan array from the state
    const selectedRatePlans = state[ratePlanMappings[priceKey]?.ratePlans] ?? [];
    const ratePlansWithPrices = selectedRatePlans.map((x) => ({
      ...x,
      price: x.price,
    }));

    commit('UPDATE_RATEPLAN_PRICES', ratePlansWithPrices);
  },
  async setRatePlanPrices({ commit, state, dispatch }, priceKey) {
    const ids = state.ratePlansDefault.map((x) => x.crmId);
    if (!ids || ids.length <= 0) return;

    const priceKeyDefault = state.priceKeyDefault;

    const useVat = state.useVat ?? false;
    await fetchRateplanPrices({ ids, priceKey, useVat })
      .then((response) => {
        let ratePlansWithPrices = state.ratePlansDefault.map((x) => ({
          ...x,
          price: response.data.find((y) => y.id === x.crmId).Cost,
        }));
        commit('SET_RATEPLAN_PRICES', { priceKey, data: ratePlansWithPrices });
      })
      .catch((error) => console.error(error));
    if (priceKey === priceKeyDefault) dispatch('updateRatePlanPrices');
  },
  async setFamilyCrmItemPrice({ commit, state }) {
    const ids = state.familyCrmItem?.crmId;
    if (!ids) return;
    const useVat = state.useVat ?? false;
    const familyCrmItem = { ...state.familyCrmItem };
    await fetchRateplanPrices({ ids, useVat })
      .then((response) => {
        const price = response.data[0].Cost;
        familyCrmItem.price = price;
      })
      .catch((error) => console.error(error));
    if (state.priceKey !== 'Normal') {
      const priceKey = state.priceKey;
      await fetchRateplanPrices({ ids, priceKey, useVat })
        .then((response) => {
          const price = response.data[0].Cost;
          familyCrmItem.priceDiscount = price;
        })
        .catch((error) => console.error(error));
    }
    commit('SET_FAMILY_CRM_ITEM_PRICE', familyCrmItem);
  },
  async fetchProductOptions({ state }, ids) {
    const useVat = state.useVat ?? false;
    try {
      const response = await fetchProductOptions({ ids, useVat });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  updatePriceKey({ commit }, priceKey) {
    commit('UPDATE_PRICE_KEY', priceKey);
  },
  reloadConfig({ commit }, value) {
    commit('RELOAD_CONFIG', value);
  },
  clearState({ commit, state }) {
    // eslint-disable-next-line no-unused-vars
    Object.entries(state).forEach(([key, value]) => commit('DELETE_ITEM', key));

    console.log('Store: Cleared whole config state!');
  },
};

const getters = {
  getRatePlans(state) {
    return state.ratePlans ?? [];
  },
  getRatePlansByPriceKey: (state) => (priceKey) => {
    return state[ratePlanMappings[priceKey]?.ratePlans] ?? [];
  },
  getRatePlansDefault(state) {
    return state.ratePlansDefault ?? [];
  },
  getRatePlansU30(state) {
    return state.ratePlansU30 ?? [];
  },
  getPriceKey(state, getters) {
    return state.priceKey ?? getters.getPriceKeyNormal;
  },
  getPriceKeyDefault(state, getters) {
    return state.priceKeyDefault ?? getters.getPriceKeyNormal;
  },
  getPriceKeyNormal() {
    return ratePlanMappings.Normal.priceKey;
  },
  getPriceKeyU30() {
    return ratePlanMappings.U30.priceKey;
  },
  getPriceKeyPartner() {
    return ratePlanMappings.Partner.priceKey;
  },
  getPriceKeyPartner10() {
    return ratePlanMappings.Partner10.priceKey;
  },
  getCoupon: (state, getters) => (priceKey) => {
    if (
      (priceKey === getters.getPriceKeyPartner || priceKey === getters.getPriceKeyPartner10) &&
      state.discountType?.discountCoupon &&
      state.discountType?.discountCoupon != ''
    )
      return state.discountType.discountCoupon;
    return ratePlanMappings[priceKey]?.coupon ?? undefined;
  },
  isSingleFlow(state) {
    return state.orderFlow === 'single';
  },
  isRecruitmentOrderType(state) {
    return state.orderType === 'verving';
  },
  isPartnerOrderType(state) {
    return state.orderType === 'partner';
  },
  isDiscountForOnlyOneProduct(state) {
    return state.discountType?.discountForOnlyOneProduct ?? undefined;
  },
  isPercentageDiscount(state) {
    if (!state.discountType) return undefined;
    return state.discountType.discountPercentage !== null && state.discountType.discountPercentage !== '';
  },
  isConfigLoading(state) {
    return state.loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
