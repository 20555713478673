import createPersistedState from 'vuex-persistedstate';
import config from '../temp/config';

const prefix = config.jssAppName;
let persistedState;

if (process.browser) {
  persistedState = createPersistedState({
    // storage: window.sessionStorage,
    // we need to prefix our storage key in order to have unique store for each jss app
    storage: {
      getItem: (key) => sessionStorage.getItem(`${prefix}_${key}`),
      setItem: (key, value) => sessionStorage.setItem(`${prefix}_${key}`, value),
      removeItem: (key) => sessionStorage.removeItem(`${prefix}_${key}`),
    },
  });
}

export default persistedState;
