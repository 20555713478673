<template>
  <div v-show="visible" class="button-container" :class="getSpacing()">
    <div class="button-container__inner">
      <button class="button secondary-btn" @click="onRouteBack">
        <!-- <Spinner v-if="isLoading" class="button__spinner" /> -->

        <!-- <ScText v-if="isLoading" tag="span" class="button__text button__loading-text" :field="fields.LoadingText" /> -->
        <!-- v-if="!isLoading" -->
        <ScText tag="span" class="button__text" :field="fields.TextBack" />

        <!-- <Tooltip v-show="fields.TooltipText" :fields="fields" class="button__tooltip" :isOpen="showErrorTooltip" /> -->
      </button>
      <button
        class="button primary-btn"
        :class="[
          {
            'button-loading': isLoading,
            isLoading,
            'button-loading-notext': !fields.LoadingTextNext.value,
            disabled: isDisabled,
          },
        ]"
        @click="onRouteNext"
      >
        <Spinner v-if="isLoading" class="button-spinner" />

        <ScText v-if="isLoading" tag="span" class="button__text button__loading-text" :field="fields.LoadingTextNext" />
        <ScText v-if="!isLoading" tag="span" class="button__text" :field="fields.TextNext" />

        <Tooltip v-show="fields.TooltipText" :fields="fields" class="button-tooltip" :isOpen="showErrorTooltip" />
      </button>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Spinner, Tooltip } from '@/sharedComponents';

export default {
  name: 'ButtonWithBack',
  components: {
    ScText: Text,
    Spinner,
    Tooltip,
  },
  inject: {
    trackEvents: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabled: this.disabled,
      isLoading: this.loading,
      showErrorTooltip: 0,
    };
  },
  computed: {
    visible() {
      if (this.fields.HideWhenStoreValueDefined?.value) {
        return !Object.hasOwn(this.$store.state.app, this.fields.HideWhenStoreValueDefined.value);
      }
      return true;
    },
  },
  watch: {
    disabled() {
      this.isDisabled = this.disabled;
    },
    loading() {
      this.isLoading = this.loading;
    },
  },
  mounted() {
    this.eventSubscriptions();
  },
  methods: {
    onRouteBack() {
      // emit all events
      this.fields.EventsBack.forEach((ev) => {
        const eventName = ev.fields.Key.value;
        this.$root.$emit(eventName);
      });

      // if the button isn't used to route to previous page, just emit a click and be done with it
      if (!this.fields.RouteBack) {
        this.$emit('click');
        return;
      }

      this.$router.push(this.fields.RouteBack.url);
    },
    onRouteNext() {
      if (this.isLoading) return;

      if (this.isDisabled) {
        this.errorTooltip();
        return;
      }

      // set loading state if we are calling a service/waiting on a event as result of button press
      if (this.fields.RouteOnEvent) {
        this.isLoading = true;
      }

      // emit all events
      this.fields.Events.forEach((ev) => {
        const eventName = ev.fields.Key.value;
        this.$root.$emit(eventName);
      });

      // if the button isn't used to route to next page, just emit a click and be done with it
      if (!this.fields.RouteNext) {
        this.$emit('click');
        return;
      }

      // automatically advance if we are not waiting on an event to fire
      if (!this.fields.RouteOnEvent) {
        this.trackEvents(this);
        this.$router.push(this.fields.RouteNext.url);
      }
    },
    eventSubscriptions() {
      // if page-change is dependent on an event being fired, subscribe to the event here.
      if (this.fields.RouteOnEvent) {
        this.$root.$on(this.fields.RouteOnEvent.fields.Key.value, () => {
          this.trackEvents(this);
          this.$router.push(this.fields.RouteNext.url);
        });

        const errorHandler = () => {
          this.isLoading = false;
        };

        // disable loading on error state of event
        this.$root.$on('onError', errorHandler);
        this.$root.$on('onError422', errorHandler);
        this.$root.$on('onErrorUsersNotComplete', errorHandler);
      }

      if (this.fields.EnableOnEvent) {
        this.isDisabled = true;

        this.$root.$on(this.fields.EnableOnEvent.fields.Key.value, () => {
          this.isDisabled = false;
        });
      }

      if (this.fields.DisableOnEvent) {
        this.$root.$on(this.fields.DisableOnEvent.fields.Key.value, () => {
          this.isDisabled = true;
        });
      }
    },
    errorTooltip() {
      this.showErrorTooltip++;
      this.$root.$emit('onInvalidateAll');
    },
    getSpacing() {
      const spacingValues = this.fields?.Spacing;
      if (spacingValues) return spacingValues.map((item) => item.fields?.SpacingClass?.value).join(' ');
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  &-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;

    &__inner {
      display: flex;
      gap: 16px 24px;
      flex-wrap: wrap-reverse;
      align-content: flex-end;
      justify-content: center;
      @include desktop {
        gap: 32px;
      }
    }

    @include desktop {
      flex: auto;
    }

    .button {
      margin: 0;
      width: auto;
      text-align: center;
      min-width: 175px;
    }
  }
}
</style>
