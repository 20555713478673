<template>
  <button class="cta-button" @click="onClick">
    <ScText tag="span" class="cta-button__text" :field="text" />
  </button>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CtaButton',
  components: {
    ScText: Text,
  },
  props: {
    text: {
      type: Object, // Object instead of String because it's used as sitecore component
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {
      location.href = this.link;
    },
  },
};
</script>

<style lang="scss">
.cta-button {
  //   font-family: 'CircularMedium', Helvetica, Arial, sans-serif;
  //   @extend .transition-all;
  //   position: relative;
  //   margin: 16px 24px 8px 24px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #{$color-white};
  //   border: 2px solid #{$color-grey-darker};
  //   border-radius: 50px;
  //   color: #{$color-blackish};
  //   cursor: pointer;
  //   padding: 16px 24px;
  //   font-size: 17.5px;

  //   &:hover {
  //     background-color: #{$color-white};
  //     border-collapse: #{$color-grey-darker};
  //     color: #{$color-blackish};
  //     box-shadow: inset 0 0 0 1px #{$color-grey-darker};
  //   }

  //   &:focus-visible {
  //     border-color: transparent;
  //     box-shadow: inset 0 0 0 2px #{$color-grey-darker};
  //     outline: 3px solid #{$color-black};
  //     color: #{$color-black};
  //     background-color: #{$color-white};
  //   }
  //   &:active {
  //     background-color: #{$color-grey-darker};
  //     border-color: #{$color-grey-darker};
  //     color: #{$color-white};
  //   }
  @include secondary-btn();
  margin: 16px 24px 8px 24px;
  padding: 16px 24px;
}
</style>
