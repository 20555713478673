import { createRouter } from './router';
import store from './store';
import { getProductPriceFromRates, getProductIndex } from '../../Talkmore.Web.Vue.Shared/src/utils';
import { gtmEcommerceEvent } from '../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

const router = createRouter();

const config = store.state.config;
const getAllProducts = () => {
  return store.state.app?.allProducts;
};

export const gtmSettings = {
  // https://github.com/gtm-support/vue-gtm/tree/vue2-gtm#configuration
  id: 'GTM-5CR6P3',
  compatibility: false,
  debug: process.env.NODE_ENV === 'development',
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
};

export const gtmSharedParams = () => ({
  order_flow: config?.orderFlow,
  order_type: config?.orderType,
  partner: config?.discount?.discountNameDisplay?.toLowerCase() ?? undefined,
});

export function gtmSharedEcommerceParams() {
  const item_list_name =
    config.priceKey === store.getters['config/getPriceKeyU30'] && store.getters['config/isSingleFlow']
      ? store.getters['config/getCoupon'](config.priceKey)
      : config.itemListName; // if priceKey is U30, set item_list_name to U30 coupon, otherwise use config value
  return { item_list_name, item_list_id: 'abonnement' };
}

export const gtmSharedItemParams = () => ({
  item_brand: 'Talkmore',
  item_category: 'abonnement',
  item_category2: 'privat',
  item_category3: config.orderFlow === 'mbb' ? 'mbb' : config.orderType,
  quantity: 1,
  coupon: undefined,
});

export const gtmDigitalSecurityParams = () => ({
  item_id: config?.digitalSecurity?.crmItem?.crmId,
  item_name: config?.digitalSecurity?.crmItem?.title,
  price: config?.digitalSecurity?.crmItem?.price,
  ...gtmSharedItemParams(),
  item_category: 'addon',
});

export const gtmExtraSimParams = {
  item_id: 'ekstra-sim', // TODO: fix hardcoded value
  item_name: 'ekstra-sim', // TODO: fix hardcoded value
  price: 0,
  ...gtmSharedItemParams(),
  item_category: 'addon', // ekstra-sim is an addon despite price being 0
};

export const gtmChildControlParams = {
  item_id: '202010290645050062', // TODO: fix hardcoded value
  item_name: 'Barnemodus', // TODO: fix hardcoded item_name
  price: 0,
  ...gtmSharedItemParams(),
  item_category: 'tjeneste',
};

export const gtmFullControlParams = {
  item_id: '202211231421521349', // TODO: fix hardcoded value
  item_name: 'Full Kontroll Privat', // TODO: fix hardcoded item_name
  price: 0,
  ...gtmSharedItemParams(),
  item_category: 'tjeneste',
};

export const gtmFamilyCrmItem = () => ({
  item_id: config?.familyCrmItem?.crmId,
  item_name: config?.familyCrmItem?.name,
  price: config?.familyCrmItem?.priceDiscount ?? config?.familyCrmItem?.price,
  ...gtmSharedItemParams(),
});

export function getGtmItems() {
  const gtmItems = [];
  const basketItems = store.getters['basket/getBasket'].items;
  if (basketItems?.length > 0) {
    const familyCrmItem = config.familyCrmItem;
    if (familyCrmItem) {
      // family flow
      const familySubscription = basketItems[0]; // all basket items in family flow are the same subscription
      const coupon = familySubscription.coupon;

      const quantity = basketItems.length - 1; // adjust quantity to match with amount of users
      gtmItems.push({
        ...gtmFamilyCrmItem(),
        quantity,
        ...(coupon && { coupon }),
      });

      gtmItems.push(
        getGtmItem({ product: familySubscription.product, price: familySubscription.price, coupon, isAdding: false }),
      );
    } else {
      const gtmBasketItems = basketItems.reduce((acc, item) => {
        const existingItem = acc.find((i) => {
          return i.item_id === item.product.fields['CRM Item']?.value && i.coupon === item.coupon;
        });
        if (existingItem) {
          existingItem.quantity += 1; // increase quantity of existing item
        } else {
          acc.push(getGtmItem({ product: item.product, price: item.price, coupon: item.coupon, isAdding: false }));
        }
        return acc;
      }, []);
      gtmItems.push(...gtmBasketItems);
    }
    basketItems.forEach((item) => {
      if (item.user?.fullControl) {
        addGtmItemOrIncreaseQuantity(gtmItems, gtmFullControlParams);
      }
      if (item.user?.childSecurity) {
        addGtmItemOrIncreaseQuantity(gtmItems, gtmChildControlParams);
      }
    });
  }
  // create new array with only items that have a extraSim value
  const extraSimValues = basketItems.map((item) => item.extraSim).filter((x) => x !== undefined && x > 0);
  if (extraSimValues.length) {
    const extraSimItem = {
      ...gtmExtraSimParams,
      // calculate total quantity of extraSim values
      quantity: extraSimValues.reduce((acc, val) => acc + val, 0),
    };
    gtmItems.push(extraSimItem);
  }
  if (store.state.app?.digitalSecurity) gtmItems.push(gtmDigitalSecurityParams());
  return gtmItems;
}

function addGtmItemOrIncreaseQuantity(gtmItems, itemParams) {
  const existingItem = gtmItems.find((i) => i.item_id === itemParams.item_id);
  if (existingItem) {
    existingItem.quantity += 1;
  } else {
    gtmItems.push({ ...itemParams, quantity: 1 });
  }
}

export function trackItemList(el, productList = false) {
  if (!el) {
    console.error('No element provided');
    return;
  }
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        gtmViewItemListEvent(productList);
      }
    });
  });
  observer.observe(el);
}

export function gtmViewItemListEvent(productList = false) {
  const currentItem = store.state.basket.currentItem;
  const ratePlans =
    productList && currentItem?.priceKey
      ? store.getters['config/getRatePlansByPriceKey'](currentItem?.priceKey)
      : store.getters['config/getRatePlans'];
  const coupon = productList && currentItem?.coupon ? currentItem?.coupon : store.getters['config/getCoupon'](config.priceKey);

  const items = getAllProducts().map((product) => {
    const price = getProductPriceFromRates({ product, ratePlans });
    return getGtmItem({ product, price, coupon, isAdding: true });
  });

  gtmEcommerceEvent({
    event: 'view_item_list',
    items,
    gtmSharedParams: gtmSharedParams(),
    gtmSharedEcommerceParams: gtmSharedEcommerceParams(),
  });
}

export function getGtmItem({ product, price, quantity = null, coupon = null, isAdding = true }) {
  return {
    ...(isAdding && { index: getProductIndex(getAllProducts(), product) }), // TODO: rename var to addIndex or similar
    item_id: product.fields['CRM Item']?.value,
    item_name: product.fields.Title?.value,
    price,
    ...gtmSharedItemParams(),
    ...(quantity && { quantity }),
    ...(coupon && { coupon }),
  };
}

export function gtmCartActionEvent({
  isAdding = true,
  product = null,
  price,
  quantity = null,
  coupon = null,
  updateItem = false,
}) {
  const items = [];
  const usersCount = store.state.basket.usersCount;

  if (product) {
    const item = {
      ...getGtmItem({ product, price, quantity, coupon, isAdding }),
      quantity: usersCount ? 1 : quantity || 1, // Set quantity to 1 if usersCount is set, otherwise use quantity or default to 1
    };
    items.push(item);
  }

  if (usersCount && !updateItem) {
    const familyItem = {
      ...(isAdding && { index: undefined }), // Include index as undefined only when adding
      ...gtmFamilyCrmItem(),
      ...(quantity && { quantity }),
      ...(coupon && { coupon }),
    };
    items.push(familyItem);
  }

  if (isAdding) gtmAddToCartEvent(items);
  else gtmRemoveFromCartEvent(items);
}

export function gtmAddToCartEvent(items) {
  gtmEcommerceEvent({
    event: 'add_to_cart',
    items,
    gtmSharedParams: gtmSharedParams(),
    gtmSharedEcommerceParams: gtmSharedEcommerceParams(),
  });
}

export function gtmRemoveFromCartEvent(items) {
  gtmEcommerceEvent({
    event: 'remove_from_cart',
    items,
    gtmSharedParams: gtmSharedParams(),
  });
}
