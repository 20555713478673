<template>
  <div v-if="visible" class="recruitment-spot desktop-wide">
    <ScImage :media="fields.RecruitmentSpotIcon" class="recruitment-spot__icon" aria-hidden="true" />
    <div>
      <ScText tag="h2" :field="titleWithRecruiterName" class="text-s text-md-s" />
      <p class="text-s text-md-s">{{ recruitmentSpotText }}</p>
    </div>
  </div>
</template>

<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'RecruitmentSpot',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    recruitmentSpotText() {
      return this.$store.state.config?.recruitmentCampaign?.spotText;
    },
    recruitmentCode() {
      return this.$store.state.app?.recruitmentCode;
    },
    recruiterName() {
      return this.$store.state.app?.recruiterName;
    },
    titleWithRecruiterName() {
      if (!this.recruiterName) return this.fields.RecruitmentSpotTitle; // TODO: if the title includes {recruiterName} remove that part
      const modifiedTitle = { ...this.fields.RecruitmentSpotTitle };
      modifiedTitle.value = modifiedTitle.value.replace('{recruiterName}', this.recruiterName);
      return modifiedTitle;
    },
    visible() {
      return !!this.recruiterName && !!this.recruitmentCode && this.$store.state.app[this.fields.ToggleByStoreValue.value];
    },
  },
};
</script>

<style lang="scss" scoped>
.recruitment-spot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-xs;
  margin-bottom: 20px; // FIXME: use gap property in container to define equal spacing everywhere

  @include desktop {
    margin-top: $spacing-m;
    margin-bottom: 0;
  }

  &__icon {
    width: 32px;
    height: 32px;
  }
}
</style>
