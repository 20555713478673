<template>
  <FlowSelector v-if="visible" :fields="fields" :bgColor="backgroundColor">
    <div v-show="isEditorActive" class="experience-editor">
      <button class="secondary-btn" @click="hidden = !hidden">
        {{ hidden ? 'Show modal for editing' : 'Hide modal again' }}
      </button>
      <p>This button is only visible while editing in the experience editor</p>
    </div>

    <ModalOverlay :class="{ hidden }" @click="closeModal">
      <div class="flow-selector-login__modal" ref="modal">
        <ScText class="flow-selector-login__modal-title text-xs-l title-md-xs" tag="h4" :field="fields.ModalTitle" />
        <ScText class="flow-selector-login__modal-text text-xs-m text-md-l" tag="p" :field="fields.ModalText" />

        <div class="flow-selector-login__modal-button-container">
          <ScText
            tag="button"
            class="button button-auto-width primary-xs-small primary-md-small text-md-m"
            :field="fields.ModalButton"
            tabindex="0"
            @click="closeModal"
            @keydown.enter.prevent="closeModal"
            @keydown.space.prevent="closeModal"
          />
        </div>
      </div>
    </ModalOverlay>

    <ScText
      tag="button"
      :field="fields.LoginText"
      class="button center button-auto-width primary-xs-small primary-md-small text-md-m m-md-t-m m-t-m"
      tabindex="0"
      @click="login"
      @keydown.enter.prevent="login"
      @keydown.space.prevent="login"
    />
  </FlowSelector>
</template>

<script>
import { mapActions } from 'vuex';
import FlowSelector from './util/FlowSelector.vue';
import { ModalOverlay } from '@/sharedComponents';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defaultPartnerOidcSettings } from '@/oidc';
import { getPartnerOidcStoreModule } from '@/store/modules/partnerOidc';
import { toggleBodyScrollLock, focusTrapInsideElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';
const packageConfig = require('@/../package.json');

export default {
  name: 'FlowSelectorLogin',
  components: {
    FlowSelector,
    ModalOverlay,
    ScText: Text,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      backgroundColor: this.fields?.BackgroundColor?.value,
      hidden: true,
    };
  },
  computed: {
    visible() {
      if (this.fields.HideWhenStoreValueDefined?.value) {
        return !Object.hasOwn(this.$store.state.app, this.fields.HideWhenStoreValueDefined.value);
      }
      return true;
    },
  },
  watch: {
    hidden() {
      focusTrapInsideElement(this.$refs.modal, !this.hidden, this.closeModal);
      toggleBodyScrollLock(!this.hidden);
    },
  },
  mounted() {
    // show the error modal if we had any issues logging in
    const error = localStorage.getItem('ShowErrorPage');
    this.hidden = !error ?? true;

    if (error) {
      localStorage.removeItem('ShowErrorPage');
      console.log(`${this.$options.name}: Removed item 'ShowErrorPage' from localStorage`);
    }
  },
  methods: {
    ...mapActions('partnerOidcStore', ['authenticateOidc']),
    closeModal() {
      this.hidden = true;
    },
    login() {
      // TODO: Should we also add a gtm event for trumf login?
      // gtmEproviderClickedEvent({ providers: 'trumf', gtmSharedParams: gtmSharedParams() });

      // check if oidc store module is already registered and if so,
      // unload it so we can update our oidc settings
      if (this.$store.hasModule('partnerOidcStore')) {
        this.$store.unregisterModule('partnerOidcStore');
      }

      // load settings from sitecore, then overwrite to default oidc settings
      const currentUrl = window.location.origin;
      const redirectUri = `${currentUrl}${packageConfig.config.sitecoreRedirectPath}`;

      defaultPartnerOidcSettings.authority = this.$store.state.config.partnerOidcAuthority;
      defaultPartnerOidcSettings.redirect_uri = redirectUri;
      defaultPartnerOidcSettings.popup_redirect_uri = redirectUri;
      defaultPartnerOidcSettings.post_logout_redirect_uri = redirectUri;

      // reload partnerOidcStore with new and updated settings
      this.$store.registerModule('partnerOidcStore', getPartnerOidcStoreModule(defaultPartnerOidcSettings));

      // save orderflow config id so we can load it once we got redirected back
      // to the redirect page containing the OidcRedirect.vue component.
      // the redirect page is not tied to a specific orderflow but is a general
      // page related to the private orderflow so it does not have its own config
      localStorage.setItem('ConfigId', this.$store.state.app.configId);
      console.log(`${this.$options.name}: Saved value: '${this.$store.state.app.configId}' for key 'ConfigId' in localStorage`);

      // save current page so we can redirect back to it in case anything fails during login
      localStorage.setItem('ErrorPage', window.location.pathname);
      console.log(`${this.$options.name}: Saved value: '${window.location.pathname}' for key 'ErrorPage' in localStorage`);

      // save final landing page for after redirect
      localStorage.setItem('RedirectPage', window.location.pathname);
      console.log(`${this.$options.name}: Saved value: '${window.location.pathname}' for key 'RedirectPage' in localStorage`);

      // save info about trumf login so we can handle it in OidcRedirect.vue
      localStorage.setItem('TrumfLogin', true);
      console.log(`${this.$options.name}: Saved value: 'true' for key 'TrumfLogin' in localStorage`);

      // start the criipto login process and redirect to either bankid or vipps login
      this.authenticateOidc();
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-selector-login {
  &__modal {
    max-width: 576px;
    width: Calc(100% - 24px);
    margin: 0 12px;
    max-height: 90vh;
    background: #{$color-white};
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop {
      max-width: $desktop-wide-component;
      padding: 32px 32px;
    }

    &-title {
      margin-bottom: 4px;
    }

    &-button {
      margin: 0;
      max-width: 295px;
    }

    &-button-container {
      margin: 24px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
