<template>
  <div class="overlay-multi desktop-wide m-b-3xl m-t-m m-md-t--clear">
    <ScText tag="h3" class="text-m text-md-m m-b-m" :field="fields.Title" />
    <div class="overlay-multi__accordion-list m-b-l">
      <div
        v-for="(item, i) in getItems"
        :key="item.id"
        class="overlay-multi__accordion"
        :class="isOverlayOpen ? (item === currentItem ? 'overlay-active' : 'inactive') : ''"
      >
        <div
          class="overlay-multi__accordion-head"
          @click="toggleAccordion(item, $event)"
          :tabindex="getTabIndex(item)"
          :ref="item.id"
          @keydown.enter.space.prevent="toggleAccordion(item, $event)"
        >
          <div class="icon-valid__container" :class="{ completed: item.isCompleted }">
            <i class="icon icon-check icon-symbol--left"></i>
          </div>

          <Pill color="green" :text="`Bruker ${i + 1} av ${numberOfProducts}`"></Pill>
          <div class="overlay-multi__accordion-head--text">
            <h4 class="text-l text-md-l m-t--clear m-b--clear">
              <SummaryProductTitle :productItem="item" />
            </h4>
            <span>{{ productName(item) }}</span>
          </div>
          <i class="icon icon-down icon-symbol--right" :class="{ 'icon-up': item === currentItem }"></i>
        </div>

        <div class="overlay-multi__accordion-content">
          <div v-if="item === currentItem" class="overlay-multi__accordion-content--inner__container">
            <div class="overlay-multi__accordion-content--inner" v-if="route">
              <template v-if="showStepIndicator">
                <div class="step-indicator__container">
                  <div class="step-indicator">
                    <progress
                      class="step-indicator__progress"
                      id="progress-bar"
                      :value="route.fields.ProgressBarPercentage.value"
                      max="100"
                    />
                  </div>
                </div>

                <ul class="overlay-multi__steps">
                  <li
                    v-for="page in fields.Pages"
                    :key="page.id"
                    :class="{ active: page.fields.pageTitle.value === route.fields.pageTitle.value }"
                  >
                    {{ page.fields.pageTitle.value }}
                  </li>
                </ul>
              </template>

              <Placeholder class="overlay-multi__main" name="Main" :rendering="route" :ref="`content-${item.id}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <ScText
        v-if="nextPossibleUserId === undefined"
        tag="span"
        class="text-m text-md-m m-t-large"
        :field="fields.ValidationSuccessMessage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Pill } from '@/sharedComponents';
import config from './../temp/config';
import { dataFetcher } from './../dataFetcher';
import { dataApi, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams, getGtmItems } from '@/gtmTracking';
import { gtmCheckoutSubscriberEvent, gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import SummaryProductTitle from './util/SummaryProductTitle.vue';
import { calculateAge, focusOnFirstTabbableElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'OverlayMulti',
  components: {
    ScText: Text,
    Pill,
    Placeholder,
    SummaryProductTitle,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      currentItem: null,
      numberOfProducts: 0,
      route: null,
      currentPage: null,
      disabled: true,
      visited: [],
      eventListeners: [
        { event: 'overlay-route', handler: this.setPage },
        { event: 'overlay-route-back', handler: this.goBack },
        { event: 'onHandleUserData', handler: this.handleUserData },
        { event: 'onHandleSimData', handler: this.handleSimData },
        { event: 'onSaveCurrentUser', handler: this.saveCurrentUser },
        { event: 'onSaveOverlayData', handler: this.saveOverlayData },
        { event: 'onUserDone', handler: this.multiDone },
        { event: 'onHandleExtraSim', handler: this.onHandleExtraSim },
      ],
      overlayPrefix: 'overlay-',
      isMobile: window.innerWidth <= 800,
    };
  },
  computed: {
    ...mapGetters({
      getItems: 'basket/getItems',
      currentUserSimDeliveryType: 'basket/getCurrentUserSimDeliveryType',
    }),
    previousPage() {
      const p = this.visited.filter((f) => f.fields.pageNumber.value < this.currentPage.fields.pageNumber.value)?.slice(-1);
      return p.length ? p : null;
    },
    showStepIndicator() {
      return this.currentPage?.fields?.pageTitle?.value;
    },
    isOverlayOpen() {
      if (this.$store.state.app?.overlayIsOpen) return true;
      return false;
    },
    productName() {
      return function (item) {
        const firstname = item?.user?.firstname;
        const lastname = item?.user?.lastname;
        const isOwner = item?.user?.info == 'owner';

        if (firstname && lastname) {
          return `${firstname} ${lastname} ${isOwner ? ` - ${this.$t('owner')}` : ''}`;
        }

        return this.$t('register-user');
      };
    },
    nextPossibleUserId() {
      const items = this.$store.state.basket?.items;
      return items.find((item) => !item.isCompleted)?.id;
    },
  },
  watch: {
    nextPossibleUserId: {
      immediate: true,
      handler(userId) {
        this.$nextTick(() => {
          if (userId) this.$root.$emit('onOverlayInvalidated', null);
          else this.$root.$emit('onOverlayValidated', null);
        });
      },
    },
  },
  beforeDestroy() {
    this.eventListeners.forEach(({ event }) => this.$root.$off(event));
  },
  mounted() {
    this.eventListeners.forEach(({ event, handler }) => this.$root.$on(event, (...args) => handler(...args)));
    this.numberOfProducts = this.getItems?.length;
    this.clearCurrentItem();
  },
  methods: {
    clearCurrentItem() {
      // console.log('Clearing currentItem...');
      if (this.currentItem?.id) this.handleToggleAnimationClose(this.$refs[this.currentItem.id][0]);
      this.currentItem = null;
      this.$store.dispatch('app/deleteItem', 'overlayIsOpen');
      this.$store.dispatch('basket/deleteItem', 'currentItem');
    },
    async toggleAccordion(item, event) {
      // Closing the accordion
      if (this.currentItem === item) {
        await this.handleToggleAnimationClose(event.target);
        // Close the item if it's already open
        setTimeout(() => {
          this.currentPage = null;
          this.visited = [];
          this.clearCurrentItem();
        }, 400);
      } else if (!this.isOverlayOpen) {
        // Open the accordion
        this.$store.dispatch('app/addItem', { key: 'overlayIsOpen', value: true });
        this.currentItem = item;
        this.currentPage = this.fields.Pages[0];
        this.setPage(this.fields.Pages[0]);
        const currentItem = this.$store.getters['basket/getItems'].find((item) => item.id === this.currentItem.id);

        if (!currentItem.user) {
          currentItem.user = {};
        }
        this.deleteAllUserdata();
        this.loadCurrentItem(currentItem);

        this.$store.dispatch('basket/addCurrentItem', currentItem);
        setTimeout(() => {
          this.handleToggleAnimationOpen(event.target);
        }, 100);
      }
    },
    async handleToggleAnimationOpen(element) {
      // Scroll to the element smoothly
      this.scrollToSmoothly(element);
      const content = await this.waitForElement(element.parentNode, '.overlay-multi__accordion-content');
      const innerContent = content.querySelector('.overlay-multi__accordion-content--inner__container');
      const innercontentHeight = innerContent.offsetHeight + 24; // it doesn't get the padding for the div for some reason
      content.style.maxHeight = `${innercontentHeight}px`;
      content.style.opacity = 1;

      setTimeout(() => {
        content.style.maxHeight = 'none';
        content.style.minHeight = `${innercontentHeight - 24}px`;
      }, 500);
    },
    async handleToggleAnimationClose(element) {
      const content = await this.waitForElement(element.parentNode, '.overlay-multi__accordion-content');
      content.style.maxHeight = 0;
      content.style.minHeight = 0;
      content.style.opacity = 0;
    },
    scrollToSmoothly(element) {
      // why not scrollIntoView()? Because it doesn't do the ease scroll at all with smooth behavior.
      const elementRect = element.getBoundingClientRect();
      const startTime = performance.now();
      const duration = 500;
      const startingY = window.pageYOffset;

      const calculateTargetY = () => {
        if (this.isMobile) {
          // If on mobile, animate scrolling to the element with an offset of 24 pixels
          return elementRect.top + window.pageYOffset - 24;
        } else {
          // If on desktop, calculate the target Y position above the center of the viewport
          return elementRect.top + window.pageYOffset - window.innerHeight / 4; // Focus above center
        }
      };

      const targetY = calculateTargetY();
      const diffY = targetY - startingY;

      const scrollAnimation = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const ease = (progress) => (progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress);
        const newScrollY = startingY + diffY * ease(progress);

        window.scrollTo(0, newScrollY);

        // progress < 1 ensures that the animation continues until progress reaches 1, indicating that the animation has completed.
        if (progress < 1) {
          requestAnimationFrame(scrollAnimation);
        }
      };
      requestAnimationFrame(scrollAnimation);
    },
    async waitForElement(element, child) {
      while (!element.querySelector(child)) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
      }
      return element.querySelector(child);
    },
    getTabIndex(productItem) {
      if (this.isOverlayOpen && productItem !== this.currentItem) return '-1';
      return '0';
    },
    loadCurrentItem(item) {
      if (!item.user) return;

      // take all properties from currentItem and load them into app store
      for (const [oldKey, value] of Object.entries(item.user)) {
        const key = `${this.overlayPrefix}${oldKey}`;
        // console.log(`loadCurrentItem: Write current user data to app store - key: ${key} / value: ${value}`);
        this.$store.dispatch('app/addItem', { key, value });
      }
    },
    deleteAllUserdata() {
      // loop through appstore find all properties that match and delete
      for (const [key] of Object.entries(this.$store.state.app)) {
        if (key.startsWith(this.overlayPrefix)) {
          // console.log(`deleteAllUserdata: Deleting prefixed overlay data from app store - key: ${key}`);
          this.$store.dispatch('app/deleteItem', key);
        }
      }
    },
    async saveCurrentUser(finalStep = false) {
      // take all prefixed properties from app store and append them to current user
      for (const [oldKey, value] of Object.entries(this.$store.state.app)) {
        if (oldKey.startsWith(this.overlayPrefix)) {
          const key = oldKey.replace(this.overlayPrefix, '');
          // console.log(`saveCurrentUser: append new data to current user - key: ${key} / value: ${value}`);
          // continue to the next key if current user already has the the same key-value
          if (this.currentItem?.user[key] === value) continue;
          if (finalStep === false && this.currentItem?.isCompleted)
            await this.$store.dispatch('basket/removeFromCurrentItem', 'isCompleted');
          this.$store.dispatch('basket/appendToCurrentUser', { key, value });
        }
      }
    },
    async multiDone() {
      await this.saveCurrentUser(true);
      await this.$store.dispatch('basket/appendToCurrentItem', { key: 'isCompleted', value: true });
      this.saveOverlayData();

      if (this.$store.getters['basket/allUsersCompleted'] === false) return;
      const items = getGtmItems();
      const value = this.$store.getters['basket/getBasket']?.total;
      gtmEcommerceEvent({ event: 'add_shipping_info', items, value, gtmSharedParams: gtmSharedParams() });
    },
    saveOverlayData() {
      const currentItem = this.$store.state.basket?.currentItem;
      this.clearCurrentItem();

      this.$store.dispatch('basket/updateBasketItem', { id: currentItem.id, newItem: currentItem });

      for (const [key, value] of Object.entries(this.$store.state.app)) {
        if (key.startsWith(this.overlayPrefix)) {
          console.log(`saveOverlayData: Deleting prefixed overlay data from app store - key: ${key} / value: ${value}`);
          this.$store.dispatch('app/deleteItem', key);
        }
      }

      // clean up
      this.currentPage = null;
      this.visited = [];

      // lastly, if we have a next possible user id, we can toggle its accordion
      if (this.nextPossibleUserId) {
        setTimeout(() => {
          this.$refs[this.nextPossibleUserId][0].click();
        }, 400);
      }
    },
    handleSimData() {
      const previousSimType = this.$store.state.basket?.currentItem?.user?.simType;
      const currentSimType = this.$store.state.app?.['overlay-simType'];

      if (currentSimType === previousSimType) return;

      if (currentSimType === 'esim') {
        console.log('handleSimData: remove extra sim and simCardDeliveryType info from store');
        this.$store.dispatch('basket/removeFromCurrentItem', 'extraSim');
        this.$store.dispatch('basket/removeFromCurrentUser', 'simCardDeliveryType');
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}simCardDeliveryType`);
      } else if (currentSimType === 'sim') {
        const isNewNumber = this.$store.state.app?.['overlay-porting'] === 'new-number';

        if (isNewNumber) {
          console.log('handleSimData: pre-set simCardDeliveryType to 0 since we selected new number');
          const value = '0';
          // we can only choose to 'pick up' sim in store, so we can preselect simCardDeliveryType to be 'delivery'
          this.$store.dispatch('basket/appendToCurrentUser', { key: 'simCardDeliveryType', value });
          this.$store.dispatch('app/addItem', { key: `${this.overlayPrefix}simCardDeliveryType`, value });
        }
      }
    },
    handleUserData() {
      const previousSelected = this.$store.state.basket?.currentItem?.user?.info;
      const nowSelected = this.$store.state.app?.['overlay-info'];

      if (nowSelected === previousSelected) return;

      if (nowSelected === 'owner') {
        console.log('handleUserData: save owner info to store');
        const ownerInfo = {
          firstname: this.$store.state.app.firstname,
          lastname: this.$store.state.app.lastname,
          birthday: this.$store.state.app.birthday,
          phonenumber: this.$store.state.app.phonenumber,
          email: this.$store.state.app.email,
        };

        for (const [key, value] of Object.entries(ownerInfo)) {
          this.$store.dispatch('basket/appendToCurrentUser', { key, value });
          this.$store.dispatch('app/addItem', { key: `${this.overlayPrefix}${key}`, value });
        }
      } else if (nowSelected === 'user') {
        console.log('handleUserData: remove owner info saved in user data');
        this.$store.dispatch('basket/removeFromCurrentUser', 'firstname');
        this.$store.dispatch('basket/removeFromCurrentUser', 'lastname');
        this.$store.dispatch('basket/removeFromCurrentUser', 'birthday');
        this.$store.dispatch('basket/removeFromCurrentUser', 'phonenumber');
        this.$store.dispatch('basket/removeFromCurrentUser', 'email');

        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}firstname`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}lastname`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}birthday`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}phonenumber`);
        this.$store.dispatch('app/deleteItem', `${this.overlayPrefix}email`);
      }
    },
    goBack(route) {
      if (route) {
        this.$router.push(route);
        return;
      }

      if (this.previousPage) {
        this.visited.pop();
        this.setPage(this.previousPage[0]);
      }
    },
    async setPage(page) {
      if (!page) return;
      // push pages to a list sorted by pageNumber so the back button always goes to a
      // page with a smaller pageNumber
      if (!this.visited.find((f) => f.fields.pageNumber.value === page.fields.pageNumber.value)) {
        this.visited.push(page);
      }

      this.visited.sort((a, b) => {
        return a.fields.pageNumber.value < b.fields.pageNumber.value;
      });

      this.currentPage = page;
      this.disabled = true;

      const pageRoute = page.url;
      const fetchOptions = {
        layoutServiceConfig: { host: config.sitecoreApiHost },
        querystringParams: { sc_lang: 'en', sc_apikey: config.sitecoreApiKey },
        fetcher: dataFetcher,
      };

      await dataApi.fetchRouteData(pageRoute, fetchOptions).then((routeData) => {
        this.route = routeData.sitecore.route;
      });

      // focus on the first tabbable element in the overlay
      this.$nextTick(() => {
        const overlayContent = this.$refs[`content-${this.currentItem.id}`]?.[0]?.$el;
        focusOnFirstTabbableElement(overlayContent);
      });

      let sub_type = undefined;
      const currentUser = this.$store.state.basket?.currentItem?.user;
      if (currentUser?.info === 'owner') sub_type = 'owner';
      else if (currentUser?.birthday) {
        const age = calculateAge(currentUser.birthday);
        age < 18 ? (sub_type = 'child') : (sub_type = 'adult'); // TODO: define age threshold in config (and reuse in ControlServiceProduct)?
      }

      const sub_step = this.currentPage.fields.SubStep.value;
      gtmCheckoutSubscriberEvent({ sub_type, sub_step, gtmSharedParams: gtmSharedParams() });

      this.scrollToTopInOverlay();

      this.$store.dispatch('app/addItem', { key: 'useSecondaryRoute', value: false });
    },
    scrollToTopInOverlay() {
      // since there should always be only one overlay open/active, we can easily get the container
      const overlay = document.querySelector('.overlay-active');
      if (overlay) this.scrollToSmoothly(overlay);
    },
    onHandleExtraSim() {
      const isPickingUp = this.currentUserSimDeliveryType == '1' ? true : false;
      if (isPickingUp) this.$store.dispatch('basket/removeFromCurrentItem', 'extraSim');
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-multi__accordion-content {
  overflow: hidden;
  opacity: 1; /* Initially set to fully visible */
  transition: opacity 0.5s ease; /* Transition for opacity property */
}
.overlay-multi {
  &__main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;

    ::v-deep .desktop-narrow {
      width: 100% !important;
    }
  }
  &__accordion-list {
    display: flex;
    flex-direction: column;
    gap: $spacing-l 0;
  }
  &__accordion {
    border-radius: $spacing-s;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid $color-grey;
    @include transition(0.5s);
    &.inactive {
      background-color: $color-grey-lighter;
      box-shadow: none;
      color: $color-grey-darker;
      @include transition(0.5s);
      .overlay-multi__accordion-head {
        cursor: default;
      }
      .pill {
        background-color: $color-grey !important;
        @include transition(0.5s);
      }
    }
    .pill {
      @include transition(0.5s);
      pointer-events: none;
    }
    &-head {
      padding: $spacing-s;
      position: relative;
      display: flex;
      padding-right: 60px;
      cursor: pointer;
      &--text {
        pointer-events: none;
      }
      .pill {
        position: absolute;
        right: 16px;
        top: -15px;
      }
      .icon {
        pointer-events: none;
        &-symbol--right {
          position: absolute;
          right: 16px;
          top: 0;
          bottom: 0;
          width: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &-symbol--left {
          display: flex;
          background-color: $color-green-darker;
          color: $color-white;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        &-valid__container {
          display: flex;
          align-items: center;
          visibility: hidden;
          opacity: 0;
          transition: all ease-in-out 0.25s;
          max-width: 0;
          overflow: hidden;
          &.completed {
            visibility: visible;
            opacity: 1;
            max-width: 36px;
            transition: all ease-in-out 0.25s;
            padding-right: 12px;
          }
        }
      }
    }
    &-content {
      max-height: 0;
      overflow: hidden;
      @include transition(0.55s);
      opacity: 0;
      display: flex;
      flex-direction: column;
      &--inner {
        display: flex;
        flex-direction: column;
        flex: 1;
        &__container {
          display: flex;
          padding: $spacing-xs $spacing-s $spacing-s;
          flex-direction: column;
          flex: 1;
        }
      }
    }
  }

  .step-indicator {
    margin-bottom: 8px;
    @include screen-tablet-portrait-up {
      margin-bottom: 16px;
    }

    &__container {
      width: 100%;
    }

    progress {
      background: #dcdcdc;
      height: 2px;
      width: 100%;
      border-radius: 50px;

      &::-webkit-progress-bar {
        background-color: $color-grey-tint-light;
        height: 2px;
        position: relative;
        border-radius: 50px;
      }
      &::-webkit-progress-value {
        transition: inline-size ease-in-out 0.5s;
        background-color: $color-green-dark;
        height: 6px;
        position: absolute;
        top: -2px;
        border-radius: 50px;
        block-size: 6px !important;
      }
    }
  }

  &__steps {
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    @include screen-tablet-portrait-up {
      font-size: 18px;
    }

    li {
      display: block;
      flex: 0 1 auto;
      list-style-type: none;
      text-align: center;
      font-size: 15px;
      color: #{$color-grey-darkest};
      line-height: normal;
      @include screen-tablet-portrait-up {
        font-size: 18px;
      }

      &.active {
        color: #{$color-black};
        font-family: $font-bold;

        @include screen-tablet-portrait-up {
          font-size: 19px;
        }
      }
    }
  }
}
</style>
