<template>
  <div class="terms-modal-link desktop-wide">
    <ScText tag="h5" class="terms-modal-link__title" :field="fields.Headline" />

    <div class="terms-modal-link__content">
      <CheckBox class="terms-modal-link__content-checkbox" :fields="fields" :embedded="true" @change="changed">
        <Modal class="terms-modal-link__content-modal" :style="isEditorActive ? 'max-height: 200px' : ''" :fields="fields">
          <ScLink v-if="hasLinkUrl || isEditorActive" class="terms-modal-link__content-link link" :field="fields.Link" />
        </Modal>
      </CheckBox>
    </div>
  </div>
</template>

<script>
import { Text, Link } from '@sitecore-jss/sitecore-jss-vue';
import Modal from './Modal';
import CheckBox from './CheckBox';

export default {
  name: 'TermsModalLink',
  components: {
    ScText: Text,
    ScLink: Link,
    Modal,
    CheckBox,
  },
  inject: {
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.$store.state.app[this.fields.Key.value],
    };
  },
  computed: {
    hasLinkUrl() {
      return this.fields.Link?.value?.href !== null;
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
  },
  mounted() {
    this.addToValidation(this);
  },
  methods: {
    changed(e) {
      this.value = e.target.checked;

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss">
.terms-modal-link {
  margin-bottom: 24px;

  @include desktop {
    padding: 0 24px;
  }

  &__title {
    padding-bottom: 16px;
  }

  &__content {
    display: flex;
    flex-direction: row;

    &-modal {
      margin-top: 8px;
      margin-left: 0;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .overlay {
        align-items: flex-start;
      }

      .modal__content {
        max-height: unset;

        @include desktop {
          max-width: 800px;
        }
      }

      .modal__content-content {
        h3 {
          margin-bottom: 24px;
        }
        h4 {
          margin-bottom: 12px;
        }
        text-align: left;
      }
    }

    &-checkbox {
      align-items: start;
      margin: 0;

      input {
        margin-top: 5px;
      }
    }

    &-link {
      color: #{$color-black};
      width: fit-content;
      margin-top: 8px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
