<template>
  <div v-if="show" class="control-service__container">
    <Pill color="blue" :text="fields.ControlServiceProductPillText.value"></Pill>
    <CheckBox class="control-service__checkbox" :fields="fields" :embedded="true" @change="changed" />
    <ScRichText tag="div" class="text-s text-md-s m-b-s" :field="fields.ControlServiceProductControlText" />

    <ScText
      tag="a"
      tabindex="0"
      class="link control-service__link text-s text-md-s"
      @click="openOverlay"
      @keydown.enter.space.prevent="openOverlay"
      :field="fields.ControlServiceProductLinkText"
    />

    <BottomSheet ref="controlServiceBottomSheet" :closeText="fields.BottomSheetCloseText.value">
      <ScText tag="h4" class="title-xxs title-md-xs" :field="fields.BottomSheetTitle" />
      <hr />

      <ScRichText tag="div" class="text-m" :field="fields.BottomSheetText" />
      <div class="m-t-s flex justify-content--center">
        <button class="button secondary-btn text-m" @click="closeOverlay" style="width: auto">
          <span class="main">
            <span class="text">{{ fields.BottomSheetCloseText.value }}</span>
          </span>
        </button>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { Pill, CheckBox } from '@/sharedComponents';
import BottomSheet from './util/BottomSheet.vue';
import { gtmSharedParams, gtmFullControlParams, gtmChildControlParams } from '@/gtmTracking';
import { gtmModalViewEvent, gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { calculateAge } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'ControlServiceProduct',
  components: {
    ScText: Text,
    ScRichText: RichText,
    Pill,
    CheckBox,
    BottomSheet,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.fields.Key.value,
      checked: this.$store.state.app[this.fields.Key.value],
      ageThreshold: this.fields.ControlServiceProductAgeThreshold?.value ?? 18,
      fullControlValue: 'overlay-fullControl',
      childSecurityValue: 'overlay-childSecurity',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.basket.currentItem?.user;
    },
    birthday() {
      return this.currentUser?.birthday;
    },
    isUnderaged() {
      const age = calculateAge(this.birthday);
      if (this.birthday && Number.isInteger(age)) {
        if (age < this.ageThreshold) {
          return true;
        }
        return false;
      }
      return null;
    },
    show() {
      // toggle control service based on key value and age
      if (this.value === this.childSecurityValue && this.isUnderaged === true) {
        return true;
      } else if (this.value === this.fullControlValue && this.isUnderaged === false) {
        return true;
      }
      this.onSaveEvent(this.value, null, this.$options.name);
      return false;
    },
  },
  beforeDestroy() {
    this.$root.$off('onSave');
  },
  mounted() {
    // remove the unintended product from user on save event
    this.$root.$on('onSave', () => {
      if (!this.show) return;
      let items = [];
      if (this.isUnderaged && this.currentUser?.fullControl) {
        this.$store.dispatch('basket/removeFromCurrentUser', 'fullControl');
        items.push(gtmFullControlParams);
      } else if (!this.isUnderaged && this.currentUser?.childSecurity) {
        this.$store.dispatch('basket/removeFromCurrentUser', 'childSecurity');
        items.push(gtmChildControlParams);
      }
      if (items.length === 0) return;
      gtmEcommerceEvent({
        event: 'remove_from_cart',
        items,
        gtmSharedParams: gtmSharedParams(),
      });
    });
  },
  methods: {
    changed(e) {
      this.checked = e.target.checked;
      const event = this.checked ? 'add_to_cart' : 'remove_from_cart';
      const items = this.value === this.childSecurityValue ? [gtmChildControlParams] : [gtmFullControlParams];
      gtmEcommerceEvent({
        event,
        items,
        gtmSharedParams: gtmSharedParams(),
      });
    },
    openOverlay() {
      this.$refs.controlServiceBottomSheet.open();

      const modal_name = 'ControlServiceProduct';
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeOverlay() {
      this.$refs.controlServiceBottomSheet.close();
    },
  },
};
</script>

<style lang="scss">
.control-service {
  &__container {
    width: 100%;
    background: #{$color-blue-lighter};
    padding: $spacing-m $spacing-s;
    border-radius: $spacing-s;
    position: relative;
    .pill {
      position: absolute;
      top: -15px;
      right: 16px;
    }
  }

  &__checkbox {
    margin-top: 0px;
  }
}
</style>
