<template>
  <div class="tooltip">
    <a
      class="tooltip__questionmark"
      :class="{ 'tooltip__questionmark--open': open }"
      @mouseover="toggle(true)"
      @mouseleave="toggle(false)"
      @click="persistToggle(persist)"
    >
      <i class="icon-questionmark-circle--filled"></i>
    </a>
    <div class="tooltip__bubble" v-if="open">
      <div class="tooltip__bubble__inner">
        <ScText tag="h3" :field="fields.TooltipTitle" />
        <ScText tag="p" :field="fields.TooltipText" />
      </div>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Tooltip',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
    isOpen: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      open: false,
      persist: false,
      openedByParent: false,
    };
  },
  watch: {
    isOpen() {
      this.open = true;
      this.openedByParent = true;
    },
  },
  mounted() {
    // avoiding window variable for SSR
    this.$el.ownerDocument.addEventListener('click', this.onWindowClick);
  },
  beforeDestroy() {
    this.$el.ownerDocument.removeEventListener('click', this.onWindowClick);
  },
  methods: {
    toggle(state) {
      if (this.persist || this.isOpen) return;
      this.open = state;
    },
    persistToggle(state) {
      this.persist = !state;
      this.open = this.persist;
    },
    onWindowClick($e) {
      const path = $e.path || $e.composedPath();

      if (path.includes(this.$el) || this.openedByParent) {
        $e.stopPropagation();
        this.openedByParent = false;
        return;
      }

      this.persistToggle(true);
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  align-self: baseline;

  &__questionmark {
    i {
      position: absolute;
      font-size: 34px; // 34px in font-size = 24px width & 24px height
      margin-top: -4px;
      cursor: help;
    }

    &--open {
      &::before {
        @extend .fade-in--fast;
        content: '';
        background: #{$color-green-light};
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 28px;
      }
    }
  }

  &__bubble {
    @extend .fade-in--fast;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    z-index: 9999;
    text-align: left;
    display: flex;
    justify-content: center;

    &__inner {
      display: inline-block;
      background: #{$color-green-light};
      padding: 16px;
      border-radius: 8px;
      cursor: help;

      h3 {
        font-size: 18px;
        line-height: 1.3;
      }

      p {
        font-weight: normal;
        padding: 16px 0 8px;
        line-height: inherit;
        font-size: 18px;
      }
    }
  }
}
</style>
