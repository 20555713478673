<template>
  <div class="loading-spinner">
    <Spinner :big="true" />
    <h2 class="loading-spinner__title">Straks klar!</h2>
  </div>
</template>

<script>
import { Spinner } from '@/sharedComponents';

export default {
  name: 'LoadingSpinner',
  components: {
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #{$color-white}; // Should be same as background in AppRoot.vue
  padding-top: 140px;
  padding-bottom: 140px;

  &__title {
    margin-top: 54px;
  }
}
</style>
