<template>
  <div class="heading-currentuser desktop-narrow" :class="fields.HeadingClass.value">
    <div>
      <b>{{ fullName }}</b>
      <ScText v-if="isOwner" tag="span" :field="fields.HeadingOwnerText" />
      <ScText v-else tag="span" :field="fields.HeadingUserText" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'HeadingCurrentUser',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      currentItem: 'basket/getCurrentItem',
    }),
    isOwner() {
      return this.currentItem?.user?.info === 'owner';
    },
    fullName() {
      return `${this.currentItem?.user?.firstname} ${this.currentItem?.user?.lastname} - `;
    },
  },
};
</script>
