<template>
  <div class="modal" :class="getAlignment()">
    <ScText
      v-show="fields.OpenLink.value"
      tag="a"
      tabindex="0"
      @click="toggle"
      @keydown.enter.prevent="toggle"
      @keydown.space.prevent="toggle"
      class="link modal__text"
      :field="fields.OpenLink"
    />

    <div v-show="isEditorActive" class="experience-editor">
      <button class="button-secondary" @click="hidden = !hidden">
        {{ hidden ? 'Show modal for editing' : 'Hide modal again' }}
      </button>
      <p>This button is only visible while editing in the experience editor</p>
    </div>

    <!-- This slot can be used to place further components under the modal text -->
    <slot></slot>

    <ModalOverlay :class="{ hidden }" @click="toggle" :isModalLarge="isModalLarge">
      <div class="modal__content" ref="modalContent">
        <a
          class="modal__content-close"
          tabindex="0"
          @click="toggle"
          @keydown.enter.prevent="toggle"
          @keydown.space.prevent="toggle"
        >
          <i class="icon-cross"></i>
        </a>

        <div class="modal__content-image" v-if="fields.Image.value.src">
          <ScImage :media="fields.Image" />
        </div>

        <!-- "tag" should always be a block element, otherwise SSR will fail when fields.Content contains block elements -->
        <ScText v-if="fields.Content" tag="div" class="modal__content-content" :field="fields.Content" :encode="false" />

        <div class="modal__content-button">
          <ScText
            tag="button"
            class="button-secondary"
            :field="fields.CloseButtonText"
            tabindex="0"
            @click="toggle"
            @keydown.enter.prevent="toggle"
            @keydown.space.prevent="toggle"
          />
        </div>
      </div>
    </ModalOverlay>
  </div>
</template>

<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import ModalOverlay from './util/ModalOverlay';
import { toggleBodyScrollLock } from '../utils';

export default {
  name: 'Modal',
  components: {
    ScText: Text,
    ScImage: Image,
    ModalOverlay,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      hidden: true,
      isModalLarge: false,
    };
  },
  watch: {
    hidden() {
      toggleBodyScrollLock(!this.hidden);
    },
  },
  mounted() {
    this.$root.$on(this.fields.ShowOnEvent?.fields.Key.value, () => {
      this.hidden = false;
    });

    // "window" won't fail SSR here since the "mounted" event wont be fired on the server
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    getAlignment() {
      if (this.fields.TextAlignment) {
        return `text-align--${this.fields.TextAlignment.fields.Type.value}`;
      }
      return null;
    },
    toggle() {
      if (this.isEditorActive) return;
      this.hidden = !this.hidden;

      if (!this.hidden) {
        this.isModalLarge = this.$refs.modalContent.clientHeight > window.innerHeight;
      }
    },
    resizeHandler() {
      this.isModalLarge = this.$refs.modalContent.clientHeight > window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
.modal {
  margin-bottom: 24px;

  &-overlay {
    transition: all 0.25s;
  }

  &__content {
    text-align: center;
    max-width: 375px;
    width: 100%;
    background: #{$color-white};
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0;

    @include desktop {
      max-width: $desktop-wide-component;
    }

    &-image {
      margin: 0 24px;
    }

    &-content {
      margin: 24px 24px;
      @include phone-xs {
        margin: 24px 12px;
      }

      p {
        padding-bottom: 16px;

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        @include textlink;
      }
    }

    &-button {
      margin: 0 24px 40px 24px;
      @include desktop {
        margin: 0 24px 40px 24px;
      }

      button {
        @extend .transition-all;
        font-family: 'CircularMedium', Helvetica, Arial, sans-serif;
        background: none;
        border: none;
        color: #{$color-black};
        font-size: 17.5px;
        cursor: pointer;
        padding: 12px 16px;
        box-shadow: inset 0 0 0 3px #{$color-black};
        border-radius: 16px;

        @include desktop {
          font-size: 17px;
        }

        &:focus {
          box-shadow: inset 0 0 0 4px #{$color-green-darker};
        }

        &:hover {
          box-shadow: inset 0 0 0 4px #{$color-black};
        }
      }
    }

    &-close {
      font-size: 24px;
      background: none;
      border: none;
      cursor: pointer;
      align-self: flex-end;
      margin: 24px 24px 0;
    }
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
  }
}
</style>
