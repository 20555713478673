<template>
  <div class="header desktop-wide">
    <Animation
      v-if="fields.Animation"
      class="header__animation"
      :class="[{ 'header__animation--large': fields.AnimationSize.fields.Key.value === 'large' }]"
      :animation="fields.Animation"
    />
    <ScText tag="h1" class="header__title" :field="fields.Headline" />
    <ScText tag="p" class="header__text" :field="fields.HeaderText" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import Animation from './util/Animation';

export default {
  name: 'Header',
  components: {
    ScText: Text,
    Animation,
  },
  props: {
    fields: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.header {
  margin-bottom: 32px;

  &__animation {
    height: 80px !important; /* we need to overwrite vue-lottie's props */
    width: 80px !important; /* we need to overwrite vue-lottie's props */
    margin-top: 6px !important; /* we need to overwrite vue-lottie's props */
    margin-bottom: 16px !important; /* we need to overwrite vue-lottie's props */

    @include desktop {
      margin-top: 28px !important; /* we need to overwrite vue-lottie's props */
      margin-bottom: 24px !important; /* we need to overwrite vue-lottie's props */
    }

    &--large {
      height: 104px !important; /* we need to overwrite vue-lottie's props */
      width: 104px !important; /* we need to overwrite vue-lottie's props */
    }
  }

  &__title {
    margin: 0 0 8px 0;
    text-align: center;
    white-space: pre-line;

    @include desktop {
      max-width: $desktop-narrow-component;
      margin: 0 auto 8px;
    }
  }

  &__text {
    @extend .lead;
    text-align: center;
    white-space: pre-line;
  }
}
</style>
