<template>
  <!-- we use v-show instead of v-if to prevent ssr hydration error -->
  <button
    v-show="visible"
    class="button desktop-narrow primary-btn full-width"
    :class="[{ 'button--loading': isLoading, disabled: isDisabled }, getSpacing()]"
    @click="onRoute"
  >
    <Spinner v-if="isLoading" class="button-spinner" />

    <ScText v-if="isLoading" tag="span" class="button__text button__loading-text" :field="fields.LoadingText" />
    <ScText v-if="!isLoading" tag="span" class="button__text" :field="fields.Text" />

    <Tooltip v-show="fields.TooltipText" :fields="fields" class="button__tooltip" :isOpen="showErrorTooltip" />
  </button>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import Spinner from './util/Spinner';
import Tooltip from './util/Tooltip';

export default {
  name: 'Button',
  components: {
    ScText: Text,
    Spinner,
    Tooltip,
  },
  inject: {
    trackEvents: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabled: this.disabled,
      isLoading: this.loading,
      showErrorTooltip: 0,
    };
  },
  computed: {
    visible() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }

      return true;
    },
  },
  watch: {
    disabled() {
      this.isDisabled = this.disabled;
    },
    loading() {
      this.isLoading = this.loading;
    },
  },
  mounted() {
    this.eventSubscriptions();
  },
  methods: {
    onRoute() {
      if (this.isLoading) return;

      if (this.isDisabled) {
        this.errorTooltip();
        return;
      }

      // set loading state if we are calling a service/waiting on a event as result of button press
      if (this.fields.RouteOnEvent) {
        this.isLoading = true;
      }

      // if the button isn't used to route to next page, just emit a click and be done with it
      if (!this.fields.Route) {
        this.$emit('click');
        return;
      }

      // emit all events
      this.fields.Events.forEach((ev) => {
        const eventName = ev.fields.Key.value;
        this.$root.$emit(eventName);
      });

      // automatically advance if we are not waiting on an event to fire
      if (!this.fields.RouteOnEvent) {
        this.trackEvents(this);
        this.$router.push(this.fields.Route.url);
      }
    },
    eventSubscriptions() {
      // if page-change is dependent on an event being fired, subscribe to the event here.
      if (this.fields.RouteOnEvent) {
        this.$root.$on(this.fields.RouteOnEvent.fields.Key.value, () => {
          this.trackEvents(this);
          this.$router.push(this.fields.Route.url);
        });

        // disable loading on error state of event
        this.$root.$on('onError', () => {
          this.isLoading = false;
        });
      }

      if (this.fields.EnableOnEvent) {
        this.isDisabled = true;

        this.$root.$on(this.fields.EnableOnEvent.fields.Key.value, () => {
          this.isDisabled = false;
        });
      }

      if (this.fields.DisableOnEvent) {
        this.$root.$on(this.fields.DisableOnEvent.fields.Key.value, () => {
          this.isDisabled = true;
        });
      }
    },
    errorTooltip() {
      this.showErrorTooltip++;
      this.$root.$emit('onInvalidateAll');
    },
    getSpacing() {
      if (this.fields.Spacing) return 'spacing-' + this.fields.Spacing.fields.Type.value;
      else return null;
    },
  },
};
</script>

<style lang="scss">
.button {
  &.spacing-normal {
    margin-top: 32px;
  }

  &.spacing-small {
    margin-top: 16px;
  }

  &.spacing-medium {
    margin-top: 48px;
  }

  &.spacing-large {
    margin-top: 64px;
  }
}
</style>
