<template>
  <vue-lottie :options="defaultOptions" v-on:animCreated="handleAnimation" />
</template>

<script>
import vueLottie from 'vue-lottie';

export default {
  name: 'Animation',
  components: {
    vueLottie,
  },
  props: {
    animation: {
      type: Object,
    },
  },
  data() {
    return {
      defaultOptions: {
        animationData: JSON.parse(this.animation.fields['Lottie Animation Data'].value),
        loop: false,
      },
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};
</script>
