<template>
  <div v-if="visible">
    <LoadingSpinner />
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'RouteLoading',
  components: {
    LoadingSpinner,
  },
  props: {
    delay: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>
