<template>
  <div class="message-box text-m text-md-m" :class="getLevel" v-show="!hidden || isEditorActive">
    <!-- "tag" should always be a block element, otherwise SSR will fail when fields.Text contains block elements -->
    <ScText tag="div" :field="fields.Text" :encode="false" class="text-s text-md-s" />
    <CtaButton
      v-if="fields.ButtonText.value"
      class="message-box__button secondary-btn secondary-xs-small secondary-md-small m-t-s m-b--clear"
      :text="fields.ButtonText"
      :link="fields.ButtonLink.value.href"
    />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import CtaButton from './util/CtaButton';

export default {
  name: 'MessageBox',
  components: {
    ScText: Text,
    CtaButton,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      hidden: true,
    };
  },
  computed: {
    getLevel() {
      if (this.fields?.Level) {
        return `message-box--${this.fields.Level.fields.Key.value}`;
      }

      // make it always visible in experience editor
      if (this.isEditorActive) return 'message-box--alert';

      return null;
    },
  },
  watch: {
    hidden() {
      if (!this.hidden && this.fields.Level.fields.Key.value === 'alert')
        this.$root.$emit('onMessageBoxAlert', this.fields.Text.value);
    },
  },
  mounted() {
    // make it always visible in experience editor
    if (this.isEditorActive || !this.fields.ShowOnEvent) this.hidden = false;

    this.$root.$on(this.fields.ShowOnEvent?.fields.Key.value, () => {
      this.hidden = false;
    });

    this.$root.$on(this.fields.HideOnEvent?.fields.Key.value, () => {
      this.hidden = true;
    });
  },
};
</script>

<style lang="scss">
.message-box {
  margin: 24px auto;
  padding: $spacing-s;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  @include desktop {
    padding: 20px;
  }

  a {
    @extend .link;
  }

  &--alert {
    background: #{$color-red-lighter};
    border: 3px solid #{$color-red};
  }

  &--info {
    background: #{$color-green-light};
    border: 3px solid #{$color-green};
  }
}
</style>
