<template>
  <div class="spinner" :class="{ 'spinner--big': big }">
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    big: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.spinner {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -12px; // a fix so the button doesn't grow in height due to the spinner
  margin-bottom: -12px; // a fix so the button doesn't grow in height due to the spinner

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 4px solid #{$color-black};
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #{$color-black} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &--big {
    div {
      width: 40px;
      height: 40px;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
