<template>
  <div v-if="visible" class="flow-selector" :style="backgroundColor">
    <nav class="flow-selector__navigation" v-if="navigationLinks.length">
      <ul>
        <li v-for="link in navigationLinks" :key="link.id">
          <RouterLink
            :to="link.fields.Route.url"
            :class="{ active: active(link.fields.Route.url) }"
            active-class="active"
            class="text-l"
            >{{ link.fields.LinkText.value }}</RouterLink
          >
        </li>
      </ul>
    </nav>

    <div class="flow-selector__text desktop-wide">
      <ScImage v-if="fields.Image" class="flow-selector__image m-b-s m-md-b-m" :media="fields.Image" />
      <ScText tag="h1" :field="fields.Title" :class="['m-b-xs', hasImage ? 'text-l text-md-l' : 'title-s title-md-s m-md-b-m']" />
      <ScText tag="p" :field="fields.Text" :class="{ 'm-md-b-m': !hasImage }" class="text-s text-md-s m-b-xs" />
      <SpotList
        v-if="fields.Spots.length > 0"
        :spots="fields.Spots"
        class="text-xs text-md-s center m-b-xs"
        :imgsize="'24px'"
        :imgmdsize="'32px'"
      />
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import SpotList from './SpotList.vue';

export default {
  name: 'FlowSelector',
  components: {
    ScText: Text,
    ScImage: Image,
    SpotList,
  },
  props: {
    fields: {
      type: Object,
    },
    bgColor: {
      type: String,
      default: '#{$color-green-lighter}',
    },
  },
  data() {
    return {
      switchLinkLeft: this.fields.SwitchLinkLeft?.fields?.Route?.url.toLowerCase(),
      switchLinkRight: this.fields.SwitchLinkRight?.fields?.Route?.url.toLowerCase(),
    };
  },
  computed: {
    ...mapGetters({
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
    }),
    navigationLinks() {
      // we do not want links without a route
      return this.fields.NavigationLinks.filter((link) => link.fields.Route != null);
    },
    backgroundColor() {
      return { 'background-color': this.bgColor };
    },
    hasImage() {
      return this.fields.Image?.value && Object.keys(this.fields.Image.value).length > 0;
    },
    visible() {
      return !this.isRecruitmentOrderType || this.$store.state.app[this.fields.ToggleByStoreValue.value];
    },
  },
  methods: {
    active(url) {
      url = url.toLowerCase();
      const path = this.$route.path.endsWith('/') ? this.$route.path.slice(0, -1) : this.$route.path;
      const pathLower = path.toLowerCase();
      return (
        url === pathLower ||
        ((this.switchLinkLeft === url || this.switchLinkRight === url) &&
          (this.switchLinkLeft === pathLower || this.switchLinkRight === pathLower))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-selector {
  $after-height: 10px;

  transition: background-color 0.5s;
  border-radius: 0 0 30px 30px;
  padding: 24px 16px;
  margin: 0 -16px;
  width: Calc(100% + 32px) !important;
  margin-bottom: 20px;

  @include phone-xs {
    margin-left: -12px;
    margin-right: -12px;
    width: Calc(100% + 24px) !important;
    padding: 24px 12px 32px;
  }
  @include desktop {
    width: Calc(100% + 48px) !important;
    margin: 0 -24px;
    border-radius: 0;
    padding: 32px 16px;
  }

  &__text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__image {
    max-width: 235px;
    width: auto;
    max-height: 44px;
    height: auto;

    @include desktop {
      max-height: 66px;
      max-width: 353px;
    }
  }

  &__navigation {
    margin-bottom: Calc($spacing-m + $after-height);

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 12px;
      @include desktop {
        gap: 24px;
      }

      li {
        flex: 1;
        max-width: 180px;

        a {
          position: relative;
          padding: 12px 24px;
          background-color: #{$color-white};
          display: flex;
          text-align: center;
          justify-content: center;
          border-radius: 16px;
          border: 1px solid #{$color-grey-darker};
          color: #{$color-black};

          &:after {
            content: '';
            position: absolute;
            left: 30px;
            bottom: -$after-height;
            border-left: $after-height solid transparent;
            border-right: $after-height solid transparent;
            border-top: $after-height solid transparent;
          }

          &.active {
            background-color: #{$color-almost-black};
            color: #{$color-white};
            font-family: $font-normal;
            border: 1px solid #{$color-almost-black};

            &:after {
              border-top-color: #{$color-almost-black};
            }
          }
        }
      }
    }
  }
}
</style>
