<template>
  <div class="product-list-multi m-t-s">
    <div v-if="!showProductList">
      <ScText tag="h2" class="product-list-multi__title text-m text-m text-md-m" :field="fields.ProductListMultiTitle" />
      <ScText tag="p" class="product-list-multi__description text-m m-t-s" :field="fields.ProductListMultiDescription" />
      <div class="product-list-multi__item-list m-t-l">
        <div
          v-for="basketItem in items"
          :key="basketItem.id"
          class="product-list-multi__item"
          @click="select(basketItem)"
          tabindex="0"
          @keypress.enter.space="select(basketItem)"
          :class="{ discount: isDiscounted(basketItem) }"
        >
          <div class="flex justify-content--space-between align-items--center">
            <div class="product-list-multi__item--left">
              <h3 class="title-xxs title-md-xxs">{{ basketItem.product.fields.Title.value }}</h3>
              <p v-if="isDiscounted(basketItem)" class="text-l">{{ getDiscountLabel(basketItem.priceKey) }}</p>
            </div>
            <div class="product-list-multi__item--right">
              <div class="flex flex-direction--column text-align--right">
                <div>
                  <strong class="price title-xxs title-md-xxs">{{ getPriceText(basketItem) }}</strong>
                  <span class="postfix text-xs m-l-xxs">{{ $t('price-postfix') }}</span>
                </div>
                <s v-if="isDiscounted(basketItem)" class="text-xs">{{ getDiscountedPriceText(basketItem.product) }}</s>
              </div>
              <i aria-hidden class="icon icon-right title-s m-l-s"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container m-t-l">
        <button class="button secondary-btn" @click="onGoToPreviousStep">
          {{ fields.ProductListMultiButtonPreviousText.value }}
        </button>
      </div>
    </div>
    <div v-else>
      <ProductList :fields="fields"></ProductList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ProductList from './ProductList.vue';
import { getProductPriceFromRates } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'ProductListMulti',
  components: {
    ScText: Text,
    ProductList,
  },
  inject: {
    onGoToPreviousStep: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      denotePostfix: `${this.$t('price-denote')} ${this.$t('price-postfix')}`,
      showProductList: false,
      hasInitialCurrentItem: false,
    };
  },
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket',
      getRatePlansDefault: 'config/getRatePlansDefault',
    }),
    items() {
      return this.basket.items;
    },
    hasCurrentItem() {
      if (!this.$store.state.basket?.currentItem) return false;
      return Object.keys(this.$store.state.basket?.currentItem).length > 0;
    },
    hasOneItemInBasket() {
      return this.items?.length === 1;
    },
  },
  created() {
    // if there is only one item in the basket or current item is set, show product list instantly
    if (this.hasOneItemInBasket || this.hasCurrentItem) {
      this.showProductList = true;
      this.hasInitialCurrentItem = true;
    }
  },
  mounted() {
    this.$root.$on('goToPreviousView', () => {
      this.$store.dispatch('basket/clearCurrentItem');
      // if the current item was set initially, go back to previous step, otherwise hide the product list
      this.hasInitialCurrentItem ? this.onGoToPreviousStep() : (this.showProductList = false);
    });
  },
  beforeDestroy() {
    this.$root.$off('goToPreviousView');
  },
  methods: {
    getPriceText(basketItem) {
      return `${basketItem.price}${this.$t('price-denote')}`;
    },
    select(basketItem) {
      this.$store.dispatch('basket/addCurrentItem', basketItem);
      this.showProductList = true;
    },
    isDiscounted(basketItem) {
      return basketItem.priceKey !== 'Normal';
    },
    getDiscountedPriceText(product) {
      const ratePlans = this.getRatePlansDefault;
      return `${this.$t('before')} ${getProductPriceFromRates({ product, ratePlans })}${this.denotePostfix}`;
    },
    getDiscountLabel(priceKey) {
      switch (priceKey) {
        case 'U30':
          return this.$t('label-u30');
        // case 'Partner':
        //   return this.$t('label-partner'); // TODO: clarify if label should be shown with partner discount
        default:
          return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-list-multi {
  &__title,
  &__description {
    text-align: left;
    @include desktop {
      text-align: center;
    }
  }

  &__item {
    border-radius: $spacing-s;
    @include box-shadow(0px 2px 10px 0px rgba(0, 0, 0, 0.1));
    border: 1px solid $color-grey;
    padding: 20px $spacing-m;
    cursor: pointer;
    > * {
      gap: $spacing-m;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: $spacing-m;
    }
    &--right {
      display: flex;
      align-items: center;
    }
    &.discount {
      padding: $spacing-s $spacing-m;
      .price {
        color: $color-green-darker;
      }
    }
  }
  .button {
    width: auto;

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 $spacing-m;
      margin-bottom: $spacing-xl;
    }
  }
}
</style>
