<template>
  <div
    v-show="visible"
    class="check-box"
    :class="[embedded ? 'desktop-wide' : 'desktop-narrow', { invalid: requiredError }, fields.ClassList.value]"
  >
    <input
      type="checkbox"
      class="check-box__input"
      :id="fields.Key.value"
      :name="fields.Key.value"
      v-model="checkboxValue"
      @change="changed($event)"
    />
    <div class="check-box__content">
      <ScText :for="fields.Key.value" tag="label" class="check-box__content-label" :field="fields.LabelText" />
      <slot></slot>
      <div class="check-box__content-error" v-if="errorMessage" v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CheckBox',
  components: {
    ScText: Text,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    embedded: {
      type: Boolean,
    },
    startValue: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      checkboxValue: this.startValue ?? this.$store.state.app[this.fields.Key.value],
      requiredError: false,
      errorMessage: null,
      visible: true,
    };
  },
  watch: {
    errorMessage(error) {
      // Track displaying validation errors in AppRoot
      if (error) this.$root.$emit('onErrorMessage', error);
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  mounted() {
    this.addToValidation(this);

    this.$nextTick(() => {
      // wait for listening components to render in before emiting
      this.onEmitValue(this, this.checkboxValue);
    });

    this.$root.$on('onSave', () => {
      this.onSaveEvent(this.fields.Key.value, this.checkboxValue, this.$options.name);
    });

    if (this.fields.ToggledBy && !this.isEditorActive) {
      this.visible = false;

      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data?.toLowerCase().includes(this.fields.ToggledBy.id);
        if (!this.visible) {
          this.checkboxValue = null;
          this.errorMessage = null;
        }
      });
    }
  },
  methods: {
    changed(e) {
      this.$emit('change', e);
      this.onEmitValue(this, e.target.checked);
      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.checkboxValue;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    hidden() {
      return !this.visible;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    validated(valid, validation, validationError) {
      if (!valid && validationError) {
        this.errorMessage = validation.fields.ErrorText.value;
        this.requiredError = true;
      } else {
        this.errorMessage = null;
        this.requiredError = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;

  &__content {
    padding-left: #{$spacing-xs};

    &-error {
      color: #{$color-red};
      margin: 8px 0;
    }
  }

  &.invalid &__content label {
    color: #{$color-red};
  }
  &.invalid input::after {
    border: 2px solid #{$color-red};
  }
}
</style>
