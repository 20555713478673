<template>
  <div class="link-card" @click="$router.push(fields.Route.url)">
    <ScText tag="h2" class="link-card__title" :field="fields.Heading" />
    <ScText tag="p" class="link-card__text" :field="fields.Text" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'LinkCard',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.link-card {
  margin: 0 24px;
  padding: 24px;
  background: #{$color-white};
  border: 2px solid #{$color-grey};
  box-sizing: border-box;
  box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  max-width: 327px;
  text-align: left;

  &-hover-effect {
    @extend .lift-up;
  }

  // a lot of styling that paints in a green arrow that animates on hover.
  // Done in pure css to avoid using icon or extra html mark up.
  &-arrow {
    cursor: pointer;
    padding-right: 64px;
    position: relative;

    &::before {
      content: '';
      transition: all 0.25s ease-in-out;
      display: block;
      background: #{$color-green-dark};
      width: 18px;
      height: 2px;
      position: absolute;
      right: 24px;
      top: calc(50% - 1px);
    }

    &::after {
      content: '';
      transition: all 0.25s ease-in-out;
      color: #{$color-green-dark};
      border-top: 2px solid #{$color-green-dark};
      border-right: 2px solid #{$color-green-dark};
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      position: absolute;
      right: 24px;
      top: calc(50% - 5px);
    }

    &:hover::before {
      right: 19px;
      width: 23px;
    }

    &:hover::after {
      right: 19px;
    }

    &-black {
      &::before {
        background: #{$color-black};
      }

      &::after {
        color: #{$color-black};
        border-top: 2px solid #{$color-black};
        border-right: 2px solid #{$color-black};
      }
    }

    &-white {
      &::before {
        background: #{$color-white};
      }

      &::after {
        color: #{$color-white};
        border-top: 2px solid #{$color-white};
        border-right: 2px solid #{$color-white};
      }
    }

    &-disabled {
      cursor: default;

      &::before {
        background: #{$color-white};
      }

      &::after {
        color: #{$color-white};
        border-top: 2px solid #{$color-white};
        border-right: 2px solid #{$color-white};
      }
    }
  }

  &__title {
    margin: 0;
    font-family: 'CircularProBold', Helvetica, Arial, sans-serif;
    font-size: 19px;
    line-height: 26.5px;
  }

  &__text {
    color: var(--color-grey-darker);
  }
}
</style>
