<template>
  <FlowSelector :fields="fields" :bgColor="bgColor">
    <div class="subnavigation-switch" v-if="hasLinks">
      <nav class="switcher">
        <div class="toggle">
          <RouterLink
            :to="fields.SwitchLinkLeft.fields.Route.url"
            :class="{ active: isActive(fields.SwitchLinkLeft.fields.Route.url) }"
            class="text-m"
          >
            {{ fields.SwitchTextLeft.value }}
          </RouterLink>
          <RouterLink
            :to="fields.SwitchLinkRight.fields.Route.url"
            :class="{ active: isActive(fields.SwitchLinkRight.fields.Route.url) }"
            class="text-m"
          >
            {{ fields.SwitchTextRight.value }}
          </RouterLink>
        </div>
      </nav>
    </div>
  </FlowSelector>
</template>

<script>
import FlowSelector from './util/FlowSelector.vue';

export default {
  name: 'FlowSelectorNavigation',
  components: {
    FlowSelector,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    bgColor() {
      return this.fields?.BackgroundColor?.value;
    },
    hasLinks() {
      return this.fields?.SwitchLinkLeft?.fields?.Route?.url != null && this.fields?.SwitchLinkRight?.fields?.Route?.url != null;
    },
  },
  methods: {
    isActive(targetRoute) {
      const currentPath = this.$route.path.toLowerCase();
      const targetPath = targetRoute.toLowerCase();
      return currentPath === targetPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  padding: 5px 7px;

  a {
    display: inline-block;
    padding: 8px 12px;

    &.active {
      background: $color-almost-black;
      color: $color-white;
    }
  }
}
</style>
