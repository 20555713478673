<template>
  <div v-if="visible" :class="containerBackgroundColorClass">
    <div class="long-text">
      <ScText tag="h3" class="title-s title-md-s text-center full-width m-b-xl m-md-b-3xl" :field="fields.LongTextTitle" />
      <ScRichText tag="div" class="text-m text-md-m" :field="fields.LongText" />
    </div>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'LongText',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    containerBackgroundColorClass() {
      const value = this.fields?.ContainerBackgroundColor?.fields.Type.value;
      if (value) return `bg-${value}`;
      else return '';
    },
  },
  created() {
    if (this.fields.ToggledBy) {
      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data;
      });
    } else this.visible = true;
  },
};
</script>

<style lang="scss">
.long-text {
  padding: 72px 16px;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;

  ul {
    list-style: none;
    padding-left: 32px;
    position: relative;
    margin-left: 0;
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;

    li {
      text-align: left;
      padding-bottom: $spacing-s;
      padding-left: 0;
      &:before {
        content: '\2022';
        color: $color-green-darker;
        display: inline-block;
        padding-right: $spacing-s;
        width: 6px;
        position: absolute;
        font-size: 19px;
        left: 0;
      }

      &:last-child {
        padding-bottom: $spacing-xs;
      }
    }
  }
}
</style>
