<template>
  <div
    class="radio-button desktop-narrow"
    @click="toggle"
    @keypress.enter="toggle"
    @keypress.space="toggle"
    :class="{ 'radio-button-toggled': toggled, disabled }"
    tabindex="0"
    v-show="visible"
  >
    <input
      type="radio"
      @click.stop="toggle"
      :name="fields.Key.value"
      :id="$vnode.data.key"
      :value="getRadioValue()"
      :checked="radioButtonValue == getRadioValue()"
    />
    <span class="radio-button__circle"></span>
    <div class="radio-button__content">
      <ScText :for="$vnode.data.key" tag="label" class="radio-button__content-label text-md-m" :field="fields.LabelText" />
      <ScText v-if="isEditorActive" tag="span" class="radio-button__content-text text-md-m" :field="fields.Text" />
      <span v-else class="radio-button__content-text text-md-m">{{ text }}</span>
    </div>
    <ScText v-if="disabled" tag="span" class="tooltiptext text-s text-md-s" :field="fields.DisableHoverText" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'RadioButton',
  components: {
    ScText: Text,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
    emitInputValue: {
      type: Function,
    },
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    rendering: {
      type: Object,
    },
    emitValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      radioButtonValue: this.$store.state.app[this.fields.Key.value],
      value: null,
      toggled: null,
      visible: true,
      disabled: false,
    };
  },
  computed: {
    text() {
      if (this.fields.Text?.value && !this.isEditorActive) {
        return this.fields.Text.value.replace('{owner}', `${this.$store.state.app.firstname} ${this.$store.state.app.lastname}`);
      }
      return this.fields.Text.value;
    },
  },
  watch: {
    toggled(newVal) {
      this.emitInputValue(this, newVal);
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
    this.$root.$off('clearRadioButtons');
  },
  mounted() {
    this.addToValidation(this);
    this.value = this.$store.state.app[this.fields.Key.value];

    this.$root.$on('clearRadioButtons', (data) => {
      if (data.key === this.fields.Key.value) {
        this.radioButtonValue = null;
        this.toggled = false;
        this.value = data.value;
      }
    });

    this.$root.$on('onSave', () => {
      if (this.radioButtonValue) {
        this.onSaveEvent(this.fields.Key.value, this.radioButtonValue, this.$options.name);
      }
    });

    if (this.fields.HideWhenStoreValueDefined?.value) {
      if (this.fields.HideWhenStoreValueDefinedEqualsValue?.value) {
        this.visible =
          this.$store.state.app[this.fields.HideWhenStoreValueDefined.value] !==
          this.fields.HideWhenStoreValueDefinedEqualsValue.value;
      } else {
        this.visible = typeof this.$store.state.app[this.fields.HideWhenStoreValueDefined.value] === 'undefined';
      }
    }

    if (this.radioButtonValue !== this.getRadioValue()) {
      if (this.fields.DisableWhenGetterIsTrue?.value) {
        this.disabled = this.$store.getters[this.fields.DisableWhenGetterIsTrue.value];
      }
      this.radioButtonValue = null;
      this.toggled = false;
    } else {
      if (!this.visible) return;
      this.toggled = true;
      this.$nextTick(() => {
        // wait for listening components to render in before emiting
        this.onEmitValue(this, this.emitValue ? this.emitValue : this.rendering.dataSource);
        if (this.fields.Validations) this.$root.$emit('onValidate', this);
      });
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;

      this.$emit('click');
      this.$root.$emit('clearRadioButtons', { key: this.fields.Key.value, value: this.getRadioValue() });
      this.radioButtonValue = this.getRadioValue();
      this.onEmitValue(this, this.emitValue ? this.emitValue : this.rendering.dataSource);
      this.toggled = true;
      this.value = this.getRadioValue();
      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    },
    getRadioValue() {
      return this.fields.Value.value ? this.fields.Value.value : this.$vnode?.data.key;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  background: #{$color-white};
  border: 1px solid #{$color-grey};
  padding: 16px;
  border-radius: 16px;
  align-items: center;
  cursor: pointer;
  @include box-shadow(#{$tile-shadow});

  @include desktop {
    padding: $spacing-s $spacing-m;
  }

  input {
    display: none;
  }

  .radio-button__circle {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #{$color-grey-in-between};
    flex-shrink: 0;
    &:after {
      content: '';
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: white;
      top: 0;
      left: 0;
    }
  }
  &.disabled {
    background-color: #{$color-grey-lighter};
    box-shadow: none;

    .radio-button__circle {
      background-color: rgba(229, 229, 229, 1);
      &:after {
        background-color: rgba(229, 229, 229, 1);
      }
    }

    .radio-button__content {
      pointer-events: none;
      color: #{$color-grey-darker};
    }
    &:hover {
      border: 1px solid #{$color-grey};
      box-shadow: none;
      cursor: not-allowed;

      .radio-button__circle {
        border: 2px solid #{$color-grey-dark};
      }
    }
  }

  &:hover {
    @include inputHoverEffect;
    .radio-button__circle {
      border-color: #{$color-black};
    }
    .tooltiptext {
      visibility: visible;
    }
  }

  &:focus-visible {
    border-color: #{$color-black};
    .radio-button__circle {
      border-color: #{$color-black};
    }
  }

  &-toggled {
    border-color: #{$color-green-darker};
    background: #{$color-green-lighter};

    .radio-button__circle {
      background-color: #{$color-green-darker};
      border: 2px solid #{$color-green-darker};
    }
  }

  &__content {
    padding-left: 16px;
    display: flex;
    flex-direction: column;

    &-label {
      cursor: pointer;
      font-family: $font-bold;
      font-weight: 600;
      line-height: 34px;
    }
  }
  .tooltiptext {
    visibility: hidden;
    width: 100%;
    background-color: #{$color-green-tint}; //#9ADF77
    text-align: left;
    border-radius: 8px;
    padding: 16px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px;
      border-color: #{$color-green-tint} transparent transparent #{$color-green-tint};
      transform: rotate(45deg);
      top: -8px;
      left: 64px;
      border-radius: 4px 0px 0px 0px;
    }
  }
}
</style>
