<template>
  <div class="steps-container flex full-width align-items--center flex-direction--column">
    <div
      v-for="(step, i) in steps"
      :key="i"
      class="flex full-width align-items--center flex-direction--column"
      :class="[{ 'mobile-full-height desktop-min-height': isCurrentStep(step) }]"
    >
      <div
        class="steps-container__step flex align-items--center"
        :class="[{ current: isCurrentStep(step), done: isStepDone(step) }]"
      >
        <div class="steps-container__wrapper flex align-items--center">
          <div class="steps-container__container flex align-items--center">
            <div class="steps-container__number">
              <span class="text-xs bold text-md-s">{{ getStepNumber(step) }}</span>
            </div>
            <div class="steps-container__text">
              <h2 class="text-s text-md-s">{{ getStepTitle(step) }}</h2>
              <SummaryProductTitle v-if="showProductTitle(step)" :productItem="getProduct" class="text-xs text-md-s" />
              <p class="text-xs text-md-s" v-else-if="isCurrentStep(step) || isStepDone(step)">{{ getStepDescription(step) }}</p>
            </div>
          </div>

          <router-link
            :to="step.name"
            v-if="isStepDone(step)"
            @click.native="handleClick(step.name)"
            class="steps-container__button"
          >
            {{ $t('change') }}
          </router-link>
        </div>
      </div>
      <slot v-if="isCurrentStep(step)"></slot>
    </div>
  </div>
</template>

<script>
import SummaryProductTitle from '../util/SummaryProductTitle.vue';
import config from './../../temp/config';

export default {
  name: 'StepsContainer',
  components: {
    SummaryProductTitle,
  },
  computed: {
    steps() {
      return this.$store.state.config.stepsPages;
    },
    allUsersCompleted() {
      return this.$store.getters['basket/allUsersCompleted'];
    },
    userStepIndex() {
      return this.steps.findIndex((step) => step.name === 'brukere' || step.name === 'familien' || step.name === 'bruker');
    },
    getProduct() {
      return this.$store.state.basket.items?.[0] || null;
    },
  },
  watch: {
    allUsersCompleted(newVal) {
      // the value changes when all users are completed/edited in user overlays
      if (newVal === false) {
        this.removeCompletedSteps();
      }
    },
  },
  beforeDestroy() {
    this.$root.$off('onCompleteStep');
  },
  mounted() {
    this.$root.$on('onCompleteStep', () => {
      this.onCompleteStep();
    });

    if (this.allUsersCompleted === false) {
      this.removeCompletedSteps();
    }
  },
  methods: {
    onCompleteStep() {
      // check if current step is already in completedSteps
      const completedSteps = this.$store.state.app.completedSteps;
      const currentStep = this.steps.find((step) => this.isCurrentStep(step));

      const alreadyCompleted = completedSteps.find((step) => step.name === currentStep.name);
      if (alreadyCompleted) return;

      this.$store.commit('app/addCompletedStep', currentStep);
      console.log(`${this.$options.name}: Added step: '${currentStep.name}' to key: 'completedSteps'`);
    },
    removeCompletedSteps() {
      console.log(`${this.$options.name}: Removing all completed steps after user step..`);
      if (this.userStepIndex === -1) {
        console.error(`${this.$options.name}: Could not find user step in steps array.`);
        return;
      }
      const stepsToRemove = this.steps.slice(this.userStepIndex);
      this.$store.commit('app/removeCompletedSteps', stepsToRemove);
    },
    getStepTitle(step) {
      const stepTitle = step.fields.find((field) => field.name === 'stepTitle');
      if (stepTitle) return stepTitle.value;
    },
    showProductTitle(step) {
      // show SummaryProductTitle in abonnement step if there is only one item in basket and in family flow
      return (
        step.name === 'abonnement' &&
        (this.$store.state.basket.items?.length === 1 || this.$store.getters['basket/getUsersCount'])
      );
    },
    getStepDescription(step) {
      // if we are done with the step we will show different description
      if (this.isStepDone(step)) {
        const stepDescriptionDone = step.fields.find((field) => field.name === 'stepDescriptionDone');
        if (!stepDescriptionDone) return '';

        if (stepDescriptionDone.value.includes('{itemAmount}')) {
          return stepDescriptionDone.value.replace('{itemAmount}', this.$store.state.basket?.items?.length);
        }

        if (stepDescriptionDone.value == '{owner}') {
          return `${this.$store.state.app?.firstname} ${this.$store.state.app?.lastname}`;
        }

        if (stepDescriptionDone.value == '{user}') {
          // check if we are in a single flow
          const itemAmount = this.$store.state.basket.items.length;
          if (itemAmount === 1) {
            const user = this.$store.state.basket.items[0].user;
            return `${user?.firstname} ${user?.lastname}${user?.info === 'owner' ? ` - ${this.$t('owner')}` : ''}`;
          } else {
            return `${itemAmount} ${this.$t('subscriptions')}`;
          }
        }

        if (stepDescriptionDone.value == '{digitalSecurity}') {
          if (this.$store.state.app?.digitalSecurity) return this.$t('stepDigitalSecuritySelected');
          return this.$t('stepDigitalSecurityDefault');
        }

        if (stepDescriptionDone.value.includes('{memberAmount}')) {
          return stepDescriptionDone.value.replace('{memberAmount}', this.$store.state.basket?.usersCount);
        }

        if (stepDescriptionDone.value == '{startDate}') {
          const date = new Date(this.$store.state.app?.startDate);
          if (date instanceof Date && !isNaN(date)) {
            return date.toLocaleDateString('no-no', { year: 'numeric', month: 'long', day: 'numeric' });
          }

          return '';
        }

        if (stepDescriptionDone.value.includes('{productName}')) {
          return stepDescriptionDone.value.replace(
            '{productName}',
            this.$store.state.basket?.items[0].product?.fields.Title?.value,
          );
        }

        return stepDescriptionDone.value;
      }

      // we are not done with the step, so we show 'normal' description
      const stepDescription = step.fields.find((field) => field.name === 'stepDescription');
      if (!stepDescription) return '';

      if (stepDescription.value.includes('{productName}')) {
        return stepDescription.value.replace('{productName}', this.$store.state.basket?.items[0].product?.fields.Title?.value);
      }

      if (stepDescription.value.includes('{itemAmount}')) {
        return stepDescription.value.replace('{itemAmount}', this.$store.state.basket?.items?.length);
      }

      if (stepDescription.value.includes('{memberAmount}')) {
        return stepDescription.value.replace('{memberAmount}', this.$store.state.basket?.usersCount);
      }

      return stepDescription.value;
    },
    getStepNumber(step) {
      const stepNumber = step.fields.find((field) => field.name === 'stepNumber');
      if (stepNumber) return stepNumber.value;
    },
    isCurrentStep(step) {
      const trimmedPath = this.$route.path.toLowerCase().replace(config.appBasePath, '');
      return trimmedPath.includes(`/${step.name.toLowerCase()}`);
    },
    isStepDone(step) {
      if (!step) return false;
      if (this.isCurrentStep(step)) return false;

      // special case for abonnement step within stepsContainer
      if (step.name === 'abonnement' && this.$store.state.basket?.items?.length) return true;

      if (!this.$store.state.app?.completedSteps?.length) return false;
      if (this.$store.state.app?.completedSteps.find((completedStep) => completedStep.name === step.name)) return true;
      return false;
    },
    handleClick(stepName) {
      if (stepName === 'abonnement') this.$store.dispatch('basket/clearCurrentItem');
    },
  },
};
</script>

<style lang="scss">
.steps-container {
  &__button {
    font-size: 16px;
    margin-left: auto;

    @include desktop {
      font-size: 18px;
    }
  }

  &__step {
    min-height: 89px;
    padding: 16px;
    max-width: 760px;
    width: 100%;
    background: var(--neutrals-grey-light, #f2f2f2);
    margin-bottom: 2px;

    @include screen-tablet-portrait-up {
      border-radius: 30px;
      margin-bottom: 12px;
      padding: 16px 24px;
    }

    &.current {
      background: #{$color-green-light};
      margin-bottom: 0;
      padding-top: 36px;
      max-width: Calc(760px + #{24px});

      @include screen-tablet-portrait-up {
        padding: 16px 24px;
        margin-bottom: 32px;
      }

      .steps-container__number {
        background-color: white;
        span {
          color: $color-black;
        }
      }
    }

    &.done {
      background: var(--green-lighter, #effae9);
      .steps-container__number {
        background-color: #{$color-green-tint};
        &:after {
          content: map-get($icons, icon-check-bold);
          color: #{$color-white};
          font-family: $talkmore-icons;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          line-height: normal;
          @include screen-tablet-portrait-up {
            font-size: 18px;
          }
        }
        span {
          color: $color-white;
          display: none;
        }
      }
    }
  }

  &__container {
    color: #000;
    gap: 16px;
    @include screen-tablet-portrait-up {
      gap: 24px;
    }
  }

  &__wrapper {
    max-width: Calc(760px - #{48px});
    margin: 0 auto;
    width: 100%;
  }

  &__number {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    background: #{$color-grey-darker};

    span {
      line-height: normal !important;
      color: #{$color-white};
    }
  }
}
</style>
