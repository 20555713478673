<template>
  <div class="extrasim">
    <div class="flex flex-direction--column summary-product__left">
      <h5 class="text-m text-md-m">{{ getExtraSimTitle() }}</h5>
      <div class="flex flex-direction--column text-s text-md-s">
        <ScText tag="span" :field="extraSimText" />
      </div>
    </div>
    <div class="flex flex-direction--column summary-product__top--right">
      <button class="extrasim__button button-textlink transparent-btn" @click="openOverlay()">
        <span class="main">
          <span class="text text-s text-normal text-md-m">{{ $t('change') }}</span>
        </span>
      </button>
    </div>

    <BottomSheet
      data-focustrap-ignore
      ref="summaryBottomSheet"
      class="summary-bottomsheet"
      :closeText="bottomSheetCloseText"
      :titleText="bottomSheetTitle"
      :maxWidth="400"
    >
      <ul>
        <li
          class="text-m text-md-m tabbable"
          v-for="index in bottomSheetListAmount + 1"
          :key="index - 1"
          @click="selectSimAmount(index - 1)"
          @keydown.enter.space.prevent="selectSimAmount(index - 1)"
        >
          <span :class="currentSimAmountSelected === index - 1 ? 'selected' : ''">{{ index - 1 }}</span>
        </li>
      </ul>

      <div class="flex justify-content--center m-t-s bottom-sheet-button">
        <button class="primary-xs-small primary-md-default primary-btn" @click="handleExtraSim()">
          <span>{{ bottomSheetButtonText }}</span>
        </button>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import BottomSheet from './BottomSheet.vue';
import { gtmSharedParams, gtmExtraSimParams } from '@/gtmTracking';
import { gtmEcommerceEvent, gtmModalViewEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'SummaryExtraSim',
  components: {
    ScText: Text,
    BottomSheet,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      extraSimTitle: this.$store.state.config?.extraSim?.title,
      extraSimText: this.$store.state.config?.extraSim?.text,
      bottomSheetTitle: this.$store.state.config?.extraSim?.bottomSheetTitle,
      bottomSheetCloseText: this.$store.state.config?.extraSim?.bottomSheetCloseText,
      bottomSheetButtonText: this.$store.state.config?.extraSim?.bottomSheetButtonText,
      currentSimAmountSelected: this.$store.state.basket?.currentItem?.extraSim || null,
    };
  },
  computed: {
    currentItem() {
      return this.$store.state.basket?.currentItem || null;
    },
    bottomSheetListAmount() {
      const amount = this.$store.state.config?.extraSim?.bottomSheetListAmount;
      return typeof amount === 'number' && Number.isInteger(amount) && amount > 0 ? amount : 9;
    },
    extraSimAmount() {
      return this.product.extraSim;
    },
  },
  methods: {
    getExtraSimTitle() {
      return this.extraSimTitle.replace('{amount}', this.extraSimAmount);
    },
    openOverlay() {
      // if user selects a different amount in modal, but closes afterwards without pressing "select"
      this.$store.dispatch('basket/addCurrentItem', this.product);
      this.currentSimAmountSelected = this.currentItem?.extraSim;
      this.$refs.summaryBottomSheet.open();

      const modal_name = 'ekstra-sim';
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeOverlay() {
      this.$refs.summaryBottomSheet.close();
    },
    selectSimAmount(val) {
      this.currentSimAmountSelected = val;
    },
    handleExtraSim() {
      this.$store.dispatch('basket/updateBasketItemExtraSim', { id: this.product.id, extraSim: this.currentSimAmountSelected });
      const currentSimAmount = this.currentItem?.extraSim;
      if (this.currentSimAmountSelected !== this.currentSimAmount) {
        const event = this.currentSimAmountSelected > currentSimAmount ? 'add_to_cart' : 'remove_from_cart';
        let quantity = this.currentSimAmountSelected - currentSimAmount;
        if (quantity < 0) quantity = quantity * -1; // change to positive number
        const items = [{ ...gtmExtraSimParams, quantity }];
        gtmEcommerceEvent({ event, items, gtmSharedParams: gtmSharedParams() });
      }
      this.$store.dispatch('basket/clearCurrentItem');
      this.closeOverlay();
    },
  },
};
</script>

<style lang="scss" scoped>
.extrasim {
  padding-top: $spacing-xs;
  &__button {
    margin-top: 0;
    padding: 0;
  }
}

.summary-bottomsheet {
  .bottom-sheet__main {
    padding-left: 0;
    padding-right: 0;
  }
  ul {
    list-style: none;
    margin-bottom: 104px;
    li {
      cursor: pointer;
      padding: 16px 24px;
      margin-left: -24px;
      margin-right: -24px;
      span {
        position: relative;
        width: 100%;
        display: block;
        &.selected {
          &:after {
            content: map-get($icons, icon-check);
            font-family: $talkmore-icons;
            height: 30px;
            width: 30px;
            position: absolute;
            right: 0;
            text-align: right;
          }
        }
      }
    }
  }
  .bottom-sheet-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 24px;

    @include desktop {
      padding-bottom: 32px;
    }
  }
}
</style>
