export function isRequired(value) {
  if (typeof value == 'boolean') return value;
  if (typeof value == 'string') return value.trim() !== '';
  else return value !== undefined && value !== null;
}

export function isEmail(value) {
  return String(value)
    .toLowerCase()
    .match(
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
}

export function isBirthday(value) {
  const matchString = String(value).match(/^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.\d{4}$/);
  // get current date to compare that the value is in the past (and the person is existing)
  if (!matchString) return false;

  const today = new Date();

  // we need to extract the matchstring and put it into a actual date format
  const birthdayDate = parseInt(matchString[1], 10);
  const birthDayMonth = parseInt(matchString[2], 10);
  const birthDayYear = matchString[0].slice(-4);

  let birthDate = new Date();
  birthDate.setDate(birthdayDate);
  birthDate.setMonth(birthDayMonth - 1);
  birthDate.setUTCFullYear(birthDayYear);
  if (birthDate > today) return false;

  return true;
}

export function validateChecksum(rewardNumber) {
  if (!rewardNumber) return;
  const len = rewardNumber.length;
  const rewardNumberWithoutCheckDigit = rewardNumber.substring(0, len - 1);
  const checkDigit = rewardNumber.substring(len - 1, len);
  const calculatedCheckDigit = calculateRewardNumberCheckDigit(rewardNumberWithoutCheckDigit);
  return checkDigit === calculatedCheckDigit;
}

// This is copied code from norwegian documentaion
// https://norwegian-reward.readme.io/docs/other-features
function calculateRewardNumberCheckDigit(val) {
  const checksum = new Array();
  let odd = false;
  let increment = 0;
  let i;
  for (i = 0; i < val.length; i++) {
    const t = val.substring(i, i + 1);
    if (odd) {
      odd = false;
      const intCheck = parseInt(t);
      const oddCheck = (intCheck * 2).toString();
      for (let j = 0; j < oddCheck.length; j++) {
        checksum[increment++] = oddCheck.substring(j, j + 1);
      }
    } else {
      odd = true;
      checksum[increment++] = t;
    }
  }
  let sumInput = 0;
  let sumChecksum = 0;
  for (i = 0; i < val.length; i++) {
    const t2 = val.substring(i, i + 1);
    const currentInt = parseInt(t2);
    sumInput = sumInput + currentInt;
  }
  for (i = 0; i < checksum.length; i++) {
    if (parseInt(checksum[i])) {
      sumChecksum = sumChecksum + parseInt(checksum[i]);
    }
  }
  const topRes = sumChecksum + 10;
  const divRes = Math.floor(topRes / 10);
  const sumRes = divRes * 10;
  let calcCheckSum = sumRes - sumChecksum;
  // if the highest number 10 is 10 then we force it to 0 to avoid number
  // overflow
  if (calcCheckSum == 10) {
    calcCheckSum = 0;
  }
  return calcCheckSum.toString();
}
