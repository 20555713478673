<template>
  <div v-if="visible" class="accordion-list__container full-width" :class="containerBackgroundColorClass">
    <div class="accordion-list content-container-10">
      <div class="accordion-list-head">
        <div>
          <ScText tag="h3" :field="fields.AccordionListTitle" class="accordion-list__title text-m text-md-m text-bold" />
        </div>
        <div v-if="showToggle" class="accordion-toggle-wrapper">
          <span
            tabindex="0"
            role="button"
            v-if="fields.AccordionListLinkCloseText.value && fields.AccordionListLinkText.value"
            class="main text-s text-md-s"
            @click="[isAllExpanded == true ? expandAllAccordions() : collapseAllAccordions()]"
            @keydown.enter.space.prevent="[isAllExpanded == true ? expandAllAccordions() : collapseAllAccordions()]"
          >
            {{ isAllExpanded == true ? fields.AccordionListLinkCloseText.value : fields.AccordionListLinkText.value }}
          </span>
        </div>
      </div>

      <div class="accordion-wrapper" v-for="(item, i) in fields.AccordionListItems" :key="i">
        <button
          ref="accordions"
          class="accordion"
          @click="toggleAccordion($event)"
          :aria-label="`${item.fields.AccordionItemTitle.value}`"
          :aria-expanded="false"
        >
          <!-- accordion header for desktop -->
          <span class="accordion-header__desktop hide-on-mobile">
            <ScText tag="span" class="title text-m text-md-m" :field="item.fields.AccordionItemTitle" />
            <span class="icon-container">
              <ScRichText class="pill pill-green text-center" tag="span" :field="item.fields.AccordionItemLabelText" />
              <i class="icon"></i>
            </span>
          </span>
          <!-- END accordion header for desktop -->
          <!-- accordion header for mobile -->
          <span class="accordion-header__mobile hide-on-tablet-up">
            <span class="title-icon__container">
              <ScText tag="span" class="title text-m text-md-m" :field="item.fields.AccordionItemTitle" />
              <i class="icon"></i>
            </span>
            <span class="pill__container">
              <ScRichText class="pill pill-green text-center" tag="span" :field="item.fields.AccordionItemLabelText" />
            </span>
            <!-- END accordion header for mobile -->
          </span>
        </button>
        <div class="panel">
          <ScRichText class="accordion-info text-m text-md-m" tag="div" :field="item.fields.AccordionItemText" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'AccordionList',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      isAllExpanded: false,
    };
  },
  computed: {
    visible() {
      if (this.fields.HideWhenStoreValueDefinedEqualsValue?.value) {
        const storeValue = this.$store.state.app[this.fields.HideWhenStoreValueDefined?.value];
        const storeValueAsString = typeof storeValue !== 'string' ? String(storeValue) : storeValue;

        const fieldValue = this.fields.HideWhenStoreValueDefinedEqualsValue?.value;
        const fieldValueAsString = typeof fieldValue !== 'string' ? String(fieldValue) : fieldValue;

        return storeValueAsString !== fieldValueAsString; // TODO: create a helper function for comparing values
      }
      if (this.fields.HideWhenStoreValueDefined?.value) {
        return !Object.hasOwn(this.$store.state.app, this.fields.HideWhenStoreValueDefined.value);
      }
      return true;
    },
    containerBackgroundColorClass() {
      const value = this.fields?.ContainerBackgroundColor?.fields.Type.value;
      if (value) return `bg-${value}`;
      else return '';
    },
    showToggle() {
      return (
        this.fields.AccordionListLinkCloseText.value !== '' &&
        this.fields.AccordionListLinkText.value !== '' &&
        this.fields.AccordionListItems.length > 1
      );
    },
  },
  mounted() {
    // Make sure that links are not tabbable in closed accordions when page has loaded
    this.$nextTick(() => {
      this.$refs.accordions?.forEach((accordion) => {
        const panel = accordion.nextElementSibling;
        const panelContent = panel.querySelector('.accordion-info');

        if (panelContent && !this.isExpanded(accordion)) {
          this.setTabIndexForLinks(panelContent, false);
        }
      });
    });
  },
  methods: {
    toggleAccordion(event) {
      const accordion = event.currentTarget;
      const panel = accordion.nextElementSibling;
      const panelContent = panel.querySelector('.accordion-info');

      if (!panelContent) return;

      accordion.classList.toggle('active');

      if (!this.isExpanded(accordion)) {
        panel.classList.add('visible');
        panel.style.maxHeight = panelContent.clientHeight + 'px';
        accordion.setAttribute('aria-expanded', 'true');

        // Reset tabindex for links inside the accordion
        this.setTabIndexForLinks(panelContent, true);
      } else {
        panel.style.maxHeight = null;
        panel.classList.remove('visible');
        accordion.setAttribute('aria-expanded', 'false');

        // Disable tabindex for links inside the accordion
        this.setTabIndexForLinks(panelContent, false);
      }
      this.checkAllAccordionsExpanded();
    },
    collapseAllAccordions() {
      this.$refs.accordions?.forEach((button) => {
        if (!this.isExpanded(button)) {
          button.click();
        }
      });
      this.isAllExpanded = true;
    },
    expandAllAccordions() {
      this.$refs.accordions?.forEach((button) => {
        if (this.isExpanded(button)) {
          button.click();
        }
      });
      this.isAllExpanded = false;
    },
    checkAllAccordionsExpanded() {
      this.isAllExpanded = this.$refs.accordions?.every((button) => this.isExpanded(button));
    },
    isExpanded(accordion) {
      return accordion.getAttribute('aria-expanded') === 'true';
    },
    setTabIndexForLinks(panelContent, isOpen) {
      const links = panelContent.querySelectorAll('a');
      links.forEach((link) => {
        if (isOpen) {
          link.removeAttribute('tabindex'); // Make the links focusable when open
        } else {
          link.setAttribute('tabindex', '-1'); // Disable focus when accordion is closed
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
  border-top: 1px solid $color-grey;
  border-bottom: 1px solid $color-grey;
  margin-bottom: -1px;

  .accordion {
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
    font-family: $font-medium;
    padding: $spacing-s 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-black;
    cursor: pointer;

    @include desktop {
      padding: $spacing-m 0;
    }

    &-header {
      &__desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        .title {
          flex: 1;
        }

        .pill {
          visibility: hidden;
          opacity: 0;
          @include transition(0.25s);
        }

        .icon-container {
          @include desktop {
            display: flex;
            flex-direction: row;
            gap: $spacing-s;
            align-items: center;
          }
        }
      }
      &__mobile {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: $spacing-xs;
        .title-icon__container {
          display: flex;
          justify-content: space-between;
          flex: 1;
          align-items: center;
          gap: $spacing-xs;
        }

        .pill__container {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          visibility: hidden;
          opacity: 0;
          display: none;

          .pill {
            margin-top: $spacing-s;
          }
        }
      }
    }

    i.icon {
      background-color: $color-green-light;
      font-size: 18px;
      padding: 12px;
      color: $color-green-darker;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      &:before {
        content: map-get($icons, icon-down);
        font-family: $talkmore-icons;
      }
    }

    &.active {
      i.icon {
        font-style: normal;
        &:before {
          content: map-get($icons, icon-up);
          font-family: $talkmore-icons;
        }
      }

      // for expanded accordion, the pill will be visible for desktop
      .accordion-header__desktop .pill {
        visibility: visible !important;
        opacity: 1 !important;
        display: flex !important;
        @include transition(0.25s);
      }
      // for expanded accordion, the pill will be visible for mobile
      .accordion-header__mobile .pill__container {
        visibility: visible !important;
        opacity: 1 !important;
        display: flex !important;
        @include transition(0.25s);
      }
    }
  }

  .panel {
    max-height: 0;
    @include transition(0.25s);
    overflow: hidden;
  }
}

.accordion-list {
  &.content-container {
    max-width: Calc(($desktop-container / 12) * 8); // sizing into 8 columns width
  }

  &-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacing-s;
    flex-wrap: wrap;
    @include desktop {
      padding-bottom: $spacing-m;
    }
    .accordion-list__title {
      flex-shrink: 0;
      max-width: 100%;
    }
    .accordion-toggle-wrapper {
      justify-content: flex-end;
      @include small-textlink;
      margin: 0;
    }
  }
  &__title {
    margin: 0;
  }

  .accordion-wrapper {
    &:last-child {
      border-bottom: none;
    }
  }
}
.accordion-info {
  padding-bottom: $spacing-s;
  ::v-deep ul {
    @include green-listing;
  }
}
</style>
