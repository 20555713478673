<template>
  <div class="button-container" :class="getSpacing()">
    <div class="button-container__inner">
      <button class="button secondary-btn" :class="cssClass" @click="onRouteBack">
        <!-- <Spinner v-if="isLoading" class="button__spinner" /> -->

        <!-- <ScText v-if="isLoading" tag="span" class="button__text button__loading-text" :field="fields.LoadingText" /> -->
        <ScText tag="span" class="button__text" :field="fields.TextBack" />

        <!-- <Tooltip v-show="fields.TooltipText" :fields="fields" class="button__tooltip" :isOpen="showErrorTooltip" /> -->
      </button>
      <button
        v-if="fields.RouteNext || fields.Events.length !== 0"
        class="button primary-btn"
        :class="[
          { 'button-loading': isLoading, 'button-loading-notext': !fields.LoadingText.value, disabled: isDisabled },
          cssClass,
        ]"
        @click="onRouteNext"
      >
        <Spinner v-if="isLoading" class="button-spinner" />

        <ScText v-if="isLoading" tag="span" class="button__text button__loading-text" :field="fields.LoadingText" />
        <ScText v-if="!isLoading" tag="span" class="button__text" :field="fields.TextNext" />

        <Tooltip v-show="fields.TooltipText" :fields="fields" class="button-tooltip" :isOpen="showErrorTooltip" />
      </button>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Spinner, Tooltip } from '@/sharedComponents';

export default {
  name: 'OverlayButton',
  components: {
    ScText: Text,
    Spinner,
    Tooltip,
  },
  inject: {
    trackEvents: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabled: this.disabled,
      isLoading: this.isLoading,
      showErrorTooltip: 0,
      useSecondaryRoute: false,
      cssClass: this.fields?.CssClass?.value,
    };
  },
  watch: {
    disabled() {
      this.isDisabled = this.disabled;
    },
    loading() {
      this.isLoading = this.loading;
    },
  },
  mounted() {
    this.eventSubscriptions();
  },
  beforeDestroy() {
    if (this.fields.SecondaryRouteToggledBy) this.$root.$off(this.fields.SecondaryRouteToggledBy.fields.Key.value);
    if (this.fields.RouteOnEvent) this.$root.$off(this.fields.RouteOnEvent.fields.Key.value);
    if (this.fields.EnableOnEvent) this.$root.$off(this.fields.EnableOnEvent.fields.Key.value);
    if (this.fields.DisableOnEvent) this.$root.$off(this.fields.DisableOnEvent.fields.Key.value);
  },
  methods: {
    onRouteBack() {
      // this.trackEvents(this);
      const route = this.fields.RouteBack?.url;
      if (route && !route.includes('_datasource')) this.$root.$emit('overlay-route-back', route);
      else this.$root.$emit('overlay-route-back', null);
    },
    onRouteNext() {
      if (this.isLoading) return;

      if (this.isDisabled) {
        this.errorTooltip();
        return;
      }

      // set loading state if we are calling a service/waiting on a event as result of button press
      if (this.fields.RouteOnEvent) {
        this.isLoading = true;
      }

      // emit all events
      this.fields.Events.forEach((ev) => {
        const eventName = ev.fields.Key.value;
        this.$root.$emit(eventName);
      });

      // if the button isn't used to route to next page, just emit a click and be done with it
      if (!this.fields.RouteNext) {
        this.$emit('click');
        return;
      }

      if (this.$store.state.app.useSecondaryRoute || this.useSecondaryRoute) {
        this.$root.$emit('overlay-route', this.fields.SecondaryRoute);
        this.useSecondaryRoute = false;
      } else {
        const route = this.fields.RouteNext?.url;
        if (route && route.includes('_datasource')) this.$root.$emit('overlay-route', this.fields.RouteNext);
        else this.$router.push(route);
      }
    },
    errorTooltip() {
      this.showErrorTooltip++;
      this.$root.$emit('onInvalidateAll');
    },
    getSpacing() {
      const spacingValues = this.fields?.Spacing;
      if (spacingValues) return spacingValues.map((item) => item.fields?.SpacingClass?.value).join(' ');
      return null;
    },
    eventSubscriptions() {
      // if page-change is dependent on an event being fired, subscribe to the event here.
      if (this.fields.RouteOnEvent) {
        this.$root.$on(this.fields.RouteOnEvent.fields.Key.value, () => {
          this.trackEvents(this);
          this.$root.$emit('overlay-route', this.fields.RouteNext);
        });

        // disable loading on error state of event
        this.$root.$on('onError', () => {
          this.isLoading = false;
        });
      }

      if (this.fields.EnableOnEvent) {
        this.isDisabled = true;

        this.$root.$on(this.fields.EnableOnEvent.fields.Key.value, () => {
          this.isDisabled = false;
        });
      }

      if (this.fields.DisableOnEvent) {
        this.$root.$on(this.fields.DisableOnEvent.fields.Key.value, () => {
          this.isDisabled = true;
        });
      }

      if (this.fields.SecondaryRouteToggledBy?.id) {
        this.$root.$on(this.fields.SecondaryRouteToggledBy.fields.Key.value, (data) => {
          this.useSecondaryRoute = data?.toLowerCase().includes(this.fields.SecondaryRouteToggledBy?.id);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: auto;

  &--disabled,
  &:disabled {
    color: #{$color-white};
    background: #{$color-grey-dark};
    cursor: not-allowed;

    &:hover {
      background: #{$color-grey-dark};
      cursor: not-allowed;
      border-color: #{$color-grey-dark};
    }

    &:active {
      background: #{$color-grey-dark};
      border-color: #{$color-grey-dark};
    }

    &:focus {
      background: #{$color-grey-dark};
      border-color: #{$color-grey-dark};
    }
  }
}

.button-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;

  &__inner {
    display: flex;
    gap: 16px 24px;
    flex-wrap: wrap-reverse;
    align-content: flex-end;
    justify-content: center;
    @include desktop {
      gap: 32px;
    }
  }
  .button {
    margin: 0;
    text-align: center;
    min-width: 175px;
  }
}
</style>
