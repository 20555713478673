import { dataFetcher } from '../dataFetcher';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';

const trackingApiOptions = {
  host: config.sitecoreApiHost,
  querystringParams: {
    sc_apikey: config.sitecoreApiKey,
  },
  fetcher: dataFetcher,
};

export function track(events) {
  trackingApi
    // note the events are an array - batching is supported
    .trackEvent(events, trackingApiOptions)
    .then()
    .catch((error) => console.error(error));
}

export function abandonSession() {
  const abandonOptions = {
    action: 'flush',
    ...trackingApiOptions,
  };
  trackingApi
    .trackEvent([], abandonOptions)
    .then()
    .catch((error) => console.error(error));
}
