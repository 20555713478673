<template>
  <div :class="logoPosition" :style="spacing" class="app-logo__container">
    <a href="/" class="app-logo">
      <img class="app-logo__image" :alt="altText" :src="imgSrc" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    imgSrc: {
      type: String,
      default: null,
    },
    altText: {
      type: String,
      default: '',
    },
    spacingTop: {
      type: String,
      default: null,
    },
    spacingBottom: {
      type: String,
      default: null,
    },
    mobileLogoPosition: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    spacing() {
      let styleObject = {};
      if (this.spacingTop) styleObject.marginTop = `${this.spacingTop}px`;
      if (this.spacingBottom) styleObject.marginBottom = `${this.spacingBottom}px`;
      return styleObject;
    },
    logoPosition() {
      return `app-logo--${this.mobileLogoPosition}`;
    },
  },
};
</script>

<style lang="scss">
.app-logo {
  display: flex;

  &__container {
    width: 100%;
    display: flex;
    padding: 0 16px;
    margin: 8px 0;
    @include phone-xs {
      padding: 0 12px;
    }
    &.app-logo {
      // currently only for mobileLogoPositioning
      &--left {
        justify-content: left;
        @include screen-tablet-portrait-up {
          // always centered for desktop
          justify-content: center;
        }
      }
      &--center {
        justify-content: center;
        @include screen-tablet-portrait-up {
          // always centered for desktop
          justify-content: center;
        }
      }
    }
  }

  &__image {
    height: 48px;

    @include screen-tablet-portrait-up {
      height: 56px;
    }
  }
}
</style>
