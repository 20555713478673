<template>
  <div class="digital-security m-t-m m-md-t--clear m-b-m">
    <div class="helping-title__container m-md-b-xs">
      <ScRichText class="digital-security__title text-md-m text-normal text-m" tag="h3" :field="fields.DigitalSecurityTitle" />
    </div>
    <ScText class="digital-security__text m-md-b-m m-b-s text-s text-md-s" tag="p" :field="fields.DigitalSecurityText" />

    <ScText class="digital-security__text text-m bold m-md-b-s m-b-s text-md-m" tag="p" :field="fields.DigitalSecurityHeadline" />

    <div class="digital-security__container">
      <Pill color="blue" :text="fields.DigitalSecurityContainerPillText.value"></Pill>
      <div class="digital-security__heading m-md-b-m m-b-m">
        <ScImage class="digital-security__icon" :media="fields.DigitalSecurityContainerIcon" />
        <ScText class="digital-security__title text-m text-md-m" tag="h4" :field="fields.DigitalSecurityContainerTitle" />
      </div>
      <ScRichText
        class="digital-security__rich m-b-s text-s text-md-s m-md-b-m"
        tag="div"
        :field="fields.DigitalSecurityContainerRichText"
      />
      <ScText
        tag="a"
        tabindex="0"
        class="link digital-security__link"
        @click="openOverlay"
        @keydown.enter.space.prevent="openOverlay"
        :field="fields.DigitalSecurityContainerLinkText"
      />

      <CheckBox class="digital-security__checkbox" :fields="fields" :embedded="true" @change="changed">
        <strong class="digital-security__price">{{ priceText }}</strong>
      </CheckBox>
    </div>
    <BottomSheet ref="digitalSecurityBottomSheet" :closeText="fields.BottomSheetCloseText.value">
      <div v-html="fields.BottomSheetText.value"></div>

      <div class="flex justify-content--center m-t-s digital-security__bottom-sheet-button">
        <ScText
          class="primary-xs-small primary-md-default primary-btn"
          tag="button"
          :field="fields.BottomSheetButtonText"
          @click="toggleDigitalSecuritySelected()"
        />
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { Pill, CheckBox } from '@/sharedComponents';
import BottomSheet from './util/BottomSheet.vue';
import { gtmSharedParams, gtmDigitalSecurityParams } from '@/gtmTracking';
import { gtmEcommerceEvent, gtmModalViewEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'DigitalSecurity',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
    Pill,
    CheckBox,
    BottomSheet,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    // isEditorActive: {
    //   type: Boolean,
    // },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.$store.state.app[this.fields.Key.value],
      price: this.$store.state.config.digitalSecurity.crmItem?.price,
    };
  },
  computed: {
    priceText() {
      return this.price + this.$t('price-denote') + this.$t('price-postfix');
    },
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'checkout_security',
      ...gtmSharedParams,
    });
  },
  methods: {
    changed(e) {
      this.value = e.target.checked;
      const event = this.value ? 'add_to_cart' : 'remove_from_cart';
      gtmEcommerceEvent({ event, items: [gtmDigitalSecurityParams()], gtmSharedParams: gtmSharedParams() });
    },
    openOverlay() {
      this.$refs.digitalSecurityBottomSheet.open();

      const modal_name = 'Digital Trygghed';
      gtmModalViewEvent({ modal_name, gtmSharedParams: gtmSharedParams() });
    },
    closeOverlay() {
      this.$refs.digitalSecurityBottomSheet.close();
    },
    toggleDigitalSecuritySelected() {
      if (this.value) {
        this.closeOverlay();
        return;
      }

      const checkbox = document.querySelector(`#${this.fields.Key.value}`);
      checkbox.click();
      this.closeOverlay();
    },
  },
};
</script>

<style lang="scss" scoped>
.digital-security {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    border-radius: 16px;
    background: var(--blue-light, #daf0fa);
    margin-top: 15px; //for making space for the absolute-positioned pill
    @include screen-tablet-portrait-up {
      padding: 32px;
    }
    .pill {
      position: absolute;
      top: -15px;
      right: 12px;
      &__text {
        font-family: $font-normal;
      }
    }
  }

  &__heading {
    display: inline-flex;
    gap: 16px;
    align-items: center;

    img {
      max-width: 42px;
      max-height: 42px;
      height: auto;
      width: auto;
      min-width: 42px;
    }
  }

  ::v-deep &__rich {
    ol,
    ul {
      padding-left: $spacing-s;
      //   @include screen-tablet-portrait-up {
      //     padding-left: 32px;
      //   }
    }
  }

  &__link.link {
    width: fit-content;
    @include button-text-small;
    @include screen-tablet-portrait-up {
      @include button-text-default;
      margin-bottom: 8px;
    }
  }
  //adding table due to sitecore content including a table for this component

  &__bottom-sheet-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 24px;

    @include desktop {
      padding-bottom: 32px;
    }
  }
  &__checkbox {
    label {
      @include button-text-small;
      @include screen-tablet-portrait-up {
        @include button-text-default;
      }
    }
  }

  &__price {
    font-family: $font-bold;
    display: block;
  }
  ::v-deep table {
    margin-bottom: 104px;
    width: 100%;
  }
}
</style>
