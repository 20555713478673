<template>
  <div class="basket">
    <div
      v-if="canShowBasket"
      class="basket-content"
      :class="{ empty: isBasketEmpty, expanded: visible }"
      :style="height"
      ref="basketOverlay"
    >
      <div class="desktop-wide flex basket-content-container" id="basket-content">
        <div class="basket-content-container__head">
          <Logo :imgSrc="imageLogo" altText="Talkmore Privat" class="basket-content__head-logo" />
          <button class="button-textlink button-small transparent-btn" @click="hideBasket">
            <span class="main text text-m text-normal text-md-m">{{ $t('close') }} </span>
          </button>
        </div>
        <div class="basket-content-container__bottom">
          <div class="basket-content__head p-b-s p-md-b-m">
            <h4 class="text-l text-md-l bold">{{ $t('cart-title') }}</h4>
            <img :src="cartImage" />
          </div>

          <SummaryContent v-if="!isBasketEmpty" />

          <div class="basket-content__item-product--empty">
            <img :src="cartImage" />
            <h4 class="text-l bold title-md-m">{{ $t('cart-empty') }}</h4>
          </div>

          <p v-if="showRecruitmentText" class="basket-content__recruitment-text text-s text-md-s">{{ recruitmentText }}</p>

          <div class="basket-content__button-container">
            <button v-if="!isLastProductRemoved" class="primary-btn primary-xs-small" @click="onRoute">
              {{ $t('cart-next') }}
            </button>
            <button class="secondary-btn secondary-xs-small" @click="hideBasket">{{ basketCloseButtonText }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showStickyBasket" :class="[{ 'animation-bounce': animateBasket }]" class="basket-expander" @click="visible = true">
      <div class="basket-expander__content-container">
        <div class="basket-expander__open">
          <i class="icon-up" />
        </div>
        <div class="basket-expander__content">
          <div class="basket-expander__content-text-container">
            <span class="basket-expander__content-text text-m text-md-m">
              <!-- in family flow, show the title of the first product -->
              <SummaryProductTitle v-if="showProductTitle" :productItem="getBasket.items[0]" />
              <span v-else>{{ getStickyBasketText }}</span>
            </span>
            <span class="basket-expander__content-price" :class="{ discount }">
              <span class="basket-expander__content-price__actual">
                <span class="text-m text-md-l">{{ getBasket.total }}</span
                ><span class="period text-md-m">{{ denotePostfix }}</span>
              </span>
              <span v-show="discount" class="basket-expander__content-price__original text-xs text-md-s"
                >{{ getBasket.totalFull }}{{ denotePostfix }}</span
              >
            </span>
          </div>
          <button
            @click="visible = true"
            class="basket-expander__content-button primary-md-default primary-btn primary-xs-small m-b--clear"
          >
            {{ $t('go-to-basket') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toggleBodyScrollLock, sleep, focusTrapInsideElement } from '../../../Talkmore.Web.Vue.Shared/src/utils';
import cartImage from '@/assets/img/cart-blue-circle.svg';
import { Logo } from '@/sharedComponents';
import SummaryContent from './util/SummaryContent.vue';
import SummaryProductTitle from './util/SummaryProductTitle.vue';
import logoImage from '@/assets/img/logo.svg';
import { gtmSharedParams, getGtmItems } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'Basket',
  components: {
    Logo,
    SummaryContent,
    SummaryProductTitle,
  },
  provide() {
    return {
      hasModalAncestor: true, // inform BottomSheet that scroll lock should be NOT be toggled
    };
  },
  data() {
    return {
      visible: false,
      height: null,
      showBasket: false,
      cartImage: cartImage,
      imageLogo: logoImage,
      animateBasket: false,
      hasFocusTrap: false,
      denotePostfix: this.$t('price-denote') + this.$t('price-postfix'),
    };
  },
  computed: {
    ...mapGetters({
      getBasket: 'basket/getBasket',
      usersCount: 'basket/getUsersCount',
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
    }),
    basketCloseButtonText() {
      return this.$store.state.config?.basketCloseButtonText;
    },
    hasStickyBasket() {
      return this.$store.state.config?.showStickyBasket ?? false;
    },
    currentPage() {
      let path = this.$route.path;
      if (path.endsWith('/')) path = path.slice(0, -1);
      return path.split('/').pop().toLowerCase();
    },
    shouldShowBasket() {
      return this.$store.state.config.basketPages?.filter((page) => page.name.toLowerCase() === this.currentPage).length !== 0;
    },
    canShowBasket() {
      return this.shouldShowBasket && this.showBasket;
    },
    // needed because we want to deep-watch items inside getBasket
    basketItems() {
      return this.getBasket?.items ?? null;
    },
    isBasketEmpty() {
      return this.basketItems === null || this.basketItems?.length === 0;
    },
    showStickyBasket() {
      return this.hasStickyBasket && this.shouldShowBasket && !this.hasOnlyDigitalSecurity && this.basketItems?.length > 0;
    },
    hasOnlyDigitalSecurity() {
      return this.isBasketEmpty && this.$store.state.app?.digitalSecurity === true;
    },
    discount() {
      return this.getBasket?.totalFull > this.getBasket?.total;
    },
    isLastProductRemoved() {
      return this.$store.state.basket?.isLastProductRemoved ?? this.isBasketEmpty;
    },
    getStickyBasketText() {
      if (this.isBasketEmpty) return `0 ${this.$t('subscriptions')}`;
      else
        return `${this.basketItems.length} ${this.basketItems.length > 1 ? this.$t('subscriptions') : this.$t('subscription')}`;
    },
    showProductTitle() {
      return this.usersCount && this.getBasket.items?.length > 0;
    },
    recruiterName() {
      return this.$store.state.app?.recruiterName ?? '';
    },
    recruitmentText() {
      return this.$store.state.config?.recruitmentCampaign?.basketText?.replace('{recruiterName}', this.recruiterName);
    },
    showRecruitmentText() {
      return this.isRecruitmentOrderType && !!this.recruitmentText && !!this.recruiterName && !this.isBasketEmpty;
    },
  },
  watch: {
    basketItems(newVal) {
      if (newVal) this.showBasket = true;
      else this.showBasket = false;

      this.animateBasket = true;
      setTimeout(() => {
        this.animateBasket = false;
      }, 1500);
    },
    visible(newVal) {
      toggleBodyScrollLock(newVal);
      if (newVal) {
        const items = getGtmItems();
        const event = 'view_cart';
        const value = this.getBasket.total;
        gtmEcommerceEvent({ event, items, value, gtmSharedParams: gtmSharedParams() });
      }
    },
    showStickyBasket(newVal) {
      if (newVal === true) this.$store.dispatch('app/addItem', { key: 'isStickyBasketVisible', value: true });
      else this.$store.dispatch('app/deleteItem', 'isStickyBasketVisible');
    },
  },
  mounted() {
    if (this.basketItems) {
      this.showBasket = true;
    }
    this.$root.$on('onShowBasket', this.onShowBasketHandler);
    this.$root.$on('openModal', this.removeFocusTrap);
    this.$root.$on('closeModal', this.addFocusTrap);
  },
  beforeUpdate() {
    this.toggleBasketFocusTrap();
  },
  updated() {
    this.toggleBasketFocusTrap();
  },
  beforeDestroy() {
    this.$root.$off('onShowBasket', this.onShowBasketHandler);
    this.$root.$off('openModal');
    this.$root.$off('closeModal');
  },
  methods: {
    onRoute() {
      // hide basket before redirecting to next step
      this.visible = false;
      this.$root.$emit('removeProductInstantly');

      this.$router.push(this.$store.state.config.basketRoute);
    },
    hideBasket() {
      this.visible = false;
      this.$root.$emit('removeProductInstantly');
    },
    toggleBasketFocusTrap() {
      if (this.$refs.basketOverlay) {
        if (this.hasFocusTrap && !this.visible) {
          this.removeFocusTrap();
        } else if (!this.hasFocusTrap && this.visible) {
          this.addFocusTrap();
        }
      }
    },
    removeFocusTrap() {
      if (!this.hasFocusTrap) return;
      focusTrapInsideElement(this.$refs.basketOverlay, false);
      this.hasFocusTrap = false;
    },
    addFocusTrap() {
      if (this.hasFocusTrap) return;
      focusTrapInsideElement(this.$refs.basketOverlay, true, this.hideBasket);
      this.hasFocusTrap = true;
    },
    async onShowBasketHandler() {
      if (this.hasStickyBasket) return; // sticky basket is shown instead
      // we want a slight delay before showing the basket
      await sleep(1000);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.basket {
  &-expander {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 24px 16px 32px;
    display: flex;
    border-top: 1px solid #{$color-grey};
    box-shadow: 0px -1px 14px rgb(0 0 0 / 10%);
    background: #{$color-white};
    align-items: center;
    z-index: 2;
    cursor: pointer;

    @include phone-xs {
      padding: 24px 12px;
    }

    @include desktop {
      justify-content: center;
      padding: #{$spacing-m} #{$spacing-l};
    }
    &__open {
      justify-content: center;
      display: none;
      @include screen-tablet-portrait-up {
        display: flex;
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
      }
    }

    &__button {
      width: fit-content;
      right: 1em;
      position: fixed;
    }

    &__content {
      display: flex;
      flex-direction: row;
      gap: 4px;
      flex: 1;
      justify-content: space-between;
      @include transition(0.25s);

      &-text-container {
        display: flex;
        flex-direction: column;
        @include screen-tablet-portrait-up {
          gap: 8px 0;
        }
      }

      &__open {
        display: flex;
        justify-content: center;
      }

      &-price {
        strong {
          @include screen-tablet-portrait-up {
            font-family: $font-bold;
          }
        }

        &__actual {
          font-family: $font-bold;
          @include screen-tablet-portrait-up {
            .period {
              font-family: $font-normal;
            }
          }
        }

        &__original {
          margin-left: #{$spacing-xs};
        }

        &.discount {
          .basket-expander__content-price__actual {
            color: #{$color-green-darker};
          }
          .basket-expander__content-price__original {
            text-decoration: line-through;
          }
        }
      }

      &-text {
        @include desktop {
          font-family: $font-bold;
        }
      }

      button {
        padding-left: 16px;
        padding-right: 16px;
        align-self: center;
        @include screen-tablet-portrait-up {
          padding-left: 36px;
          padding-right: 36px;
        }
      }
    }

    &__content-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include desktop {
        width: 1000px; // 10 col width in pixels 12 cols = 1200px
      }
    }

    &__icon-container {
      display: none;

      @include desktop {
        width: 48px;
        height: 48px;
        background: linear-gradient(327.41deg, #67da2e -10.55%, #91ee65 83.8%), #{$color-green};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
      }
    }
  }

  &-content {
    @include transition(0.65s);
    position: fixed;
    bottom: 0;
    height: 0;
    visibility: hidden;
    width: 100%;
    overflow: hidden;
    background: #{$color-white};
    z-index: 3;
    padding: 0 12px 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &-container {
      padding-top: 24px;
      padding-block: 24px;
      &__head {
        display: flex;
        width: 100%;
        // flex: 1;
        @include screen-tablet-portrait-up {
          margin-bottom: $spacing-xl;
        }

        button {
          padding-right: 0;
          margin: 0;
        }
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @include screen-tablet-portrait-up {
          flex: 1;
          justify-content: center;
          margin-bottom: 60px;
        }
      }
    }

    .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @include screen-tablet-portrait-up {
        flex: 1;
      }
    }

    &__close {
      font-size: 17.5px;
      color: #{$color-black};
      align-self: flex-end;
      @include desktop {
        padding: 16px 24px;
        margin-right: -24px;
        font-size: 19.5px;
        margin-top: -16px;
      }

      &:hover {
        cursor: pointer;
      }

      &-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
      }
    }

    &__head {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      img {
        height: 32px;
        width: 32px;
      }
      &-logo {
        margin-bottom: 24px;
        width: 100%;
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 16px;
        padding: 0;
      }
    }

    &__item {
      &-product {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        &--removed {
          display: none;
        }
        &--empty {
          display: none;
          margin-top: 88px;

          img {
            height: auto;
            width: 80px;
            max-height: 80px;
          }
        }
      }
    }

    &__button {
      &-container {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
      }
    }

    &__recruitment-text {
      margin: $spacing-m 0;
      color: $color-green-darker;
    }

    &.expanded {
      height: 100%;
      visibility: visible;
      @include transition(0.5s);

      @include desktop {
        box-shadow: 0px -1px 14px rgb(0 0 0 / 10%);
      }
    }

    &.empty {
      .basket-content__head {
        display: none;
      }
      .basket-content__item-product--empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        align-items: center;
      }
    }
  }
}

// animation of basket change
.animation-bounce {
  @include screen-tablet-portrait-up {
    -webkit-animation: basket-bounce 1.5s ease-in-out both;
    animation: basket-bounce 1.5s ease-in-out both;
  }
  -webkit-animation: basket-bounce-mobile 1.5s ease-in-out both;
  animation: basket-bounce-mobile 1.5s ease-in-out both;
  transform-origin: bottom;
}

@keyframes basket-bounce {
  0% {
    padding-bottom: 24px;
  }
  15% {
    padding-bottom: 37px;
  }
  18% {
    padding-bottom: 38px;
  }
  30% {
    padding-bottom: 33px;
  }
  35% {
    padding-bottom: 33px;
  }
  45% {
    padding-bottom: 36px;
  }
  70% {
    padding-bottom: 24px;
  }
}

// two different mixins due to different bottom-paddings for sticky-basket.
@keyframes basket-bounce-mobile {
  0% {
    padding-bottom: 32px;
  }
  15% {
    padding-bottom: 37px;
  }
  18% {
    padding-bottom: 38px;
  }
  30% {
    padding-bottom: 33px;
  }
  35% {
    padding-bottom: 33px;
  }
  45% {
    padding-bottom: 36px;
  }
  70% {
    padding-bottom: 32px;
  }
}
</style>
