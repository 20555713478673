<template>
  <div class="sim-next-steps desktop-wide">
    <ScText
      tag="h3"
      :field="!hasEsim ? fields.SimNextStepsTitleDefault : fields.SimNextStepsTitleEsim"
      class="sim-next-steps__title title-xs"
    />
    <ScText
      tag="p"
      :field="!hasEsim ? fields.SimNextStepsDescriptionDefault : fields.SimNextStepsDescriptionEsim"
      class="sim-next-steps__description"
    />
    <div class="sim-next-steps__links">
      <a
        v-if="showMarketplace('SimNextStepsLinkAppStore', 'SimNextStepsImageAppStore')"
        :href="fields.SimNextStepsLinkAppStore.value.href"
        ><ScImage :media="fields.SimNextStepsImageAppStore"
      /></a>
      <a
        v-if="showMarketplace('SimNextStepsLinkGooglePlay', 'SimNextStepsImageGooglePlay')"
        :href="fields.SimNextStepsLinkGooglePlay.value.href"
        ><ScImage :media="fields.SimNextStepsImageGooglePlay"
      /></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'SimNextSteps',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      getItems: 'basket/getItems',
    }),
    hasEsim() {
      let hasEsim = false;

      this.getItems.forEach((item) => {
        if (item?.user?.simType === 'esim') hasEsim = true;
      });

      return hasEsim;
    },
  },
  methods: {
    showMarketplace(linkField, imageField) {
      return this.fields[linkField].value.href !== '' && this.fields[imageField].value.src !== undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.sim-next-steps {
  margin-top: 36px;
  border: 1px solid $color-green-tint-light;
  border-radius: 16px;
  background-color: $color-green-lighter;
  padding: 28px $spacing-m;

  @include desktop {
    margin-top: $spacing-5xl;
    padding: 47px $spacing-l;
  }

  &__title,
  &__description {
    text-align: center;
    max-width: 388px;
    margin-right: auto;
    margin-left: auto;
  }

  &__links {
    margin-top: $spacing-m;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    a {
      display: inline-flex;

      img {
        height: auto;
        width: 100%;
        max-width: 181px;
      }
    }
  }
}
</style>
