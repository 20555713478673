<template>
  <div class="dark-overlay" :class="[{ visible: isVisible }]" @click="$emit('toggle')" />
</template>

<script>
export default {
  name: 'DarkOverlay',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.dark-overlay {
  background-color: #{$color-black};
  height: 100%;
  width: 100%;
  position: absolute;
  position: fixed;
  top: 0;
  opacity: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;

  &.visible {
    opacity: 0.3;
    visibility: visible;
    z-index: 1;
  }
}
</style>
