<template>
  <!-- TODO: Check if "visible & computedVisible" works -->
  <div v-show="visible & computedVisible" class="rich-text desktop-wide" :class="fields.ClassList.value">
    <ScRichText tag="p" class="rich-text-paragraph" :field="fields.Text" />
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'RichText',
  components: {
    ScRichText: RichText,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    // TODO: Check if this works with the other visible property
    computedVisible() {
      if (this.fields.HideWhenStoreValueDefined?.value) {
        return !Object.hasOwn(this.$store.state.app, this.fields.HideWhenStoreValueDefined.value);
      }
      return true;
    },
  },
  created() {
    if (this.fields.Text?.value && !this.isEditorActive) {
      // eslint-disable-next-line vue/no-mutating-props
      this.fields.Text.value = this.fields.Text.value
        .replace('{ownerStreet}', this.$store.state.app.street || '')
        .replace('{ownerPostal}', this.$store.state.app.postal || '')
        .replace('{ownerCity}', this.$store.state.app.city || '');
    }
  },
  mounted() {
    if (this.fields.ToggledBy && !this.isEditorActive) {
      this.visible = false;

      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data?.toLowerCase().includes(this.fields.ToggledBy.id);
      });
    }

    if (this.fields.ToggleByStoreValue?.value) {
      console.log(`RichText: Setting visible to '${!this.$store.state.app[this.fields.ToggleByStoreValue.value]}'`);
      this.visible = !this.$store.state.app[this.fields.ToggleByStoreValue.value];
    }
  },
  beforeDestroy() {
    if (this.fields.ToggledBy) this.$root.$off(this.fields.ToggledBy.fields.Key.value);
  },
};
</script>

<style lang="scss">
.rich-text {
  font-size: 17px;

  @include desktop {
    font-size: 19px;
  }
}
</style>
