<template>
  <div class="underline"></div>
</template>

<script>
export default {
  name: 'Underline',
};
</script>

<style lang="scss">
.underline {
  max-width: 564px;
  width: 100%;
  height: 1px;
  background: #{$color-grey};
  margin: 16px 0;
}
</style>
