<template>
  <div class="receipt">
    <Header :fields="fields" />
    <RichText :fields="fields" />
  </div>
</template>

<script>
import Header from './Header';
import RichText from './RichText';

export default {
  name: 'Receipt',
  components: {
    Header,
    RichText,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  mounted() {
    if (!this.fields?.Text?.value) return;

    // eslint-disable-next-line vue/no-mutating-props
    this.fields.Text.value = this.fields.Text.value
      .replace('{email}', this.$store.state.app.email || '')
      .replace('{orderId}', this.$store.state.app.orderId || '');
  },
};
</script>
