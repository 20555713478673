<template>
  <!-- we use v-show instead of v-if to prevent ssr hydration error -->
  <div v-show="visible" class="link-text" :class="{ 'link-text--negativ': fields.FollowingHeader.value }">
    <ScText tag="a" tabindex="0" class="link" @click="onRoute" @keydown.enter="onRoute" :field="fields.LinkText" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'LinkText',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    visible() {
      if (this.fields.ToggleByStoreValue?.value) {
        return !this.$store.state.app[this.fields.ToggleByStoreValue.value];
      }

      return true;
    },
  },
  methods: {
    onRoute() {
      // emit all events
      this.fields.Events.forEach((ev) => {
        const eventName = ev.fields.Key.value;
        this.$root.$emit(eventName);
      });

      if (this.fields.RouteExternal?.url) window.location.href = this.fields.RouteExternal.url;
      else this.$router.push(this.fields.Route.url);
    },
  },
};
</script>

<style lang="scss">
.link-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  &--negativ {
    margin-top: -32px;
  }
}
</style>
