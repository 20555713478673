<template>
  <SubscriptionList v-if="visible" :fields="fields"></SubscriptionList>
</template>

<script>
import SubscriptionList from './SubscriptionList.vue';

export default {
  name: 'SubscriptionListTrumf',
  components: {
    SubscriptionList,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    visible() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }

      return true;
    },
  },
};
</script>
