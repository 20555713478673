import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { defaultPartnerOidcSettings } from '@/oidc';
// NOTE: If you want to customize how tokens are stored you can use WebStorageStateStore from oidc-client-ts (see bellow)
// Default storage is window.localStorage
// import { WebStorageStateStore } from 'oidc-client-ts';

export function getPartnerOidcStoreModule(oidcSettings = defaultPartnerOidcSettings) {
  return vuexOidcCreateStoreModule(
    // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
    // spread your oidcSettings and define a userStore of your choice
    // {
    //   ...oidcSettings,
    //   userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    // },
    oidcSettings,
    // Optional OIDC store settings
    {
      namespaced: true,
      dispatchEventsOnWindow: true,
      // automaticSilentRenew: false,
      // automaticSilentSignin: false,
    },
    // Optional OIDC event listeners
    // {
    //   userLoaded: (user) => console.log('OIDC user is loaded:', user),
    //   userUnloaded: () => console.log('OIDC user is unloaded'),
    //   accessTokenExpiring: () => {
    //     console.log('Access token will expire');
    //   },
    //   accessTokenExpired: () => {
    //     console.log('Access token did expire');
    //   },
    //   silentRenewError: () => console.log('OIDC user is unloaded'),
    //   userSignedOut: () => console.log('OIDC user is signed out'),
    //   oidcError: (payload) => {
    //     console.log('OIDC error', payload);
    //     // this.$root.$emit('onOidcError', payload);
    //   },
    //   automaticSilentRenewError: (payload) => console.log('OIDC automaticSilentRenewError', payload),
    // },
  );
}
