<template>
  <div class="info-box desktop-wide">
    <div class="info-box__image">
      <ScImage class="info-box__icon" :media="fields.InfoBoxImage" />
    </div>

    <div class="info-box__wrapper">
      <ScText tag="h6" class="info-box__title" :field="fields.InfoBoxTitle" />
      <ScText tag="p" class="info-box__text" :field="fields.InfoBoxText" />
    </div>
  </div>
</template>

<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'InfoBox',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.info-box {
  display: flex;
  flex-direction: row;
  background: #{$color-yellow-lighter};
  border-radius: 16px;
  padding: 16px 24px 16px 16px;
  margin: 16px 0 24px 0px;

  &__image {
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 39px;
    height: 36px;
  }

  &__title {
    font-size: 18px;
  }

  &__text {
    font-size: 18px;
  }
}
</style>
