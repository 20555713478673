import { isRequired, isEmail, isBirthday, validateChecksum } from './validators';

export const subscribedValidations = {
  valid: false,
  queue: {},
};

export function subscribeToValidation(validations, ref) {
  const key = `${ref.$vnode.tag}-${ref.$vnode.key}`;
  if (key === undefined) return;

  validations.forEach((validation) => {
    if (!subscribedValidations.queue[key]) subscribedValidations.queue[key] = [];
    subscribedValidations.queue[key].push({ validation, ref });
  });
}

export function unsubscribeFromValidation(validations, ref) {
  // TODO: validations parameter can be removed?
  const key = `${ref.$vnode.tag}-${ref.$vnode.key}`;
  if (key === undefined) return;
  delete subscribedValidations.queue[key];
}

export function clearValidations() {
  subscribedValidations.queue = {};
  subscribedValidations.valid = false;
}

export function validate(ref, validationError) {
  if (!Object.keys(subscribedValidations.queue).length) return;

  let valids = [];

  // if there is a required field and it failed, the entire validation fails
  let requiredFailed = false;

  Object.keys(subscribedValidations.queue).forEach((key) => {
    let validations = subscribedValidations.queue[key];
    let isEmpty = false;

    // only add required fields to validation list
    let required = validations.filter((f) => f.validation.fields.Key.value === 'isRequired');

    // keep track of if the validation list contains an "isEmpty" validation for special logic regarding that validation
    let containIsEmpty = validations.filter((f) => f.validation.fields.Key.value === 'isEmpty');

    let requiredIndex = validations.findIndex((obj) => obj.validation.fields.Key.value === 'isRequired');

    // "sort" the array so required always comes first
    if (requiredIndex > -1) {
      let [requiredObj] = validations.splice(requiredIndex, 1); // Remove the isRequired-object
      validations.unshift(requiredObj); // Add the isRequired-object to the start of the array
    }

    validations.forEach((validation) => {
      // attempt get value from vue component. Must implement .getValue()
      const value = validation.ref.getValue();
      const validationType = validation.validation.fields.Key.value;
      const currentIsEmpty = validationType === 'isEmpty';
      const isHidden = typeof validation.ref.hidden === 'function' ? validation.ref.hidden() : false;
      let valid = false;

      switch (validationType) {
        case 'isRequired': {
          // bit of a special case, since this validation blocks everything
          valid = isRequired(value);
          requiredFailed = !valid && !isHidden;
          break;
        }
        case 'isEmpty': {
          // special case where a field can be valid both when it is empty or when it passes the rest of it's validations
          valid = !value;
          isEmpty = valid;
          break;
        }
        case 'isEmail': {
          valid = isEmail(value);
          break;
        }
        case 'isBirthday': {
          valid = isBirthday(value);
          break;
        }
        case 'isChecksumValid': {
          valid = validateChecksum(value);
          break;
        }
        default: {
          // by default we are taking the regex pattern from the validation in sitecore
          const regex = validation.validation.fields.RegexPattern?.value;
          const pattern = new RegExp(regex);
          valid = String(value).match(pattern);
          break;
        }
      }

      if (isHidden) valid = true;

      // regardless of whether a field is required, try to notify vue component of the validation result
      // must implement .validated(bool, obj) (optional)
      if (
        typeof validation.ref.validated === 'function' && // must contain function 'validated'
        // reference must match the validations reference - or if it's a total invalidation (clicked disabled button) invalidate all
        ((ref && ref.$vnode.tag === validation.ref.$vnode.tag) || validationError) &&
        ((currentIsEmpty && valid) || !currentIsEmpty) // if it's empty, we only tell the component if it's valid (empty)
      ) {
        validation.ref.validated(valid, validation.validation, validationError);
      }

      // add result to validation list if it's required, or the list of evaluations contains an "isEmpty" - but current evalution is NOT "isEmpty"
      if (required.length || (containIsEmpty.length && !currentIsEmpty)) {
        valids.push(valid);
      }
    });

    // if an isEmpty validation has been hit in the list of validations and it WAS empty, validate the whole component to true
    if (isEmpty) {
      valids = [true];
    }
  });

  // validation passed if there aren't any failed validations in the validation list, and no required validations failed
  subscribedValidations.valid = valids.length > 0 && valids.filter((f) => !f).length === 0 && !requiredFailed;

  return subscribedValidations;
}
