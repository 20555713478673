<template>
  <div v-show="visible" class="input-field desktop-narrow" :class="fields.ClassList.value">
    <div class="input-field__label">
      <ScText :for="fields.Key.value" tag="label" :field="fields.LabelText" :class="{ invalid: errorMessage || requiredError }" />

      <Tooltip v-show="fields.TooltipText.value && !isEditorActive" :fields="fields" />

      <ScText tag="p" :field="fields.FormatText" class="input-field__label-format small" />
    </div>

    <div v-show="isEditorActive" class="experience-editor">
      <a @click="editTooltip = !editTooltip">Create/Edit tooltip (only visible in exp-editor)</a>

      <div v-show="editTooltip || fields.TooltipText.value">
        <ScText tag="h3" :field="fields.TooltipTitle" />
        <ScText tag="p" :field="fields.TooltipText" />
      </div>
    </div>

    <div class="input-field__content">
      <input
        :type="inputType"
        :inputmode="inputMode"
        :autocapitalize="autoCapitalize"
        autocorrect="off"
        class="input-field__content-input"
        :id="fields.Key.value"
        :name="fields.Key.value"
        :placeholder="fields.Placeholder.value"
        :class="{ invalid: errorMessage || requiredError }"
        v-model="inputValue"
        @keyup="dateFormatter($event)"
        @blur="onBlur()"
        @change="$emit('change', $event)"
        @keypress.enter="$emit('keypressEnter')"
      />
      <slot></slot>
    </div>

    <div class="input-field__error" v-if="errorMessage" v-html="errorMessage" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import Tooltip from './util/Tooltip';

export default {
  name: 'InputField',
  components: {
    ScText: Text,
    Tooltip,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
    onEmitValue: {
      type: Function,
    },
    addToValidation: {
      type: Function,
    },
    removeFromValidation: {
      type: Function,
    },
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      inputValue: this.$store.state.app[this.fields.Key.value],
      errorMessage: null,
      requiredError: false,
      editTooltip: false,
      visible: true,
    };
  },
  computed: {
    inputMode() {
      const type = this.fields.Type?.fields.Key.value;

      switch (type) {
        case 'email':
          return 'email';

        case 'phonenumber':
          return 'tel';

        case 'number':
        case 'date':
          return 'numeric';

        case 'text':
        default:
          return 'text';
      }
    },
    autoCapitalize() {
      const type = this.fields.Type?.fields.Key.value;

      switch (type) {
        case 'text':
          return 'words';

        case 'number':
        case 'email':
        case 'phonenumber':
        case 'date':
        default:
          return 'none';
      }
    },
    inputType() {
      const type = this.fields.Type?.fields.Key.value;

      switch (type) {
        case 'email':
          return 'email';

        case 'number':
          return 'number';

        case 'phonenumber':
          return 'tel';

        case 'date':
        case 'text':
        default:
          return 'text';
      }
    },
    showWhenStoreValueDefinedValue() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }
      return undefined;
    },
  },
  watch: {
    showWhenStoreValueDefinedValue() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        this.visible = this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }
    },
    errorMessage(error) {
      // Track displaying validation errors in AppRoot
      if (error) this.$root.$emit('onErrorMessage', error);
    },
  },
  beforeDestroy() {
    this.removeFromValidation(this);
    this.$root.$off('onSave');
  },
  mounted() {
    this.addToValidation(this);

    this.$root.$on('onSave', () => {
      this.onSaveEvent(this.fields.Key.value, this.inputValue, this.$options.name);
    });

    if (this.fields.ToggledBy && !this.isEditorActive) {
      this.visible = false;

      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data?.toLowerCase().includes(this.fields.ToggledBy.id);
        if (!this.visible) {
          this.inputValue = null;
          this.errorMessage = null;
          this.requiredError = false;
        }
      });
    }

    if (this.fields.ToggleByStoreValue?.value) {
      this.visible = !this.$store.state.app[this.fields.ToggleByStoreValue.value];
    }

    if (this.fields.ShowWhenStoreValueDefined?.value) {
      this.visible = this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
    }

    this.$nextTick(() => {
      // validate so that OverlayButton can be enabled if the input is pre-filled
      if (this.fields.Validations) this.$root.$emit('onValidate', this);
    });
  },
  methods: {
    onBlur() {
      // skip the event entirely if it is set to only fire on change and not input (and there isn't a error message)
      if (!this.errorMessage && this.fields.NoInputEvent.value) return;

      if (this.fields.Validations) this.$root.$emit('onValidate', this);
      this.onEmitValue(this, this.inputValue);
    },
    // used by Talkmore.Web.Vue.Shared/validate
    getValue() {
      return this.inputValue;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    hidden() {
      return !this.visible;
    },
    // used by Talkmore.Web.Vue.Shared/validate
    // eslint-disable-next-line vue/no-unused-properties
    validated(valid, validation, validationError) {
      // set error message and red borders if invalid and text has length or is dirty or it's a disabled button press
      // || this.dirty
      if (!valid && (this.getValue()?.length || validationError)) {
        this.errorMessage = validation.fields.ErrorText.value;
        this.requiredError = true;
      } else {
        this.errorMessage = null;
        this.requiredError = false;
      }
    },
    dateFormatter(event) {
      if (!event) return;

      // we only want to format dates
      if (this.fields.Type?.fields.Key.value !== 'date') return;

      const value = this.inputValue;
      const length = value?.length;

      if (!value) return;

      if ((event.code === 'Backspace' || event.key === 'Backspace') && (length === 2 || length === 5)) {
        this.inputValue = value.substring(0, length - 1);
      } else if (event.code === 'Period' || event.key === '.' || event.keyCode === 229) {
        this.inputValue = value.substr(0, length - 1);
      } else if (length === 2 || length === 5) {
        this.inputValue = `${value}.`;
      }
    },
  },
};
</script>
