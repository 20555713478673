<template>
  <!-- we use v-show instead of v-if to prevent ssr hydration error -->
  <div v-show="visible || isEditorActive" class="heading-with-text desktop-narrow">
    <ScText tag="h2" class="heading-with-text__title" :field="fields.HeadingWithTextTitle" />
    <ScText tag="span" class="heading-with-text__text" :field="fields.HeadingWithTextText" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'HeadingWithText',
  components: {
    ScText: Text,
  },
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    visible() {
      if (this.fields.ShowWhenStoreValueDefined?.value) {
        return this.$store.state.app[this.fields.ShowWhenStoreValueDefined.value];
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
.heading-with-text {
  text-align: left;
  margin-bottom: 16px;
  align-self: auto;

  // @include desktop {
  //   align-self: start;
  // }

  &__title {
    font-family: 'CircularProBold', Helvetica, Arial, sans-serif;
    font-size: 17.5px;

    @include desktop {
      font-size: 19.5px;
    }
  }

  &__text {
    font-size: 15px;

    @include desktop {
      font-size: 18px;
    }
  }
}
</style>
