import Vue from 'vue';

const state = {
  completedSteps: [],
  basePath: '',
};

const recruitmentKeys = ['isRecruitmentFlowStarted', 'recruiterName', 'recruitmentCode'];

const mutations = {
  ADD_ITEM(state, { key, value }) {
    Vue.set(state, key, value);
  },
  DELETE_ITEM(state, key) {
    Vue.delete(state, key);
  },
  addCompletedStep(state, step) {
    state.completedSteps.push(step);
  },
  removeCompletedSteps(state, stepsToRemove) {
    if (!state.completedSteps) return;
    state.completedSteps = state.completedSteps.filter(
      (completedStep) => !stepsToRemove.some((stepToRemove) => stepToRemove.name === completedStep.name),
    );
  },
};

const actions = {
  addItem({ commit }, { key, value }) {
    commit('ADD_ITEM', { key, value });
  },
  deleteItem({ commit }, key) {
    commit('DELETE_ITEM', key);
  },
  clearState({ commit, state }) {
    // eslint-disable-next-line no-unused-vars
    Object.entries(state).forEach(([key, value]) => {
      // we want to keep the config id that we got from localStorage
      if (key === 'configId') return;
      // we want to keep the recruitment values for switching between recruitment flows
      if (recruitmentKeys.includes(key)) return;
      commit('DELETE_ITEM', key);
    });

    console.log('Store: Cleared whole app state!');
  },
  clearRecruitmentValues({ commit }) {
    recruitmentKeys.forEach((key) => commit('DELETE_ITEM', key));
  },
};

const getters = {
  getBasePath(state) {
    return state.basePath ?? '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
