<template>
  <div v-if="visible" :class="containerBackgroundColorClass">
    <div class="icon-list__container content-container-10">
      <ScText tag="h3" class="title-s title-md-s text-center full-width m-b-xl m-md-b-3xl" :field="fields.IconListTitle" />
      <div class="icon-list">
        <div v-for="(item, i) in fields.IconListItems" :key="i" class="icon-list__item">
          <div class="image-container" :class="iconBackgroundColorClass">
            <ScImage :media="item.fields.IconListItemImage" :class="{ 'small-icon': showSmallIcon() }" />
          </div>
          <div class="text-container">
            <div class="text-content">
              <ScText tag="h4" class="text-l text-md-m" :field="item.fields.IconListItemTitle" />
              <ScRichText class="text-m text-md-m" tag="div" :field="item.fields.IconListItemText" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'IconList',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    containerBackgroundColorClass() {
      const value = this.fields?.ContainerBackgroundColor?.fields.Type.value;
      if (value) return `bg-${value}`;
      else return '';
    },
    iconBackgroundColorClass() {
      const value = this.fields?.IconListItemBackgroundColor?.fields.Type.value;
      if (value) return `${value}`;
      else return '';
    },
  },
  created() {
    if (this.fields.ToggledBy) {
      this.$root.$on(this.fields.ToggledBy.fields.Key.value, (data) => {
        this.visible = data;
      });
    } else this.visible = true;
  },
  methods: {
    showSmallIcon() {
      if (this.fields?.IconListItemBackgroundColor) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss">
.icon-list {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  &__container {
    padding: 72px 16px;
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
  }
  &__item {
    display: flex;
    gap: 24px;
    flex-basis: 100%;
    @include screen-tablet-portrait-up {
      flex-basis: Calc(50% - #{20px});
    }
    .image-container {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      display: flex;

      img {
        height: auto;
        width: 100%;
        max-width: 72px;
        max-height: 72px;

        &.small-icon {
          padding: 16px;
        }
      }
    }
  }
}
</style>
