<template>
  <div class="modal-overlay" :class="{ 'experience-editor': isEditorActive, large: isModalLarge }">
    <div class="modal-overlay__fade" @click="$emit('click')" />
    <div class="modal-overlay__content" role="dialog">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalOverlay',
  inject: {
    isEditorActive: {
      type: Boolean,
    },
  },
  props: {
    isModalLarge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  overflow-x: hidden;
  visibility: visible;
  opacity: 1;
  align-items: center;
  justify-content: center;

  &.large {
    overflow-y: scroll;
    align-items: unset;
  }

  &__fade {
    @extend .fade-in;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &.experience-editor {
    position: static;
    width: auto;
    height: auto;

    .modal-overlay__fade {
      display: none !important;
    }
  }
}
</style>
