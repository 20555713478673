<template>
  <FlowSelector :fields="fields" :bgColor="backgroundColor">
    <div v-if="leftText && rightText" class="subnavigation-switch">
      <span class="switcher switcher-1">
        <input
          tabindex="0"
          ref="toggle"
          @click="switchFlow"
          @keydown.space.prevent="switchFlow"
          type="checkbox"
          id="switcher-1"
          :data-first="leftText"
          :data-second="rightText"
          :checked="checked"
          :aria-checked="checked.toString()"
          role="switch"
          :aria-label="$t('switch-to') + ' ' + rightText"
        />
        <label class="text-m" for="switcher-1"></label>
      </span>
    </div>
  </FlowSelector>
</template>

<script>
import FlowSelector from './util/FlowSelector.vue';
import { gtmViewItemListEvent } from '@/gtmTracking';

export default {
  name: 'FlowSelectorSwitch',
  components: {
    FlowSelector,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      backgroundColor: this.fields?.BackgroundColor?.value,
      currentFlow: this.fields?.SwitchLeftKey?.value,
    };
  },
  computed: {
    leftText() {
      return this.fields?.SwitchLeftText?.value;
    },
    rightText() {
      return this.fields?.SwitchRightText?.value;
    },
    isDefault() {
      return this.$store.state.config?.useDefaultPrices ?? true;
    },
    checked() {
      return this.currentFlow === this.fields?.SwitchRightKey?.value;
    },
  },
  mounted() {
    if (!this.isDefault) {
      this.switchFlow();
    }
    if (this.$store.state.config?.priceKeyDefault === this.fields?.SwitchRightKey?.value) {
      this.switchFlow();
    }

    // TODO: Evaluate if this could be improved
    // Right now we just assume that the U30 switch is
    // always the right one and that it always has 'U30' as key
    if (this.$route.query.tab) {
      const tabKey = this.$route.query.tab;
      if (tabKey !== 'under30rabatt') return;
      if (this.currentFlow === this.fields?.SwitchRightKey?.value) return;
      if (this.fields?.SwitchRightKey?.value !== 'U30') return;

      setTimeout(() => {
        this.switchFlow();
      }, 300);
    }
  },
  methods: {
    async switchFlow() {
      if (!this.checked) {
        this.currentFlow = this.fields?.SwitchRightKey?.value;
      } else {
        this.currentFlow = this.fields?.SwitchLeftKey?.value;
      }

      this.$store.dispatch('config/updatePriceKey', this.currentFlow);
      await this.$store.dispatch('config/updateRatePlanPrices');

      // switch background color
      if (this.backgroundColor === this.fields?.BackgroundColor?.value)
        this.backgroundColor = this.fields?.BackgroundColorAlt?.value;
      else this.backgroundColor = this.fields?.BackgroundColor?.value;

      gtmViewItemListEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.subnavigation-switch {
  $switchWidth: 215px;

  .switcher {
    width: $switchWidth;

    input {
      appearance: none;
      width: $switchWidth;
      outline: none;
      transition: 0.25s -0.1s;

      &:not(:checked) {
        & + label {
          left: 8px;
          right: 100px;
          background: $color-almost-black;
          transition: left 0.5s, right 0.4s 0.2s;
          width: 80px;
          transition: left 0.4s 0.1s, right 0.5s, background 0.35s 0s;
        }
      }

      &:checked {
        transition: background 0.5s -0.1s;

        &:before {
          color: #{$color-black};
          transition: color 0.5s;
        }

        &:after {
          color: white;
          transition: color 0.5s 0.2s;
        }

        & + label {
          left: 96px;
          right: 8px;
          background: $color-almost-black;
          transition: left 0.4s 0.1s, right 0.5s, background 0.35s 0.5s;
          width: 112px;
        }
      }

      &:before,
      &:after {
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        white-space: nowrap;
        font-size: 17.5px;
        line-break: normal;
        font-family: $font-normal !important;
      }

      &:before {
        color: white;
        transition: color 0.5s 0.2s;
        white-space: nowrap;
        content: attr(data-first);
        left: 20px;
      }

      &:after {
        border: none;
        background: transparent;
        color: black;
        transition: color 0.5s;
        white-space: nowrap;
        content: attr(data-second);
        right: 16px;
      }
    }

    label {
      z-index: 1;
      position: absolute;
      top: 6px;
      bottom: 6px;
      height: 44px;
    }
  }
}
</style>
