export function toggleBodyScrollLock(isActive) {
  if (typeof isActive !== 'boolean') return;

  // note: "document" will fail for SSR but it's currently not used upon initial rendering so it _should_ be fine
  const body = document.getElementsByTagName('body')[0];
  if (body === undefined) return;

  if (isActive) body.classList.add('no-scroll');
  else body.classList.remove('no-scroll');
}

export function norwegianPhonenumberToE164(phonenumber) {
  phonenumber.trim();
  if (phonenumber.Length == 8) return `+47${phonenumber}`;
  else if (phonenumber.Length == 10 && phonenumber.StartsWith('47')) return `+${phonenumber}`;
  else if (phonenumber.Length == 12 && phonenumber.StartsWith('0047')) return `+${phonenumber.substring(2)}`;
  return phonenumber;
}

export async function stringToSha256(string) {
  const buffer = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function capitalizeFirstLetter(string) {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

// used in b2b orderflow
export function getProductPrice(product, rates) {
  const id = product?.fields['CRM Item']?.value;
  if (!id) return null;

  const rateplan = rates?.find((f) => f.fields['CRM Item'].value === id);
  const price = Math.round(rateplan?.price);
  return price;
}

// used in b2c orderflow
export function getProductPriceFromRates({ product, ratePlans, multiplyBy = 1, familyCrmItemPrice = null }) {
  const id = product?.fields['CRM Item']?.value;
  if (!id) return null;

  if (typeof multiplyBy !== 'number' || multiplyBy <= 0) multiplyBy = 1;
  if (typeof familyCrmItemPrice !== 'number' || familyCrmItemPrice <= 0) familyCrmItemPrice = null;

  const rateplan = ratePlans.find((f) => f.crmId === id);
  // TODO: If we can't map the product crm id to the rateplan in config
  // it will return undefinded which will return NaN price - FIX ME
  let price = Math.round(rateplan?.price);
  if (multiplyBy > 1 && familyCrmItemPrice) price = price + familyCrmItemPrice * multiplyBy;
  return price;
}

export function calculateAge(date) {
  if (!date || typeof date !== 'string' || !date.match(/^\d{2}\.\d{2}\.\d{4}$/)) {
    console.error('calculateAge: Invalid date format. Please provide a valid date in the format DD.MM.YYYY');
    return null;
  }

  const [day, month, year] = date.split('.'); // Split the input date (DD.MM.YYYY) and rearrange it to match MM/DD/YYYY format
  const formattedDate = `${month}/${day}/${year}`;
  const current = new Date();
  const birthday = new Date(formattedDate);
  let age = current.getFullYear() - birthday.getFullYear();
  const m = current.getMonth() - birthday.getMonth();

  if (m < 0 || (m === 0 && current.getDate() < birthday.getDate())) {
    age--;
  }

  return age;
}

export const tabbableElements =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], .tabbable';

export function focusOnFirstTabbableElement(parentElement) {
  if (!parentElement) {
    console.error('Parent element is missing');
    return;
  }
  const focusableElements = parentElement.querySelectorAll(tabbableElements);
  if (focusableElements.length === 0) {
    console.log('No focusable elements found');
    return;
  }
  focusableElements[0].focus();
}

export const focusTrapInsideElement = (() => {
  let elementsWithModifiedTabindex = []; // Store elements that had their tabindex changed
  let handleKeyDown = null;

  // parent is the element (usually a modal) that should trap focus inside of it, turnOn specifies if the focus trap should be turned on or off,
  // and cancelCallback is a function that should be called when the user presses the escape key
  // add attribute "data-focustrap-ignore" to a element that should not be included in the focus trap (e.g. a nested modal)
  return (parent, turnOn = true, cancelCallback) => {
    // Find all focusable elements

    if (turnOn === true) {
      // Set tabindex to -1 for all focusable elements outside the parent element
      const allTabbableElements = document.querySelectorAll(tabbableElements);
      allTabbableElements.forEach((el) => {
        // select elements outside the parent but that are not inside (another) dialog
        if (!parent.contains(el) && !el.closest('[role="dialog"]')) {
          el.setAttribute('tabindex', '-1');
          elementsWithModifiedTabindex.push(el);
        }
      });
    } else if (turnOn === false) {
      // Only reset the tabindex of elements that had their tabindex changed by the function
      elementsWithModifiedTabindex.forEach((el) => {
        el.setAttribute('tabindex', '0');
      });
      elementsWithModifiedTabindex = [];
    }

    // Handle tab index inside the parent element
    const parentTabbableElements = parent.querySelectorAll(tabbableElements);
    const ignoreInsideElement = parent.querySelector(['[data-focustrap-ignore]']); // Find element that should not be included in the focus trap
    parentTabbableElements.forEach((el) => {
      if (!turnOn || (ignoreInsideElement && ignoreInsideElement.contains(el))) {
        el.setAttribute('tabindex', '-1');
      } else {
        el.setAttribute('tabindex', '0');
      }
    });

    parentTabbableElements[0]?.focus(); // Focus the first element inside the parent element

    // Handle keydown event
    if (turnOn === true) {
      handleKeyDown = function (event) {
        if (event.key === 'Escape' && cancelCallback && typeof cancelCallback === 'function') {
          cancelCallback();
        }
        if (event.key !== 'Tab') return;

        const firstElement = parentTabbableElements[0];
        const lastElement = parentTabbableElements[parentTabbableElements.length - 1];

        if (event.shiftKey) {
          /* shift + tab */ if (document.activeElement === firstElement) {
            lastElement.focus();
            event.preventDefault();
          }
        } /* tab */ else {
          if (document.activeElement === lastElement) {
            firstElement.focus();
            event.preventDefault();
          }
        }
      };
      // trap focus inside the parent element
      window.addEventListener('keydown', handleKeyDown);
    } else if (handleKeyDown) {
      window.removeEventListener('keydown', handleKeyDown);
      handleKeyDown = null;
    }
  };
})();

export const isDefaultPrice = (priceKey, defaultPriceKey = null) => {
  if (!defaultPriceKey) return priceKey === '';
  return priceKey === defaultPriceKey;
};

export function getProductIndex(products, product) {
  if (!products || !product) {
    console.error('Products array or product is missing');
    return null;
  }
  const index = products.findIndex((p) => p.fields['CRM Item']?.value === product.fields['CRM Item']?.value);
  if (index === -1) {
    console.error('Product not found in products array');
    return null;
  }
  return index + 1; // gtm item index starts at 1
}
